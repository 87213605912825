.modal-wrapper {

    .modal {

        header {
            padding: 1.5rem 2.5rem;
            text-align: center;
            background-color: $security-modal-grey;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }

        section {
            padding: 30px 15px;
            text-align: center;
        }

        .warning-icon {
            padding: 30px 0 0;
            font-size: 90px;

            i {
                color: $select-status-pending;
            }

            [class^="path"]::before {
                color: $select-status-pending;
            }
        }

        .confirm-action {
            //max-width: 500px;
            line-height: 1.5em;
        }

        footer {
            @include display (flex);
            @include align-items (center);
            @include justify-content (space-between);
            width: 80%;
            padding: 15px 15px 30px;
            margin: 0 auto;
        }

        h4 {
            margin: 0;
            font-size: 42px;
            font-weight: 100;
            color: $h4;
        }

        h5 {
            margin: 0;
            font-size: 28px;
            font-weight: 300;
            color: $h4;
        }

        //[type=text] {
        //    width: 80%;
        //    height: 54px;
        //    margin-bottom: 20px;
        //    line-height: 54px;
        //    @include responsive-font-size($font-size-md);
        //    font-weight: 400;
        //    background-color: $input-background-grey;
        //    border: 1px solid $security-modal-semi-grey;
        //}

        .cancel {
            height: 44px;
            @include responsive-font-size($baseFontSize);
            border: 1px solid $security-grey;
            border-radius: 46px;
            font-weight: 400;
            line-height: 44px;
            background-color: $white;
            color: $security-grey;
        }

        .create {
            height: 44px;
            @include responsive-font-size($baseFontSize);
            font-weight: 400;
            line-height: 44px;
            color: $white;
            background-color: $blue;
            border: 1px solid $radio-grey;
        }
    }
}

.custom-position-new-called-party-number{
    right: -30px;
    top: 4px;
}
