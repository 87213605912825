$black: #000;
$white: #fff;

$active-tab-color: rgba(224, 46, 126, .9);
$text-focus-border-color: rgba(224, 46, 126, 1);

$nav-link-color: #b3b5bd;
$background-grey: #fcfcfc;
$background-grey-modal: #f7f7f7;
$light-grey: #8d8d8d;
$extra-light-grey: #eef2f3;
$dark-grey: #2f353f;
$extra-dark-grey: #23252a;
$border-color: #bbb;
$semi-grey: #808e9b;

$input-disabled: #dedede;
$input-color-grey: #333;
$input-background-grey: #efefef;
$input-background-dark-grey: #dedede;
$code-border: #e5e5e5;
$table-striped-row: #f7f7f7;

$extra-semi-grey: #c5c5c5;
$filters-gray: #bababa;
$security-grey: #999;
$security-border-grey: #9b9b9b;
$security-modal-grey: $input-background-dark-grey;
$security-modal-semi-grey: #d2d2d2;
$cancel-grey: #e1e1e1;
$cancel-grey-text: #666;
$radio-grey: #8f8f8f;
$radio-deep-grey: #636363;
$radio-border-grey: #c6c6c6;
$security-background-grey: #ededed;
$activity-header: #e9e9e9;
$search-grey: #ccc;
$one-more-grey: #d6d6d6;
$pagination-field: #cdcdcd;

$blue: #e02e7e;
$deep-blue: rgba(224, 46, 126, 1);
$green: #61bf88;
$toggle-green: #5bc689;
$extra-green: #41af52;
$pink: #f1658c;
$bright-pink: #f14675;
$toggle-pink: #f3638b;
$red: #fe3e2c;
$orange: #f9ce89;

$sidebar-background: #515253;
$sidebar-text-color: #b3b5bd;
$sidebar-nav-border: #e02e7e;
$sidebar-active-link: #23252a;

$h4: rgba(224, 46, 126, 0.8);
$search-background: #edf1f2;

$tab-shadow: rgba(0, 0, 0, .1);
$top-shadow: rgba(0, 0, 0, .2);
$map-shadow: rgba(0, 0, 0, .5);
$sort-by-selector-shadow: rgba(0, 0, 0, .35);

$btn-green-color: #89c34c;
$btn-green-color-2: #8dc63f;

$footer-btn-color-grey: #999;
$footer-btn-browse-bg-color: #999;
$btn-browse-bg-color: #999;
$btn-draft-bg-color: #333;
$footer-btn-border-grey: #9b9b9b;
$footer-btn-border-pink: #f3638b;

$dashboard-btn-bg-grey: #5b7c8a;

$billing-btn-color-dark-grey: #333;

$new-green: #78b926;

$alert-pink: #f1005e;
$alert-orange: #de6b1a;
$alert-purple: #9d20ae;
$alert-blue: rgba(224, 46, 126, .3);
$alert-green: #8bc82a;
$alert-gray: #5b7c8a;

$summary-green: #18a650;
$summary-orange: #f79625;
$summary-blue: rgba(224, 46, 126, 1);
$summary-purple: #8f3f98;

$progress-bar: rgba(224, 46, 126, .6);
$tag-button: rgba(224, 46, 126, .65);
$tag-border: rgba(224, 46, 126, .4);
$light-blue: rgba(224, 46, 126, .85);
$deep-blue: rgba(224, 46, 126, 1);
$search-hover: rgba(224, 46, 126, .1);
$search-arrow: rgba(224, 46, 126, .6);
$gradient-blue-1: rgba(224, 46, 126, .9);
$gradient-blue-2: rgba(224, 46, 126, 1);
$gradient-blue-3: rgba(224, 46, 126, 1);

$iot-bg-layer: rgba(0, 0, 0, .3);

$notification-green: #c3e674;
$notification-pink: #f14675;
$notification-yellow: #fcf8e3;

$notification-info-bg: rgba(224, 46, 126, 0.1);
$notification-info-text: #e02e7e;
$notification-info-icon: rgba(224, 46, 126, 0.2);
$notification-success-bg: #cbf3dc;
$notification-success-text: #2ecc71;
$notification-success-icon: #97e6b8;
$notification-warning-bg: #fce7c4;
$notification-warning-text: #f39c12;
$notification-warning-icon: #f9ce89;
$notification-error-bg: #f9d3cf;
$notification-error-text: #e91e63;
$notification-error-icon: #f0958b;

$activity-blue: rgba(224, 46, 126, 0.7);

$tooltip-bg: #333;
$tooltips-bg: #413755;
$jpu-new-styled-tooltips-bg: #333;

$logout-pink: #e64173;

$thing-form-pink: #ec008c;
$thing-form-pink-2: #e82875;

$submit-search-button: #f15a29;
$yellow: #fce701;

$loader: rgba(0, 0, 0, .2);
$modal-pink: #ef429e;

$reports-green: #439786;
$reports-green-input: #88c7b9;
$reports-green-one-more: #6ccac9;
$reports-gray: #999;
$reports-red: #ff6c60;
$reports-green-stop-it: #a9d96c;

$billing-hover-td: rgba(224, 46, 126, .1);
$billing-tooltip-color: rgba(224, 46, 126, .2);
$billing-tooltip-shadow-color: rgba(0, 0, 0, .32);

$select-item-border-color: #e2e2e2;
$select-item-hover-color: #f9f9f9;
$select-status-approved: #9c6;
$select-status-pending: #c69;
$select-status-draft: #f9973f;
$select-status-retired: #f44336;

$background-color-grey: #ecebec;

$approve-button-green: #8ec640;
$approve-button-purple: #c36;

$text-grey: #999;

$billing-reports-cullent-cycle-bg: #e8f2f7;

$delete-entity-color: #cc3e3a;

$things-colors-date-color: #868686;
$events-modal-label-color: #969696;
$input-hint-color: #9c9c9c;
$header-search-category-counter-color: #777;

$text-blue: rgba(224, 46, 126, .7);
$text-green: #8ec640;
$text-red:  #c36;
$text-real-red: #ee5657;
$text-gray: #8d8d8d;

$close-button-small-modal: #a7a7a7;
$mobile-subscriber-active-row: rgba(224, 46, 126, 0.1);
$icon-settings-color: rgb(47, 53, 63);
$text-thing-assign-label-text: #6b6b6b;
$text-label-dark-grey: #6b6b6b;
$text-label-dark-grey: #6b6b6b;

$ui-grid-columns-checkbox-bg-color: rgba(224, 46, 126, 0.7);

$multiselect-list-item-font-color: #212529;
$multiselect-list-item-active-backgroud-color: rgba(224, 46, 126, 1);
$multiselect-list-item-focus-font-color: #16181b;
$multiselect-list-item-focus-backgroud-color: #f8f9fa;

$new-tumbler-bg-color: #999;
$new-tumbler-border-color: #636363;
$new-tumbler-runner-color: #d8d8d8;
$new-tumbler-disabled-color: #666;

$icon-button-color: #D0D0D0;
$icon-button-hover-active-color: rgba(224, 46, 126, .7);
$grid-column-menu-icon-color: #8B8A8C;
$active-new-tab-color: rgba(224, 46, 126, 0.6);

$ui-grid-row-expanded-background-color: rgba(224, 46, 126, 0.1);

$modal-new-warning-text-red: #f22613;
$modal-new-warning-message-text-color: #4a4a4a;
