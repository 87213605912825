.things-form {
    .things-groups-detail {
        .table {
            margin: 0;
        }
    }

    .checkbox {
        &.thingform-checkbox {
            top: -20%;
        }
    }

    .test-webhook-button {
        height: 44px;
        padding: 0 30px;
        @include responsive-font-size($baseFontSize);
        font-weight: bold;
        line-height: 1;
        color: $black;
        background-color: $white;
        border: 1px solid $btn-green-color;

        &.info {
            height: 29px;
            padding: 0 10px;
            color: $white;
            background-color: $blue;
            border: 0;
        }
    }

    .things-form-fix {
        @include clearfix;
        padding: 15px 30px;

        .left-block {
            float: left;
            width: 50%;
        }

        .right-block {
            float: right;
            width: 50%;
        }

        .title {
            padding-bottom: 15px;
            @include responsive-font-size($font-size-xl);
            line-height: 46px;
        }

        .tumbler {
            width: 50px;
        }

    }

    .tag-title {
        float: left;
        width: 195px;
        line-height: 46px;
    }

    .tag-data {
        @include calc (width, '100% - 195px');
        float: right;

        [type=text] {
            width: 191px;
        }
    }

    .things-button {
        width: 275px;
        height: 46px;
        padding: 0 40px;
        @include responsive-font-size($baseFontSize);
        font-weight: bold;
        line-height: 46px;
        background-color: $btn-green-color;
        border: 1px solid $btn-green-color;

        &.empty {
            color: $black;
            background-color: $white;
        }
    }

    .save {
        text-transform: uppercase;
        background-color: $tag-button;
        border: 0;
    }

    .sort-things {
        padding: 0 15px;
        background-color: $background-grey;
        user-select: none;

        .left-block,
        .right-block {
            display: flex;
            height: 59px;
        }

        .page-stat {
            margin-left: auto;
            @include responsive-font-size($font-size-sm);
            align-self: center;

            span {
                color: $security-grey;
            }

            .page-selector {
                display: inline-block;
                padding: 0 20px;

                .page-stat-input {
                    width: 59px;
                    height: 36px;
                    padding: 0 5px;
                    margin: 0 5px;
                    color: $black;
                    text-align: center;
                    background-color: $pagination-field;
                    border-radius: 5px;
                }
            }
        }

        .sort-by {
            position: relative;
            display: flex;
            cursor: pointer;
            align-self: center;

            .sort-icon {
                display: inline-block;
                width: 26px;
                height: 22px;
                margin-right: 10px;
                background: url('#{$images}sort-regular.png') no-repeat;
                align-self: center;

                &.active {
                    background: url('#{$images}sort-hover.png') no-repeat;
                }
            }

            &:hover > .sort-icon {
                background: url('#{$images}sort-hover.png') no-repeat;
            }

            .sort-text {
                display: inline-block;
                @include responsive-font-size($font-size-sm);
                align-self: center;
            }

            .sort-by-selector {
                position: absolute;
                top: 30px;
                z-index: 2;
                width: 144px;
                height: 140px;
                background-color: $white;
                box-shadow: 0 4px 10px 0 $sort-by-selector-shadow;

                .selector-options {
                    width: 144px;
                    padding: 6px 15px;
                    @include responsive-font-size($font-size-sm);

                    &:hover,
                    &.active {
                        color: $white;
                        background-image: linear-gradient(to top, $gradient-blue-1, $gradient-blue-2), linear-gradient($gradient-blue-3, $gradient-blue-3);
                    }
                }
            }
        }
    }

    // .table-head {
    //     background-color: $white;
    //
    //     td {
    //         cursor: pointer;
    //         user-select: none;
    //     }
    //
    //     .sort-arrow {
    //         display: inline-block;
    //         width: 7.3px;
    //         height: 4px;
    //         margin-left: 2px;
    //         background: url('#{$images}arrow-active-copy-3.png') no-repeat;
    //         transform: translateY(-2px);
    //     }
    //
    //     .current-sorted {
    //         background-color: $input-background-grey;
    //     }
    //
    //     .current-sorted-reverse {
    //         background-color: $input-background-grey;
    //
    //         .sort-arrow {
    //             transform: translateY(-2px) rotate(180deg);
    //         }
    //     }
    // }
}

.icons-box {
    position: absolute;
    top: 70px;
    left: 60px;
    z-index: 10;
    display: block;
    width: 275px;
    //height: 345px;
    height: 285px;
    overflow: hidden;
    text-align: left;
    background-color: $background-grey-modal;
    border-radius: 5px;
    box-shadow: 0 5px 5px 5px $tab-shadow;

    .scroll-box {
        height: 250px;
        //padding: 0 0 20px 20px;
        padding: 0 0 0 37px;
        //margin-top: 10px;
        margin-top: 35px;
        overflow: auto;
    }

    .upload {
        text-align: center;

        button {
            position: relative;
            float: none;
            height: 33px;
            padding: 0 38px 0 15px;
            @include responsive-font-size($font-size-md);
            font-weight: 300;
            line-height: 1;
            background-color: $tag-button;

            &::after {
                position: absolute;
                top: 3px;
                right: 3px;
                width: 23px;
                height: 23px;
                color: $white;
                border: 2px solid $white;
                border-radius: 50px;
                content: '+';
                @include responsive-font-size($font-size-xl);
                line-height: 1.5rem;
            }
        }
    }

    .close {
        position: absolute;
        top: 5px;
        right: 5px;
        display: inline-block;
        line-height: 1;
        color: $extra-semi-grey;
        cursor: pointer;
    }

    .icon {
        display: inline-block;
        width: 50px;
        height: 50px;
        margin: 0 20px 20px 0;
        font-size: 38px;
        line-height: 50px;
        text-align: center;
        cursor: pointer;

        &.active,
        &:hover {
            background-color: $white;
            box-shadow: 0 2px 2px 2px $tab-shadow;
        }
    }
}

.things-table-sort-things {
    padding: 0 15px;
    background-color: $background-grey;
    user-select: none;

    .left-block,
    .right-block {
        display: flex;
        height: 59px;
    }

    .page-stat {
        //margin-left: auto;
        @include responsive-font-size($font-size-sm);
        align-self: center;

        span {
            color: $security-grey;
        }

        .page-selector {
            display: inline-block;
            padding: 0 20px;

            .page-stat-input {
                width: 59px;
                height: 36px;
                padding: 0 5px;
                margin: 0 5px;
                color: $black;
                text-align: center;
                background-color: $pagination-field;
                border-radius: 5px;
            }
        }
    }
}

.event-message-styles {
    @include calc (width, '100% - 29px');
    max-width: 765px;
    word-break: break-word;
    display: inline-block;
    vertical-align: middle;
}

.event-arrow {
    padding: 0 5px 0 1px;
}

.event-message-button-font-size{
    font-size: 1.6rem;
}

.top-button-text {
    max-width: fit-content;
}

.network-table-cell {
    overflow: hidden;
}

