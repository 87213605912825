.dns-row {
    @include clearfix;
    padding: 10px;

    .left-block {
        @include clearfix;
        float: left;
        width: 50%;
    }

    .right-block {
        @include clearfix;
        float: right;
        width: 50%;
    }

    .form-label {
        display: inline-block;
        width: 200px;

        &.left {
            float: left;
            margin-top: .5rem;
        }
    }
}

.dns-list {
    h4 {
        margin: 20px 0 0;
    }

    [type=text] {
        width: 240px;
        padding: 0 20px;
        font-weight: 300;
        background-color: $input-background-grey;
    }

    .block {
        padding: 10px;
        margin: 20px 0 0;
    }

    .forms-row {
        float: left;

        [type=text] {
            margin-right: 10px;
        }

        .row {
            margin-bottom: 5px;
        }
    }
}

.upload-vpn {
    float: right;
    margin-top: -63px;
    margin-right: 150px;

    button {
        padding: 10px 20px;
    }
}

.vpn-list {
    a.btn-icon {
        text-decoration: none;
    }
}

.PCC-Rules-list {
    a.btn-icon {
        text-decoration: none;
    }
}
