.color-scheme-form {
    border-radius: 5px;

    .left-block {
        width: 100%;
    }

    .input-field {
        display: flex;

        .color-picker-swatch {
            top: 8px;

            &.color-picker-swatch-right {
                right: -35px;
            }
        }

        .color-picker-wrapper {
            .color-picker-panel {
                top: 50;

                &.color-picker-panel-right {
                    right: -135px;
                }
            }
        }

        .button-block {
            margin-left: 75px;
        }
    }

    .bem-modifier_padding-top-32 {
        padding-top: 32px;
    }

    color-picker {
        width: auto;
    }

    .color-picker-input-wrapper {
        display: flex;
    }

    .color-picker-swatch {
        align-self: center;
    }

    .color-picker-wrapper .color-picker-swatch:not(.input-group-addon) {
        height: 30px;
        position: static;
        margin-left: 30px;
    }
}
