.fired-alerts {
    .alerts {
        @include calc (max-height, '100vh - (#{$header-height} + #{$subheader-height} + #{$stat-height} + 160px)');
        min-height: 250px;
        overflow-y: auto;

        &.empty {
            min-height: 50px;
        }
    }

    .divided-rows {
        padding: 0;

        tbody td {
            &:first-of-type {
                padding: 0;
                width: 8px;
            }

            &:nth-of-type(2) {
                @include calc (width, '50% - (8px + 213px + 135px)');
            }

            &:nth-of-type(3) {
                width: 10%;
            }

            &:nth-of-type(4) {
                width: 15%;
            }

            &:nth-of-type(5) {
                width: 25%;
            }

            &:nth-of-type(6) {
                width: 135px;
            }

            &:last-of-type {
                width: 213px;
            }
        }

        &.thead {
            th {
                &:first-of-type {
                    width: 8px;
                }

                &:nth-of-type(2) {
                    @include calc (width, '50% - (8px + 213px + 135px)');
                }

                &:nth-of-type(3) {
                    width: 10%;
                }

                &:nth-of-type(4) {
                    width: 15%;
                }

                &:nth-of-type(5) {
                    width: 25%;
                }

                &:nth-of-type(6) {
                    width: 135px;
                }

                &:last-of-type {
                    width: 213px;
                }
            }
        }
    }
}

.alert {
    cursor: pointer;

    &.cursor-default {
        cursor: default;
    }

    &.active {
        background-color: $security-background-grey;
    }

    .critical {
        width: 8px;
        padding: 0;
        background-color: $alert-pink;
    }

    .error {
        width: 8px;
        background-color: $alert-orange;
    }

    .warning {
        width: 8px;
        background-color: $alert-purple;
    }

    .info {
        width: 8px;
        background-color: $alert-blue;
    }

    .title {
        display: inline-block;
    }

    .date {
        min-width: 115px;
    }

    .severity {
        min-width: 100px;
    }

    .manage {
        position: relative;
        display: inline-block;

        // button {
        //     padding: 10px 25px;
        //     @include responsive-font-size($baseFontSize);
        //     font-weight: bold;
        //     color: $white;
        //     text-decoration: none;
        //     text-transform: uppercase;
        //     background-color: $alert-green;
        //     border-radius: 20px;
        // }
        //
        // .more {
        //     text-transform: none;
        //     background-color: $alert-gray;
        // }

        .menu {
            position: absolute;
            top: 40px;
            right: 10px;
            z-index: 10;
            width: 150px;
            padding: 5px;
            @include responsive-font-size($font-size-sm);
            text-align: left;
            background-color: $white;
            border: 1px solid $border-color;
            border-radius: 5px;
        }

        a {
            text-decoration: none;
        }

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
        }
    }
}

.filters {
    margin-bottom: 25px;

    [type=text] {
        width: 243px;
        height: 33px;
        padding: 0 23px;
        margin: 0 0 0 20px;
        font-weight: 300;
        line-height: 23px;
        color: $light-grey;
        background-color: $white;
    }

    [type=submit] {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 20px;
        background-color: transparent;
        background-repeat: no-repeat;
        border: 0;
        outline: 0;
    }

    .search {
        display: inline-block;

        [type=submit] {
            background-image: url('#{$images}search.png');
        }
    }

    .filter {
        position: relative;
        display: inline-block;

        [type=submit] {
            top: 20px;
            background-image: url('#{$images}arrow-down.png');
            background-size: auto 10px;
        }
    }

    .btn-clear {
        height: 29px;
        padding: 0 16px;
        line-height: normal;
        font-weight: 300;
    }
}

