@import "../helpers";

.bem-btn_underline-button__billing_overview {
  cursor: pointer;
  color: $blue;
  width: auto;

  &:hover {
    cursor: pointer;
    color: $deep-blue;
  }
}

.charge-info-container {
  padding: 0 0 25px 0;
  //display: flex;
  //justify-content: space-between;
  border-bottom: 1px solid $search-grey;
}

.billing-reports-charge-info-block {
  text-align: center;
}

.billing-reports-charge-data-description {
  display: block;
  padding: 20px 0;
  font-weight: normal;
  @include responsive-font-size($font-size-md);
}

.billing-reports-charge-data-actual {
//   font-size: 32px;
//   font-weight: 300;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: -1px;

    &.billing-cycle-id {
      @include responsive-font-size($font-size-sm);
    }
}

.billing-reports-charge-data-actual_text-bold {
  font-weight: 600;
}

.billing-reports-charge-description-container {
  //padding: 30px 35px;
}

.charge-description-block {
  //display: flex;
  //align-items: center;
  //justify-content: flex-start;
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: 10px;
  padding: 12px 0;
}

.charge-description-block__last-block-align-left {
  margin-left: auto;
}

.charge-description-block_first-block-rules {
  border: 0;
  background-color: inherit;
}

.billing-reports-charge-description-header {
  margin: 16px 0 7px 0;
  padding: 0 12px;
  font-weight: normal;
  @include responsive-font-size($font-size-md);
}

.billing-reports-charge-block__item {
  padding: 0 20px;
  @include responsive-font-size($font-size-sm);
  min-height: 42px;
}

.bem-btn__download-charges {
  padding: 0px 29px;
  margin-left: 6px;
}

.bem-ver-divider__billing-reports-positioning {
  top: 10%;
  right: 0;
  height: 80%;
}