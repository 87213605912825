@import "helpers";

@include media-breakpoint-up(lg) {
  .bem-security-widts-separate-tables {
    width: auto;
  }
}

@include media-breakpoint-down(xl) {
  .navbar, .navbar-nav {
    flex-wrap: nowrap;
  }

  .bem-button-like-label {
    padding: 0 25px;
  }

  .bem-table-container-roaming-policy-table-widths {
    overflow-y: auto;
    min-width: 750px;
  }

  .bem-table-bordered-roaming-policy-table-widths {
    min-min-width: 750px;
  }

  .bem-table-container__customer-form-widths {
    overflow-y: auto;
    width: 100%;
  }

  .bem-thing-form-ips-table-widths {
    min-width: 1610px;
  }

  .bem-table-separate-container,
  .bem-table-separate-rows-container {
    width: 100%;
  }

  .charts .block {
    width: 60%;
  }

  .overflow-y-lg-auto {
    overflow-y: auto;
  }

  .min-width-lg-920px {
    min-width: torem(920px);
  }
}

@include media-breakpoint-down(lg) {

  .bem-button-like-label {
    padding: 0 15px;
  }

  .roaming {
    .new-rule-wrapper {
      margin: -25px 0 12px 0;
      .bem-btn_new-rule {
        margin-top: 20px;
      }
    }
  }

  //.bem-modifier_neg-margin-bottom-25 {
  //  margin-bottom: 0;
  //}

  .new-rule-wrapper {
    margin-top: 0;
  }

  .thing-form {
    .close {
      right: 0;
    }
  }

  .bem-add-divider {
    display: none;
  }

  .bem-table-bordered__width-1100px {
    min-width: 1100px;
  }

  .bem-table-bordered:not(.color-schemes_table-bordered):not(.bem-thing-form-ips-table-widths) {
    min-width: 1000px;
  }

  .bem-table-container:not(.color-schemes_table-container) {
    overflow-y: auto;
  }

  .bem-scrolable-table-header-container:not(.bem-no-media-width-change),
  .bem-scrolable-table-body-container,
  .bem-table-separate-rows {
    width: 1000px;
  }

  .bem-table-container-roaming-policy-table-widths {
    min-width: 550px;
  }

  .bem-table-bordered-roaming-policy-table-widths {
    min-width: 550px;
  }

  .bem-table-container__customer-form-widths {
    overflow-y: auto;
    width: 620px;
  }

  .bem-thing-form-ips-table-widths {
    min-width: 1610px;
  }

  .tabs:not(.color-scheme-tabs) {
    //display: none;
  }

  .charts .block {
    width: 60%;
  }

  .bem-security-widts-separate-tables {
    width: 1600px;
  }

  .bem-modifier_min-width-lg-180 {
    min-width: 180px;
  }

  .ui-grid-modal-pagination-fix {
    .ui-grid-pager-panel {
      bottom: -95px;
      flex-wrap: wrap;
    }

    .ui-grid-pager-container {
      flex-basis: 100%;
      justify-content: center;
      flex-wrap: wrap;

      .ui-grid-pager-control {
        justify-content: center;

        flex: 0 1 100%;
      }
    }

    .ui-grid-pager-panel {
      .ui-grid-pager-count-container {
        justify-content: center;
        flex: 0 1 100%;
      }
    }

    .ui-grid-pager-count-container {
      .ui-grid-pager-count {
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }

    .footer-buttons-pagination-fix {
      margin-top: 60px;
    }
  }
}

@include media-breakpoint-down(md) {
  .thing-form {
    .close {
      right: 0;
      //color: $blue;
    }
  }

  .bem-add-divider {
    :not(.remove-divider)::after {display: none;}

  }

  .bem-table-container-roaming-policy-table-widths {
    overflow-y: auto;
    width: 500px;
  }

  .bem-table-bordered-roaming-policy-table-widths {
    width: 900px;
  }

  .bem-table-container__customer-form-widths {
    overflow-y: auto;
    width: 420px;
  }

  .bem-scrolable-table-header-container:not(.bem-no-media-width-change),
  .bem-scrolable-table-body-container,
  .bem-table-separate-rows {
    width: 1000px;
  }

  .bem-thing-form-ips-table-widths {
    min-width: 1610px;
  }

  .ui-grid-pager-panel {
    bottom: -105px;
    flex-wrap: wrap;
  }

  .ui-grid-pager-container {
    flex-basis: 100%;
    justify-content: center;
    flex-wrap: wrap;

    .ui-grid-pager-control {
      justify-content: center;

      flex: 0 1 100%;
    }
  }

  .ui-grid-pager-panel {
    .ui-grid-pager-count-container {
      justify-content: center;
      flex: 0 1 100%;
    }
  }

  .ui-grid-pager-count-container {
    .ui-grid-pager-count {
      display: flex;
      justify-content: center;
    }
  }

  .body-toggled {
    .ng-toast__list {
      width: 350px;
    }
  }

  .full-width-toasts {
    .ng-toast__list {
      width: 350px;
    }
  }
}

@include media-breakpoint-down(sm) {
 .full-width-sm-xs {
   width: 100% !important;
 }

  .bem-btn_responsive_button {
    padding: 0 torem(40px);
  }

  //.bem-table-container {
  //  max-width: 700px;
  //}
  //
  //.bem-table-bordered {
  //  overflow-x: scroll;
  //}

  .bem-select-container {
    right: torem(20px);
  }

  .thing-form {
    .close {
      top: 26px;
      right: 28px;
      color: $close-button-small-modal;
    }
  }

  .modal-wrapper .modal .close {
    top: 26px;
    right: 28px;
    color: $close-button-small-modal;
  }

  .bem-close-btn {
    top: 26px;
    right: 28px;
    color: $close-button-small-modal;
  }

  .bem-add-divider {
    :not(.remove-divider)::after {display: none;}

  }

  .bem-table-container-roaming-policy-table-widths {
    overflow-y: auto;
    width: 480px;
    min-width: 0;
  }

  .bem-table-bordered-roaming-policy-table-widths {
    width: 900px;
  }

  .bem-table-container__customer-form-widths {
    overflow-y: auto;
    width: 330px;
  }

  .bem-scrolable-table-header-container:not(.bem-no-media-width-change),
  .bem-scrolable-table-body-container,
  .bem-table-separate-rows {
    width: 1000px;
  }

  .bem-thing-form-ips-table-widths {
    min-width: 1610px;
  }

  div[id^="model-"] {
    max-width: 450px;
    overflow-y: auto;

    .jpu-swagger-ui-markdown {
      p {
        padding: 0 1rem;
      }
    }
  }

  .sm-width_user-profile-table {
    width: 725px;
  }

  .sm-overflow-x_user-profile-table {
    overflow-x: auto;
  }
}

@include media-breakpoint-down(xs) {
 .full-width-sm-xs {
   width: 100% !important;
 }

  .bem-btn_responsive_button {
    padding: 0 torem(40px);
  }

  //.bem-table-container {
  //  max-width: 700px;
  //}

  //.bem-table-bordered {
  //  overflow-x: scroll;
  //}

  .bem-select-container {
    right: torem(20px);
  }

  .thing-form {
    .close {
      top: 26px;
      right: 28px;
      color: $close-button-small-modal;
    }
  }

  .modal-wrapper .modal .close {
    top: 26px;
    right: 28px;
    color: $close-button-small-modal;
  }

  .bem-close-btn {
    top: 26px;
    right: 28px;
    color: $close-button-small-modal;
  }

  .bem-add-divider {
    :not(.remove-divider)::after {display: none;}

  }

  .bem-table-container-roaming-policy-table-widths {
    overflow-y: auto;
    width: 420px;
    min-width: 0;
  }

  .bem-table-bordered-roaming-policy-table-widths {
    width: 900px;
  }

  .bem-scrolable-table-header-container:not(.bem-no-media-width-change),
  .bem-scrolable-table-body-container,
  .bem-table-separate-rows {
    width: 1000px;
  }

  .bem-thing-form-ips-table-widths {
    min-width: 1610px;
  }
}