$i: 1;
@while $i <= 50 {
    $perc: unquote((100 / (50 / $i)) + '%');

    .cw-#{$i} {
        float: left;
        width: $perc;
    }

    $i: $i + 1;
}

.scroll-table {
    overflow-y: auto;

    .table-row {

        $i: 1;
        @while $i <= 100 {
            $perc: unquote($i + '%');

            .column-#{$i} {
                width: $perc;
            }

            $i: $i + 1;
        }

        .skip-divider {
            &::after {
                display: none;
            }
        }

    }
}

.table-row {
    height: 64px;
    margin-right: 4px;
    margin-bottom: 7px;
    background-color: $white;
    border: 1px solid $border-color;
    //border-right: 0;
    //border-left: 0;
    border-radius: 5px;

    .td {
        @include display(flex);
        @include align-items(center);
        @include justify-content(flex-start);

        position: relative;

        height: 64px;
        padding: 0 10px;

        @include responsive-font-size($font-size-md);

        &::after {
            position: absolute;
            top: 50%;
            right: 0;

            display: block;
            width: 1px;
            height: 31px;
            margin-top: -15.5px;
            background: $extra-semi-grey;

            content: '';
        }

        &:last-child {
            @include display(flex);
            @include justify-content(flex-end);

            &::after {
                display: none;
            }
        }

        &.not-right {
            @include display(flex);
            @include justify-content(flex-start);
        }

        &.color-toggle {
            height: 63px;
            border-radius: 5px 0 0 5px;
        }
    }

    .td-more-items {
        @include align-items(flex-start);
        @include flex-direction(column);
        @include justify-content(center);
    }
}

.table-head {
    margin-right: 15px;

    .th {
        padding: 0 10px;
        @include responsive-font-size($font-size-md);
        font-weight: normal;
        text-align: left;
    }
}

.table-hover {
    .table-row {
        &:hover {
            cursor: pointer;
            background-color: $security-background-grey;
        }
    }
}

.table-height-for-security {
    @include calc(height, '100vh - 590px');
}

.table-height-for-network {
    @include calc(height, '100vh - 680px');
}
