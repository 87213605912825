.imsi-form-container {
    .input-has-error {
        margin-bottom: 20px;
    }

    .validation {
        position: absolute;
        top: 50px;
    }

    [type='file'] {
        display: none;
    }
}
