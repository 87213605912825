.password-reset {
    .validation {
        position: static;
    }
}

.bem-form-info-text {
    margin-bottom: 35px;
    display: block;
    @include responsive-font-size($font-size-md);
    font-weight: 100;
    line-height: 24px
}
