.user-form {
    margin: 22px 15px;

    .footer-buttons {
        padding: 20px;
        margin: 30px 0 0;
    }

    form {
        padding: 25px 30px;

        [type=text],
        [type=password],
        textarea,
        select {
            color: $input-color-grey;
            background-color: $input-background-grey;
            border: 1px solid $input-background-dark-grey;
        }

        textarea {
            height: 100px;
            border-radius: 20px;
            resize: none;
        }

        .textarea-label {
            vertical-align: top;
        }

        h3 {
            margin-bottom: 10px;
            font-size: 38px;
        }

        .radiobutton {
            margin-right: 0;
        }

        .roles {
            height: 35px;
            margin-bottom: 0;
            line-height: 35px;
        }
    }

    .left-block {
        float: left;
        width: 50%;

        &.billing-roles {
            width: 70%;
        }
    }

    .right-block {
        float: right;
        width: 50%;

        .select-things {
            margin-top: 20px;
        }
    }


    .user-form-role-container {
        //height: 135px;

        &.input-has-error {
            .validation {
                position: absolute;
                top: 105%;
            }
        }
    }

    .validation-role {
        left: 13px;
        display: block;
    }

    .section-title.blocked-title {
        margin-bottom: 35px;
    }

    .actions-blocked {
        margin-top: 40px;
        margin-bottom: 40px;

        label {
            float: none;
            width: 20px;
            margin: 0;
        }

        .item {
            margin-right: 15px;
            margin-bottom: 15px;
        }

        .checkbox-eye {
            margin-right: 10px;

            &::before {
                font-size: 27px;
            }

            & + .auto-label {
                //min-width: 255px;
                width: auto;
                color: $btn-green-color-2;
            }
        }

        [type=checkbox]:checked {
            & + .checkbox-eye {
                &::before {
                    font-size: 25px;
                }
            }

            & ~ .auto-label {
                color: $thing-form-pink;
            }
        }
        .auto-label {
            display: inline-block;
            min-width: 250px;
            @include responsive-font-size($font-size-md);
            font-weight: 300;
            cursor: pointer;
        }
    }
}

.bem-first-block,
.bem-second-block {
    display: inline-block;
}