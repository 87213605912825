.nav-tabs__custom {
  border-bottom: 0;
  margin: 10px 15px 0 15px;
}

.nav-tabs {
  .nav-link__custom {
    color: $black;
    border-color: $card-border-color $card-border-color transparent transparent;
    border-radius: 0;

    &.active {
      background-color: $active-new-tab-color;
      border-color: $active-new-tab-color;
      color: $white;
      font-weight: bold;
    }
    &:hover {
      border-color: $card-border-color $card-border-color transparent transparent;
    }
  }

  .nav-item {
    &:first-child {
      .nav-link__custom {
        border-top-left-radius: .25rem;
        border-color: $card-border-color $card-border-color transparent $card-border-color;
      }
    }
    &:last-child {
      .nav-link__custom {
        border-top-right-radius: .25rem;
      }
    }

  }
}

.nav-link__custom {
  font-weight: normal;
  font-size: .850rem;
  text-decoration: none;
  padding: 14px 34px;
  &:visited {
    color: unset;
  }
}