.accessible-price-plans {
    .entry-button-container {
        margin-right: 10px;
    }
}

    .table-container {
        overflow: inherit;

        .table-bordered.apn-custom-table {
            margin-bottom: 0;

            th {
                @include responsive-font-size($font-size-sm);
                padding: 15px 0;
                font-weight: 600;
            }

            td {
                padding: 10px 0;
            }

            select {
                width: 100%;
                padding-right: 35px;
                height: 96%;
                padding: 0 8px;
                background-color: $white;
                background-position: right 9px center;
                border: 0;
                border-radius: 0;
            }

        }
    }

    .apn-custom-table {

        tr {
            background-color: $white;
        }

        td {
            text-align: left;

            &:nth-of-type(1) {
                width: 50%;
            }

            &:nth-of-type(2) {
                width: 10.5%;
                text-align: center;
            }

            &:nth-of-type(4) {
                width: 40%;
                text-align: right;
            }
        }

        th {
            &:nth-of-type(2) {
                text-align: center;
            }
        }
    }

    .bem-price-table__checkmark {
        font-size: 34px;
        font-weight: 100;
        color: $btn-green-color;
    }

    .bem-price-table__checkmark-gray {
        color: rgba(149, 149, 149, 0.2);
    }


    .bem-price-table__no-price {
        font-weight: bold;
        color: $select-status-pending;
    }


.accessible-price-plans.apn-table-container {
    margin-top: 20px;
}

.table-header.custom-apn-select-header {
    background-color: $white;
}

.customers-fields {
    .tooltip-content {
        &.top-tooltip {
            top: -25px;
            width: 400px;
        }
    }
}
