$icomoon-font-family: "Aqua-Icons" !default;
$icomoon-font-path: "fonts" !default;

$icon-organizationOne: "\ea48";
$icon-Success: "\ea47";
$icon-inventory: "\e998";
$icon-Start-1: "\ea25";
$icon-Start-2: "\ea26";
$icon-Stop-1: "\ea27";
$icon-Stop-2: "\ea28";
$icon-user_login: "\ea29";
$icon-user_pass: "\ea2a";
$icon-truck1: "\ea2b";
$icon-car: "\ea2c";
$icon-bag: "\ea2d";
$icon-building: "\ea2e";
$icon-bulb: "\ea2f";
$icon-cart1: "\ea30";
$icon-cow: "\ea31";
$icon-drone: "\ea32";
$icon-duplicate: "\ea33";
$icon-factory: "\ea34";
$icon-gas: "\ea35";
$icon-help: "\ea36";
$icon-house: "\ea37";
$icon-mobile1: "\ea38";
$icon-notification1: "\ea39";
$icon-pc: "\ea3a";
$icon-people: "\ea3b";
$icon-plug: "\ea3c";
$icon-shop: "\ea3d";
$icon-traffic: "\ea3e";
$icon-water: "\ea3f";
$icon-wearables: "\ea40";
$icon-Organization1-path1: "\ea13";
$icon-Organization1-path2: "\ea14";
$icon-Organization1-path3: "\ea15";
$icon-Organization1-path4: "\ea16";
$icon-Organization1-path5: "\ea17";
$icon-Organization1-path6: "\ea18";
$icon-Organization1-path7: "\ea19";
$icon-Organization1-path8: "\ea1a";
$icon-Organization1-path9: "\ea1b";
$icon-Organization1-path10: "\ea1c";
$icon-Organization1-path11: "\ea1d";
$icon-Organization1-path12: "\ea1e";
$icon-Organization1-path13: "\ea1f";
$icon-Organization1-path14: "\ea20";
$icon-Organization1-path15: "\ea21";
$icon-Organization1-path16: "\ea22";
$icon-Organization1-path17: "\ea23";
$icon-Organization1-path18: "\ea24";
$icon-checkbox_on-path1: "\ea41";
$icon-checkbox_on-path2: "\ea42";
$icon-checkbox_off: "\ea43";
$icon-bootstrap1-path1: "\ea0f";
$icon-bootstrap1-path2: "\ea10";
$icon-bootstrap2-path1: "\ea11";
$icon-bootstrap2-path2: "\ea12";
$icon-bill: "\ea06";
$icon-browser: "\ea07";
$icon-exit1: "\ea08";
$icon-pause1: "\ea09";
$icon-pin-on-map: "\ea0a";
$icon-play1: "\ea0b";
$icon-refresh-page-option: "\ea0c";
$icon-APN_id-path1: "\ea44";
$icon-APN_id-path2: "\ea45";
$icon-SMS-path1: "\ea0d";
$icon-SMS-path2: "\ea0e";
$icon-thingsgroup: "\ea05";
$icon-pin-on-map2: "\ea04";
$icon-billings: "\ea02";
$icon-global_pool: "\ea03";
$icon-audit_log: "\e9fd";
$icon-network_providers: "\e9fe";
$icon-price_plan: "\e9ff";
$icon-profiles: "\ea00";
$icon-settings: "\ea01";
$icon-move_between_groups: "\e9fc";
$icon-download_csv: "\e9fa";
$icon-upload_csv: "\e9fb";
$icon-question-mark: "\ea46";
$icon-warning2: "\e9f5";
$icon-approve-path1: "\e9f6";
$icon-approve-path2: "\e9f7";
$icon-reject-path1: "\e9f8";
$icon-reject-path2: "\e9f9";
$icon-active-path1: "\e9c9";
$icon-active-path2: "\e9ca";
$icon-active-path3: "\e9df";
$icon-suspend-path1: "\e9e0";
$icon-suspend-path2: "\e9e1";
$icon-suspend-path3: "\e9f2";
$icon-suspend-path4: "\e9f3";
$icon-suspend-path5: "\e9f4";
$icon-provisioning-path1: "\e9ed";
$icon-provisioning-path2: "\e9ee";
$icon-provisioning-path3: "\e9ef";
$icon-api: "\e9f0";
$icon-imsi: "\e9f1";
$icon-archive: "\e9ec";
$icon-calendar1: "\e9e8";
$icon-datausage: "\e9e9";
$icon-profit: "\e9ea";
$icon-smsusage: "\e9eb";
$icon-apn: "\e9e7";
$icon-blocked1: "\e9cb";
$icon-ping-path1: "\e9cc";
$icon-ping-path2: "\e9cd";
$icon-ping-path3: "\e9ce";
$icon-ping-path4: "\e9cf";
$icon-refresh-path1: "\e9d0";
$icon-refresh-path2: "\e9d1";
$icon-retire-path1: "\e9d2";
$icon-retire-path2: "\e9d3";
$icon-sebd-sms-path1: "\e9d4";
$icon-sebd-sms-path2: "\e9d5";
$icon-sebd-sms-path3: "\e9d6";
$icon-sebd-sms-path4: "\e9d7";
$icon-sebd-sms-path5: "\e9d8";
$icon-sebd-ussd-path1: "\e9d9";
$icon-sebd-ussd-path2: "\e9da";
$icon-sebd-ussd-path3: "\e9db";
$icon-sebd-ussd-path4: "\e9dc";
$icon-sebd-ussd-path5: "\e9dd";
$icon-sebd-ussd-path6: "\e9de";
$icon-test-active-path1: "\e9e2";
$icon-test-active-path2: "\e9e3";
$icon-test-active-path3: "\e9e4";
$icon-test-active-path4: "\e9e5";
$icon-unblocked: "\e9e6";
$icon-roaming: "\e9c8";
$icon-product: "\e9ed";
$icon-closepopup-path1: "\e9c6";
$icon-closepopup-path2: "\e9c7";
$icon-customers: "\e900";
$icon-profiles2: "\e901";
$icon-lock1: "\e902";
$icon-unlock: "\e903";
$icon-update: "\e904";
$icon-warning1-path1: "\e905";
$icon-warning1-path2: "\e906";
$icon-default-sim-path1: "\e907";
$icon-default-sim-path2: "\e908";
$icon-default-sim-path3: "\e909";
$icon-default-sim-path4: "\e90a";
$icon-default-sim-path5: "\e90b";
$icon-default-sim-path6: "\e90c";
$icon-default-sim-path7: "\e90d";
$icon-default-sim-path8: "\e90e";
$icon-profile-help: "\e90f";
$icon-profile-legal: "\e910";
$icon-profile-logout: "\e911";
$icon-profile-setting: "\e912";
$icon-profile-support: "\e913";
$icon-thing_building-path1: "\e914";
$icon-thing_building-path2: "\e915";
$icon-thing_building-path3: "\e916";
$icon-thing_building-path4: "\e917";
$icon-thing_building-path5: "\e918";
$icon-thing_building-path6: "\e919";
$icon-thing_building-path7: "\e91a";
$icon-thing_building-path8: "\e91b";
$icon-thing_building-path9: "\e91c";
$icon-thing_building-path10: "\e91d";
$icon-thing_building-path11: "\e91e";
$icon-thing_building-path12: "\e91f";
$icon-thing_building-path13: "\e920";
$icon-thing_building-path14: "\e921";
$icon-thing_car-path1: "\e922";
$icon-thing_car-path2: "\e923";
$icon-thing_car-path3: "\e924";
$icon-thing_car-path4: "\e925";
$icon-thing_car-path5: "\e926";
$icon-thing_car-path6: "\e927";
$icon-thing_car-path7: "\e928";
$icon-thing_car-path8: "\e929";
$icon-thing_factory-path1: "\e92a";
$icon-thing_factory-path2: "\e92b";
$icon-thing_factory-path3: "\e92c";
$icon-thing_factory-path4: "\e92d";
$icon-thing_factory-path5: "\e92e";
$icon-thing_factory-path6: "\e92f";
$icon-thing_factory-path7: "\e930";
$icon-thing_factory-path8: "\e931";
$icon-thing_gas-path1: "\e932";
$icon-thing_gas-path2: "\e933";
$icon-thing_house-path1: "\e934";
$icon-thing_house-path2: "\e935";
$icon-thing_house-path3: "\e936";
$icon-thing_house-path4: "\e937";
$icon-thing_house-path5: "\e938";
$icon-thing_house-path6: "\e939";
$icon-thing_house-path7: "\e93a";
$icon-thing_phone-path1: "\e93b";
$icon-thing_phone-path2: "\e93c";
$icon-thing_phone-path3: "\e93d";
$icon-thing_phone-path4: "\e93e";
$icon-thing_phone-path5: "\e93f";
$icon-thing_phone-path6: "\e940";
$icon-thing_phone-path7: "\e941";
$icon-thing_phone-path8: "\e942";
$icon-thing_phone-path9: "\e943";
$icon-thing_phone-path10: "\e944";
$icon-thing_phone-path11: "\e945";
$icon-thing_phone-path12: "\e946";
$icon-thing_settings: "\e947";
$icon-thing_store-path1: "\e948";
$icon-thing_store-path2: "\e949";
$icon-thing_store-path3: "\e94a";
$icon-thing_store-path4: "\e94b";
$icon-thing_store-path5: "\e94c";
$icon-thing_store-path6: "\e94d";
$icon-thing_store-path7: "\e94e";
$icon-thing_store-path8: "\e94f";
$icon-thing_store-path9: "\e950";
$icon-thing_store-path10: "\e951";
$icon-thing_store-path11: "\e952";
$icon-thing_store-path12: "\e953";
$icon-thing_store-path13: "\e954";
$icon-thing_store-path14: "\e955";
$icon-thing_store-path15: "\e956";
$icon-thing_store-path16: "\e957";
$icon-thing_store-path17: "\e958";
$icon-thing_store-path18: "\e959";
$icon-thing_store-path19: "\e95a";
$icon-thing_store-path20: "\e95b";
$icon-thing_store-path21: "\e95c";
$icon-thing_suitcase-path1: "\e95d";
$icon-thing_suitcase-path2: "\e95e";
$icon-thing_suitcase-path3: "\e95f";
$icon-thing_suitcase-path4: "\e960";
$icon-thing_traficlights-path1: "\e961";
$icon-thing_traficlights-path2: "\e962";
$icon-thing_traficlights-path3: "\e963";
$icon-thing_traficlights-path4: "\e964";
$icon-thing_traficlights-path5: "\e965";
$icon-thing_traficlights-path6: "\e966";
$icon-thing_traficlights-path7: "\e967";
$icon-thing_truck-path1: "\e968";
$icon-thing_truck-path2: "\e969";
$icon-thing_truck-path3: "\e96a";
$icon-thing_truck-path4: "\e96b";
$icon-thing_truck-path5: "\e96c";
$icon-thing_truck-path6: "\e96d";
$icon-thing_truck-path7: "\e96e";
$icon-thing_truck-path8: "\e96f";
$icon-thing_truck-path9: "\e970";
$icon-thing_truck-path10: "\e971";
$icon-thing_truck-path11: "\e972";
$icon-thing_truck-path12: "\e973";
$icon-thing_truck-path13: "\e974";
$icon-thing_truck-path14: "\e975";
$icon-thing_truck-path15: "\e976";
$icon-thing_water-path1: "\e977";
$icon-thing_water-path2: "\e978";
$icon-thing_water-path3: "\e979";
$icon-thing_water-path4: "\e97a";
$icon-thing_water-path5: "\e97b";
$icon-thing_water-path6: "\e97c";
$icon-thing_water-path7: "\e97d";
$icon-thing_water-path8: "\e97e";
$icon-thing_water-path9: "\e97f";
$icon-thing_water-path10: "\e980";
$icon-alert-error: "\e981";
$icon-alert: "\e982";
$icon-arrowdown: "\e983";
$icon-arrowup: "\e984";
$icon-checkmark1: "\e985";
$icon-checkbox: "\e986";
$icon-close: "\e987";
$icon-Copy: "\e988";
$icon-dashboard: "\e989";
$icon-download1: "\e98a";
$icon-drag: "\e98b";
$icon-edit: "\e98c";
$icon-events: "\e98d";
$icon-expand: "\e98e";
$icon-fav_off: "\e98f";
$icon-fav_on: "\e990";
$icon-gaspumps-path1: "\e991";
$icon-gaspumps-path2: "\e992";
$icon-gaspumps-path3: "\e993";
$icon-geo_fencing: "\e994";
$icon-iot_store: "\e995";
$icon-login_go_down: "\e996";
$icon-login_go_up: "\e997";
$icon-map_black-path1: "\e999";
$icon-map_black-path2: "\e99a";
$icon-map_black-path3: "\e99b";
$icon-map_green-path1: "\e99c";
$icon-map_green-path2: "\e99d";
$icon-map_green-path3: "\e99e";
$icon-map_red-path1: "\e99f";
$icon-map_red-path2: "\e9a0";
$icon-map_red-path3: "\e9a1";
$icon-menuclose: "\e9a2";
$icon-menuopen: "\e9a3";
$icon-network: "\e9a4";
$icon-password: "\e9a5";
$icon-plus_green-path1: "\e9a6";
$icon-plus_green-path2: "\e9a7";
$icon-pricing: "\e9a8";
$icon-radio_off: "\e9a9";
$icon-radio_on: "\e9aa";
$icon-reports: "\e9ab";
$icon-search1: "\e9ac";
$icon-security: "\e9ad";
$icon-sort: "\e9ae";
$icon-tag: "\e9af";
$icon-things: "\e9b0";
$icon-trafficlights-path1: "\e9b1";
$icon-trafficlights-path2: "\e9b2";
$icon-trafficlights-path3: "\e9b3";
$icon-trafficlights-path4: "\e9b4";
$icon-trafficlights-path5: "\e9b5";
$icon-trafficlights-path6: "\e9b6";
$icon-trafficlights-path7: "\e9b7";
$icon-trafficlights-path8: "\e9b8";
$icon-trash: "\e9b9";
$icon-upload1: "\e9ba";
$icon-username: "\e9bb";
$icon-users: "\e9bc";
$icon-waterpipes-path1: "\e9bd";
$icon-waterpipes-path2: "\e9be";
$icon-waterpipes-path3: "\e9bf";
$icon-waterpipes-path4: "\e9c0";
$icon-waterpipes-path5: "\e9c1";
$icon-waterpipes-path6: "\e9c2";
$icon-waterpipes-path7: "\e9c3";
$icon-waterpipes-path8: "\e9c4";
$icon-waterpipes-path9: "\e9c5";

