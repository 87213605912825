.things-groups-list {
    background: $background-grey;

    .filters {
        margin-top: 20px;
    }

    .thing-name {
        display: inline-block;
        padding-left: 10px;
        font-weight: 100;
        letter-spacing: -.95px;
        color: $blue;
    }

    .thing-description {
        display: inline-block;
        @include responsive-font-size($font-size-lg);
    }
}

.jpu-card {
    margin: 0 16px 13px;
    @include responsive-font-size($font-size-lg);
    border: 1px solid $border-color;
    border-radius: 5px;

    header {
        @include display (flex);
        @include align-items (center);
        @include justify-content (space-between);
        padding: 10px;
    }

    .card-content {
        @include clearfix;
        padding: 10px 10px 5px;
        background: $white;
        border-radius: 5px;
    }

    .left-block {
        float: left;
        width: 300px;
    }

    .things-group-icon {
        @include transform(translateY(20%));
        padding: 0 0 0 50px;
        font-size: 100px;
    }

    .right-block {
        @include calc (width, '100% - 300px');
        float: right;
        font-weight: 300;
    }

    .row {
        padding-bottom: 10px;
    }

    .col {
        display: inline-block;
        width: 20%;
    }

    .manage {
        min-width: 158px;
        text-align: right;
    }
}

.measurements {
    display: inline-block;
    margin-top: 15px;
    line-height: 1;
    color: $cancel-grey-text;
}

h2 {
    margin-right: 2rem;
}

.stat {
    display: inline-block;
    margin-right: 1rem;

    &:last-of-type {
        margin-right: 0;
    }

    .unit {
        text-transform: uppercase;
    }

    small,
    .unit {
        font-size: 12.02px;
    }

    small {
        display: block;
        font-weight: 400;
    }
}

.things-group-icon-small {
    position: relative;
    top: 3px;
    display: inline-block;
    margin-right: 5px;
    font-size: 25px;
}

.bem-thing-list-card {
    margin: 0 16px 13px;
    @include responsive-font-size($font-size-lg);
    border: 1px solid $border-color;
    border-radius: 5px;

    //header {
    //    @include display (flex);
    //    @include align-items (center);
    //    @include justify-content (space-between);
    //    padding: 10px;
    //}

    .left-block {
        float: left;
        width: 300px;
    }

    .right-block {
        @include calc (width, '100% - 300px');
        float: right;
        font-weight: 300;
    }

    .row {
        padding-bottom: 10px;
    }

    .col {
        display: inline-block;
        width: 20%;
    }
}

.bem-thing-list-thing-name {
    display: inline-block;
    @include responsive-font-size($font-size-xl);
    padding-left: torem(10px);
    font-weight: 100;
    letter-spacing: torem(-.95px);
    color: $blue;
}

.bem-thing-list-thing-description {
    display: inline-block;
    font-size: torem(20px);
}

.bem-thing-list-manage {
    min-width: torem(158px);
    text-align: right;
}

.bem-thing-list-card-content {
    //padding: torem(20) torem(10px) torem(20);
    background: $white;
    border-radius: torem(5px);
}

.bem-thing-list-card-content-group-icon {
    @include transform(translateY(0%));
    padding: 0 0 0 torem(50px);
    font-size: torem(100px);
}

.bem-thing-list-card-content-value-text {
    font-size: torem(18px);
    font-weight: 400;
}