h4 {
    padding: 0;
    margin: 20px 0;
    @include responsive-font-size($font-size-lg);
    font-weight: 400;
}

h3 {
    padding: 0;
    margin: 0;
    font-size: 38px;
    font-weight: 100;
    color: $blue;
}

.left-dashboard {
    float: left;
    width: 780px;
    margin-right: 20px;
    background-color: transparent;
}

.right-dashboard {
    float: left;
    width: 323px;
}

.dashboard-info {
    @include clearfix;
    min-height: 170px;
    margin-bottom: 20px;
    background-color: $white;

    .icon {
        float: left;
        width: 150px;
        height: 150px;
        background: url('#{$images}network-name.png') 50% 50% no-repeat;

        &.second {
            background: url('#{$images}vpn list.png') 50% 50% no-repeat;
        }
    }

    .data {
        float: left;
    }
}

.vpns {
    max-width: 600px;

    .vpn {
        display: inline-block;
        width: 295px;
        margin-bottom: 10px;
        @include responsive-font-size($baseFontSize);
        font-weight: 300;
        line-height: 17px;
        color: $semi-grey;

        .tumbler {
            float: right;
            margin-right: 30px;
        }
    }
}

.dashboard-chart {
    min-height: 220px;
    text-align: center;
    background-color: $white;

    .donut-chart {
        position: relative;

        margin: 72px 0;
    }

    .chart {
        position: absolute;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        font-size: 60px;
        font-weight: 300;
        color: $green;

        &::after {
            position: absolute;
            top: 68px;
            font-size: 30px;
            font-weight: 100;
            color: $green;
            content: '%';
        }
    }
}
