@import "../helpers";

.roaming {
    .tabs {
        @include justify-content(flex-start);

        .tab {
            margin-right: 5px;
            cursor: pointer;
        }
    }

    .new-rule-wrapper {
        margin-top: -78px;
    }

    .roaming-tables {
        margin-top: 15px;

        .table-bordered {
            .operator-name {
                width: 500px;
            }

            th {
                &:last-of-type {
                    text-align: right;
                }
            }

            td {
                &:last-of-type {
                    padding-left: 0;
                }
            }
        }
    }
}

.roaming-management {
    #country-autofill {
        display: inline-block;
        width: 100%;
    }

    .roaming-management-block {
        margin: 15px 15px 65px 15px;
    }

    .e-212,
    .e-214 {

        [type="text"] {
            width: 90px;
        }
    }

    .bem-modifier_label-width-50 {
        width: 50px;
    }

    .bem-modifier_label-width-62 {
        min-width: 62px;
    }

    //.column {
    //    display: inline-block;
    //    margin-right: 5%;
    //}

    .small-input-validation {
        &.input-has-error {
            .validation {
                left: 77px;
            }
        }
    }

    .detail-header {
        @include display(flex);
        width: 96%;
        padding: 25px 30px;
        margin: 15px auto 0;


        .input-field {
            //.lbl {
            //    display: inline-block;
            //    font-weight: 400;
            //    color: $security-grey;
            //}
            //
            //[type="text"] {
            //    background-color: $input-background-grey;
            //    color: $input-color-grey;
            //    @include responsive-font-size($font-size-md);
            //    font-weight: normal;
            //}

            .tooltip-content {
                top: -49px;
                left: 53px;
            }
        }

        .detail {
            .absolute {
                position: absolute;
            }

            .lbl {
                min-width: 115px;
            }

            .countries {
                @include responsive-font-size($font-size-md);
            }
        }

        .e-212,
        .e-214 {
            .lbl {
                width: 50px;
            }

            [type="text"] {
                width: 90px;
            }
        }

        .e-214 {
            .input-field:first-child {
                .lbl {
                    width: auto;
                }
            }
        }

        //.input-has-error {
        //    margin-bottom: 48px;
        //
        //    .validation {
        //        position: absolute;
        //        top: 50px;
        //        left: 130px;
        //        display: block;
        //    }
        //}

        //.country-select {
            //&.input-has-error {
            //    .validation {
            //        left: 8px;
            //        display: block;
            //    }
            //}
        //}

        .validation {
            display: none;
        }
    }

    .network-elements {
        margin-top: 15px;

        .container {
            margin: 60px auto 0;
        }

        .table-bordered {
            th {
                &:last-of-type {
                    text-align: right;
                }
            }

            .add-new {
                background-color: $security-background-grey;

                .styled-select {
                    width: 100%;
                    padding: 0;
                    line-height: 30px;
                    background-color: inherit;
                    background-position: right 0 center;
                }

                td {
                    padding: 10px;

                    [type="text"] {
                        width: 100%;
                        padding: 5px;
                        background-color: $security-background-grey;
                    }
                }
            }
        }

        .bem-table-bordered {
            .input-has-error {

                [name="type"] {
                    margin-bottom: 48px;
                }

                .validation {
                    position: absolute;
                    top: 60px;
                    left: 30%;
                    width: 110px;
                    // display: block;
                }
            }
        }

        .network-elements-rows {
            td {
                position: relative;

                .pencil {
                    position: absolute;
                    top: calc(50% - 10px);
                    right: 15px;
                    color: $one-more-grey;
                    @include responsive-font-size($font-size-lg);
                }
            }

            .styled-select {
                width: 100%;
                padding: 0;
                background-color: $white;
            }

            .ne-input {
                @extend .bem-form-input;
                width: 100%;
                padding: 0;

                border-radius: 0;

                &:focus {
                    color: $black;
                }

            }

            // .ng-invalid-validation.ng-touched[type="text"] {
            //     border: 0 !important;
            //     color: $bright-pink;
            // }
        }

        .button-save {
            width: 89px;
            height: 39px;
            padding: 0;

            @include responsive-font-size($baseFontSize);
            font-weight: bold;
            line-height: initial;
            text-transform: uppercase;
        }
    }
}

.roaming-policy-modal {
    .modal {
        //position: absolute;
        //top: 35px;
        //margin-bottom: 15px;
        //max-width: 70%;

        .no-entries {
            text-align: center;
            padding: 15px;
        }
    }

    .detail-header {
        display: flex;
        flex-wrap: wrap;
        //width: 700px;
        //max-width: 700px;

        .input-field {
            //float: left;
            margin-bottom: 0;
            height: 75px;

            .auto-label {
                display: inline-block;
                width: 100px;
                margin-right: 5px;
                //font-weight: normal;
                color: $security-grey;
                @include responsive-font-size($font-size-md);
            }

            [type='text'] {
                width: 250px;
                height: 45px;
                border: 0;
                background-color: $input-background-grey;
                line-height: initial;
                margin: 0;
            }

            //&.input-has-error {
            //    margin-bottom: 42px;
            //}
            //
            .validation {
                left: 125px;
            }
        }

        //.description {
        //    clear: both;
        //}
        //
        //.right {
        //    float: right;
        //    margin-right: 10px;
        //    margin-left: 30px;
        //}
    }

    .table-bordered {
        th {
            &:last-of-type {
                text-align: right;
            }
        }

        tr {
            td {
                &:first-child {
                    width: 200px;
                }

                &:nth-child(2) {
                    width: 300px;
                }
            }
        }

        .styled-select {
            padding: 0;
            background-color: $white;

            &.countries {
                width: 200px;
            }

            &.operators {
                width: 300px;
            }
        }

        .byterate {
            .number-of-byterate {
                margin: 0px;
                margin-right: 8px;
                padding: 0px 15px;
                width: 100px;
                height: 45px;

                border: 0;
                background-color: $input-background-dark-grey;

                line-height: initial;
                color: $black;
            }

            .metric {
                font-weight: 300;
            }

            .disabled {
                background-color: $input-background-grey;
                color: $search-grey;
            }
        }

        .disabled-metric {
            color: $search-grey;
        }
    }

    .bem-table-bordered {
        .input-has-error {
            .validation {
                left: 10px;
            }
        }
    }

    .detail-footer {
        .cancel {
            color: $cancel-grey-text;
            background-color: $white;
        }
    }

    .bem-styled-select_new-roaming-policy-byterate {
        width: 100px;
    }

    .custom-form-style {
        padding: 0;
    }
}

.bem-roaming-management-net-btn {
    .new-rule-wrapper {
        margin-top: torem(-25px);
    }
}

.roaming-network-edit-icon-positioning {
    right: 8px;
}

.create-partner-success-header {
    @include responsive-font-size($font-size-xl);
    text-align: center;
    color: #78b926;
    text-transform: capitalize;
    font-weight: 400;
}

.create-partner-suggestion-header {
    @include responsive-font-size($font-size-sm);
    text-align: center;
    color: $black;
    text-transform: uppercase;
    font-weight: 300;
}

.roaming-partner-icon {
    &:before {
        font-size: 8.125rem;
        color: #78b926;
    }
}

#grid-partnerActions.list {
    height: 300px;
}