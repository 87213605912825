.swagger-notification {
    padding: 15px;
    margin: 20px;

    i {
        position: relative;
        top: 25px;
        float: left;
        font-size: 3rem;
        line-height: 0;
        color: $blue;
    }

    .notification-text {
        margin-left: 65px;
    }

    .example {
        margin-top: 15px;

        pre {
            @include responsive-font-size($font-size-xs);
        }
    }
}
