.apn {
    position: relative;

    .title-action {
        position: absolute;
        top: -11px;
        left: 26%;

        display: inline-block;

        span {
            color: $security-grey;
        }

        .icon-arrowup {
            @include transform (rotate(90deg));
            display: inline-block;
            @include responsive-font-size($font-size-sm);
            color: $security-grey;
            vertical-align: middle;
        }
    }

    .new-rule-wrapper {
        position: absolute;
        top: 81px;
        right: 1%;

        .new-rule-button {
            margin: 0;
        }
    }

    .switch-showing-lte {
        .auto-label {
            margin-right: 5px;
        }

        .checkbox {
            top: 0;
        }
    }

    .block {
        margin: 1%;
    }

    .footer-buttons {
        padding: 1%;
    }

    .served-by-jpu-checkbox {
        .tooltip-content {
            top: -105px;
            left: -90px;

            width: 200px;
        }
    }

    .lte-configuration {
        .styled-select {
            width: 292.3px;
        }
    }

    .standart-form {
        .validation {
            left: 185px;
        }
    }
}

.apn-pdpsubs {
    //margin: 1%;

    .switch-showing-4G {
        .auto-label {
            display: inline-block;
        }
    }

    .td-gray {
        input {
            background-color: $input-background-dark-grey;
        }

        button {
            &:focus {
                box-shadow: $shadow-for-focused-input;
            }
        }
    }
}

.bem-dublicate-input-styles {
    color: $input-color-grey;
    background-color: $input-background-grey;
    border: 1px solid $input-background-dark-grey;
}
