.bem-thing-tag-input-width {
    width: 100%;
}

.tags-container {
    display: inline-block;
    width: 100%;
    tags-input {
        width: 100%;
        .host {
            background-color: $white;
            border: 1px solid #ced4da;
            border-radius: 0.25rem;
            .tags {
                //height: 46px;
                padding: 4px;
                border: none;
                background: none;
                box-shadow: none;
                &.focused {
                    outline: none;
                    box-shadow: none;
                    input {
                        font-weight: normal;
                    }
                }
                .tag-item {
                    background: $text-grey;
                    border: none;
                    &.realm-operator {
                        background: $blue;
                    }
                    &.realm-customer {
                        background: $btn-green-color-2;
                    }
                    color: #fff;
                    border-radius: 4px;
                }
                input {
                    background: $white;
                    margin: 2px;
                    border: none;
                    line-height: auto;
                    height: 26px;
                    min-width: 63px;
                    width: auto;
                    @include responsive-font-size($font-size-sm);
                }
            }
        }
        &[disabled] .tags .tag-item {
            opacity: 1;
            .remove-button {
                display: none;
            }
        }
    }
    .autocomplete {
        max-height: 292px;
        overflow: auto;
        .suggestion-item {
            text-align: left;
            &.selected {
                background: $blue;
            }
        }
    }
    ul.tags-legend-list {
        padding: 0 10px;
        width: 100%;
        display: block;
        overflow: hidden;
        margin-top: 10px;
        li.tags-legend-item {
            float: left;
            list-style-type: none;
            &:first-of-type {
                margin-right: 20px;
            }
            &::before {
                display: inline-block;
                content: "";
                margin-right: 6px;
                height: 12px;
                width: 12px;
            }
            &.operator::before {
                background: $blue;
            }
            &.customer::before {
                background: $btn-green-color-2;
            }
        }
    }
}

.tags-modal-container {
    ul li {
        list-style: none;
    }

    .tags-list {
        padding-left: 0;
        padding-top: 2px;
        text-align: justify;
    }

    .tag-item {
        background: $text-grey;
        border: none;
        &.realm-operator {
            background: $blue;
        }
        &.realm-customer {
            background: $btn-green-color-2;
        }
        margin: 2px 4px ;
        color: #fff;
        font-weight: 400;
        @include responsive-font-size($font-size-sm);
        border-radius: 4px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 0 15px;
        height: 26px;
        line-height: 25px;
        display: inline-block;
    }

    //.tags-legend-list {
    //    padding: 0 10px;
    //    width: 100%;
    //    display: block;
    //    overflow: hidden;
    //    margin-top: 10px;
    //    li.tags-legend-item {
    //        float: left;
    //        list-style-type: none;
    //        &:first-of-type {
    //            margin-right: 20px;
    //        }
    //        &::before {
    //            display: inline-block;
    //            content: "";
    //            margin-right: 6px;
    //            height: 12px;
    //            width: 12px;
    //        }
    //        &.operator::before {
    //            background: $blue;
    //        }
    //        &.customer::before {
    //            background: $btn-green-color-2;
    //        }
    //    }
    //
    //}
}
