.jpu-btn {
    display: inline-block;
    height: 56px;
    padding: 0 55px;
    @include responsive-font-size($baseFontSize);
    font-weight: bold;
    line-height: 56px;
    color: $white;
    cursor: pointer;
    background: $blue;
    border: 0;
    border-radius: 46px;
    outline: 0;

    &.green {
        background: $alert-green;
    }

    &.small {
        height: 39px;
        padding: 0 35px;
        font-weight: 400;
        line-height: 39px;
    }

    &.disabled-button-restriction {
        cursor: default;
        background-color: $search-grey;
    }

    &.disabled {
        cursor: default;
        opacity: .3;
    }

    &.btn-access {
        position: relative;
        top: -2px;
        height: 40px;
        padding: 0 39px;
        margin-left: 15px;
        @include responsive-font-size($baseFontSize);
        font-weight: 400;
        line-height: 40px;
        background-color: $new-green;
    }

    &:focus {
        box-shadow: $shadow-for-focused-input;
    }
}

.new-rule-button {
    padding: 10px 40px;
    margin: 12px 15px 0 0;
    font-weight: bold;
    color: $white;
    background-color: $alert-green;
    border-radius: 20px;

    &:focus {
        box-shadow: $shadow-for-focused-input;
    }
}

.bem-btn {
    @extend .jpu-btn;
    display: inline-block;
    height: 42px;
    padding: 0 55px;
    @include responsive-font-size($baseFontSize);
    font-weight: 600;
    line-height: 44px;
    color: $white;
    cursor: pointer;
    background: $blue;
    border: 0;
    border-radius: 5px;
    outline: 0;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
        box-shadow: $shadow-for-focused-input;
    }

    &:focus {
        box-shadow: $shadow-for-focused-input;
    }

    &:active {
        transform: translateY(2px);
    }

    &:disabled {
        opacity: .5;
    }
}

.bem-btn_green {
    background-color: $new-green;
    color: #fff;
}

.bem-btn_grey {
    background: $btn-browse-bg-color;
}

.bem-btn_black {
    background: $btn-draft-bg-color;
}

.bem-btn_red {
    background: $red;
}

.bem-btn_disabled {
    cursor: not-allowed;
    opacity: .3;

    &:hover {
        box-shadow: none;
    }

    &:focus {
        box-shadow: none;
    }
}

.bem-btn_access {
    position: relative;
    top: -2px;
    height: 40px;
    padding: 0 39px;
    margin-left: 15px;
    @include responsive-font-size($baseFontSize);
    font-weight: 400;
    line-height: 40px;
    //background-color: $new-green;
}

.bem-btn_x-sm__mobile-subscriber {
    height: 29px;
    line-height: 8px;
    font-weight: 400;
    width: 29px;
}

.bem-btn_tabs-btn {
    border-radius: 5px;
}

.bem-btn_login-button {
    position: absolute;
    top: .75rem;
    right: 0;
    font-size: 2.25rem;
    color: $white;
    background: transparent;
    width: 50px;
    height: 50px;
    padding: 0;

    &:hover {
        box-shadow: none;
    }

    &:focus {
        box-shadow: none;
        outline: 0;
    }
}

.bem-btn_import-csv {
    width: 105px;
    height: 42px;
    padding: 0;
    @include responsive-font-size($baseFontSize);
    font-weight: 400;
    line-height: normal;
    color: $white;
    background-color: $btn-browse-bg-color;
    //border: 2px solid $new-green;
}

.bem-btn_footer {
    height: 39px;
    padding: 0 35px;
    margin: 12px 0 0 5px;
    @include responsive-font-size($baseFontSize);
    font-weight: 600;
    line-height: 39px;
    color: $footer-btn-color-grey;
    background-color: $white;
    border-radius: 5px;

    &.action-btn-custom-style {
        //background-color: $alert-green;
        color: #fff;
    }
}

.bem-btn_footer_save {
    margin-right: 0;
    // font-weight: 600;
    color: $white;
    text-transform: uppercase;
    background-color: $blue;
}

.bem-btn_footer_save_provisioning {
    margin-top: 22px;
    color: $white;
    background-color: $btn-green-color-2;
}

.bem-btn_footer_cancel {
    background-color: $white;
    border: 1px solid $footer-btn-border-grey;
}

.bem-btn_footer_browse {
    background-color: $footer-btn-browse-bg-color;
    color: $white;
}

.bem-btn_footer_draft {
    background-color: $btn-draft-bg-color;
    color: $white;
}

.bem-btn_footer_delete {
    //padding: 0 20px;
    font-weight: 400;
    border: 1px solid $footer-btn-border-pink;
    color: $footer-btn-border-pink;
}

.bem-btn_billing {
    height: 42px;
    padding: 0 35px;
    // margin: 12px 0 0 5px;
    @include responsive-font-size($baseFontSize);
    font-weight: 600;
    line-height: 39px;
    color: $white;
    background-color: $btn-browse-bg-color;
    //border: 1px solid $new-green;
}

.bem-btn_billing_csv {
    position: relative;
    width: 105px;
    height: 42px;
    padding: 0 0 0 30px;
    // margin: 0;
    font-weight: 400;
    // background-color: transparent;
}

.bem-btn_billing_add-entry {
    width: 165px;
    padding: 0;
    // margin: 0;
    //color: $billing-btn-color-dark-grey;
    //background-color: $white;
}

.bem-btn_billing_add-entry-green {
    font-weight: 400;
    color: $white;
    background-color: $new-green;
}

.bem-btn_billing_version-button {
    width: 275px;
    height: 38px;
    line-height: 38px;
}

.bem-btn_billing_submit-approval {
    height: 36px;
    padding: 0 10px;
    margin: 5px 50px 0 0;
    line-height: 36px;
}

.bem-btn_subheader {
    margin: 18px 0 0;
}

.bem-btn_subheader-alerts {
    margin: 18px 25px 0;
}

.bem-btn_subheader,
.bem-btn_subheader-alerts{
    float: right;
    height: 40px;
    padding: 0 45px;
    line-height: 40px;
    text-transform: capitalize;
}

.bem-btn_file {
    &.bem-button-like-label {
        //width: 275px;
        width: 50%;
        min-width: 115px;
        height: 46px;
        margin-left: 0;
        @include responsive-font-size($font-size-md);
        font-weight: 500;
        line-height: 47px;
        color: $white;
        text-align: center;
        background-color: $new-green;
        outline: 0;
    }

    &.bem-button-like-label__browse {
        max-width: 120px;
        min-width: 100px;
        height: 40px;
        @include responsive-font-size($font-size-xs);
        line-height: 40px;
        background-color: $white;
        border: 1px solid $blue;
        border-radius: 5px;
        color: $blue;
    }

    &.bem-button-like-label__browse__maxwidth-unset {
        max-width: unset;
    }
}

.bem-btn_new-rule {
    height: 39px;
    padding: 10px 40px;
    margin: 12px 15px 0 0;
    line-height: 20px;
}

.bem-btn_new-rule__roaming-policy {
    margin: 0;
    height: auto;
    padding: 10px 25px;
}

.bem-fix_top-margin0 {
    margin-top: 0;
}

.bem-fix_margin0 {
    margin: 0;
}

.bem-btn_small-round {
    height: 28px;
    padding: 1px 10px;
    margin-left: 10px;
    @include responsive-font-size($font-size-md);
    line-height: 26px;
    border-radius: 56px;
}

.bem-btn_small-round-provisioning {
    height: 16px;
    padding: 0px 5px;
    margin-left: 5px;
    @include responsive-font-size($font-size-xs);
    line-height: 16px;
    border-radius: 56px;
}

.bem-btn_profile-remove-qos {
    width: 38px;
    height: 38px;
    padding: 0;
    font-size: 38px;
    font-weight: 300;
    line-height: 30px;
    color: $black;
    background-color: $white;
    border: 2px solid $black;
}

.bem-shadowed {
    &:hover {
        box-shadow: $shadow-for-focused-input;
    }

    &:focus {
        box-shadow: $shadow-for-focused-input;
    }

    &:active {
        transform: translateY(2px);
    }
}

.bem-btn_approval-process {
    height: 42px;
    margin-left: 5px;
    line-height: 30px;
    background-color: $white;
}

.bem-btn_approval-process_approve {
    //order: 3;
    color: $white;
    background-color: $blue;
}

.bem-btn_approval-process_reject {
    //order: 2;
    color: $footer-btn-border-pink;
    border: 1px solid $footer-btn-border-pink;
}

.bem-btn_approval-process_cancel {
    //order: 1;
    color: $footer-btn-color-grey;
    border: 1px solid $footer-btn-border-grey;
}

.bem-btn_roaming-network-save {
    @include responsive-font-size($baseFontSize);
    line-height: initial;
    text-transform: uppercase;
    border-radius: 0;
    font-weight: 400;
}

//.bem-btn_xs {
//    height: 29px;
//    padding: 0 16px;
//    font-weight: 300;
//    line-height: normal;
//}

.bem-btn_delete {
    background-color: $white;
    color: $footer-btn-border-pink;
    border: 1px solid $footer-btn-border-pink;
}

.bem-btn_dashboard-alerts {
    height: 40px;
    padding: 10px 25px;
    line-height: normal;
}

.bem-btn_dashboard-alerts_clear {
    width: 98px;
    color: $footer-btn-border-pink;
    background-color: $white;
    border: 1px solid $footer-btn-border-pink;
}

.bem-btn_test-webhook {
    height: 38px;
    padding: 0 30px;
    @include responsive-font-size($baseFontSize);
    line-height: normal;
    color: $white;
    background-color: $btn-browse-bg-color;
}

.bem-btn_restriction {
    border-radius: 12px;
}

.bem-text_transform-none {
    text-transform: none;
}

.bem-btn_map {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    font-weight: 400;
    line-height: normal;
    border-radius: 0 0 5px 5px;

    &:active {
        transform: translateY(0);
    }
}

.bem-btn_things-button {
    width: 275px;
    height: 46px;
    padding: 0 40px;
    line-height: normal;
}

.bem-btn_things-create-group {
    width: 320px;
    height: 48px;
    line-height: normal;
}

.bem-btn_network_provider {
    margin-bottom: 20px;
}

.bem-btn_underline-button {
    width: 80px;
    line-height: normal;
    text-decoration: underline;
    background-color: transparent;

    &:hover {
        color: $radio-deep-grey;
        text-decoration: none;
        transform: translateY(0);
    }
}

.bem-btn_underline-out-file {
    text-decoration: underline;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
}

.bem-btn_underline-button_blue {
    color: #0a6ebd;

    &:hover {
        color: #e02e7e;
        text-decoration: none;
    }
}

.bem-btn-modifier_padding-0-35 {
    padding: 0 35px;
}

.footer-buttons {
    @include clearfix;

    .left-block {
        float: left;
    }

    .right-block {
        float: right;
    }

    %footer-buttons {
        height: 39px;
        padding: 0 35px;
        margin: 12px 0 0 5px;
        @include responsive-font-size($baseFontSize);
        font-weight: 600;
        line-height: 39px;
        color: $security-grey;
        background-color: $white;
        border-radius: 20px;
    }

    .delete {
        @extend %footer-buttons;
        padding: 0 20px;
        @include responsive-font-size($baseFontSize);
        font-weight: 400;
        color: $security-grey;
        background-color: $white;
        border: 1px solid $toggle-pink;
        border-radius: 46px;
    }

    .cancel {
        @extend %footer-buttons;
        background-color: $white;
        border: 1px solid $security-border-grey;
    }

    .save {
        @extend %footer-buttons;
        margin-right: 0;
        font-weight: 600;
        color: $white;
        text-transform: uppercase;
        background-color: $tag-button;
    }

    .primary {
        @extend %footer-buttons;
        color: $white;
        background-color: $blue;
        border: 0;
    }
}

%manage-buttons {
    display: inline-block;
    padding: 8px 10px;
    @include responsive-font-size($baseFontSize);
    color: $input-color-grey;
    cursor: pointer;
    background-color: $activity-header;
    border: 1px solid $white;
    border-radius: 5px;

    &:hover {
        color: $white;
        background-color: $border-color;
        border-color: $border-color;
    }

    &:focus {
        box-shadow: $shadow-for-focused-input;
    }
}

.btn-icon {
    @extend %manage-buttons;
}

.btn-icon__font-size-increased {
    font-size: 1.1rem;
}

.btn-round {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
    border-radius: 50%;
    // box-shadow: 0 2px 2px 2px $top-shadow;

    &.red:hover,
    &.white:hover {
        color: $white;
        background-color: $border-color;
        border-color: $border-color;
        // box-shadow: 0 1px 1px 1px $top-shadow;
    }

    &.red {
        background-color: $toggle-pink;
    }

    &.white {
        background-color: $white;
    }
}

.copy-content {
    @extend %manage-buttons;
    @include responsive-font-size($font-size-xl);
}

.edit {
    @extend %manage-buttons;
    margin-left: 10px;
}

.bem-lifecycle-table__edit-tooltip-fix {
    margin-left: 0;
}

.left-button {
    @extend %manage-buttons;
    margin-right: -4px;

    .icon-arrowdown {
        display: inline-block;
        transform: rotate(90deg);
    }
}

.right-button {
    @extend %manage-buttons;

    .icon-arrowup {
        display: inline-block;
        transform: rotate(90deg);
    }

}

.update {
    @extend %manage-buttons;
    margin-left: 10px;
    &.custom-table-padding {
        padding: 4px;
    }
}

.remove {
    @extend %manage-buttons;
    margin-left: 10px;
}

.add_btn {
    padding-left: 0;
    padding-right: 0;
}

.zoom-in {
    @extend %manage-buttons;
    margin-left: 10px;
}

.button-round {
    width: 38px;
    height: 38px;
    font-size: 38px;
    font-weight: 300;
    line-height: 30px;
    background-color: $white;
    border: 2px solid $black;
    border-radius: 50%;
}

.import-csv-button {
    width: 105px;
    height: 42px;

    margin: 0;


    @include responsive-font-size($baseFontSize);
    font-weight: 400;

    background-color: $new-green;
    color: $white;

    cursor: pointer;

    border-radius: 46px;
}

.bem-button-addon {
    position: absolute;
    left: 4px;
    top: 6px;
    font-size: 28px;
    color: $alert-green;

    &.icon-close {
        left: 11px;
        top: 11px;
        color: $text-red;
        font-size: 16px;
    }
}

.dropdown-trasparent-btn {
    font-size: 20px;
    height: 42px;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.btn-prevent-overflow {
  text-overflow: unset;
  overflow: visible;
}

.bem-btn_trasparent {
    padding: 0 1rem;
    background-color: transparent;
    border-color: transparent;
    color: $black;
    font-weight: 400;
    @include responsive-font-size($font-size-sm);
}

.bem-btn_trasparent-with-borders {
    border: 1px solid $black;
    line-height: 20px;
}

.bem-btn_add-element {
    position: relative;
    background-color: transparent;
    color: $blue;
    padding: 0;
    height: 24px;
    width: 24px;
    line-height: 1;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
}

.bem-btn_add-array {
    position: relative;
    background-color: transparent;
    color: $blue;
    padding: 0;
    height: 24px;
    width: 41px;
    line-height: 1;
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
}

.bem-btn_bulk-action-left {
    align-self: flex-start;
    margin-right: auto !important;

}

.bem-btn_bulk-action-right {
    align-self: flex-end;
    margin-left: auto !important;
}

.bem-btn-filters {
    height: 33px;
    line-height: 33px;
    border: none;
    padding: 0 10px;
    font-size: 14px;
    margin: 0;
}

.bem-btn-icon {
    height: 33px;
    line-height: 33px;
    background-color: transparent;
    font-size: 1rem;
    padding: 0 5px;
    color: $icon-button-color;
}

.bem-btn-icon__modifiers {
    &:hover, &:focus {
        box-shadow: unset;
        color: $icon-button-hover-active-color;
    }
}

.bem-btn-icon__modifiers-important {
    &:hover, &:focus {
        box-shadow: unset !important;
        color: $icon-button-hover-active-color !important;
    }
}

.bem-btn-link-like-button {
    background-color: transparent;
    color: $blue;
    &:hover {
        box-shadow: unset;
        color: darken( $blue, 18% );
    }
    &:active {
        transform: translateY(0);
    }
    &:focus {
        box-shadow: unset;
        color: darken( $blue, 18% );
    }
}

.bem-btn_sm {
    height: 39px;
    padding: 0 35px;
    font-weight: 400;
    line-height: 39px;
}

.bem-btn_x-sm {
    height: 33px;
    line-height: 20px;
    font-weight: 400;
    padding: 0 15px;
}

.bem-btn_xx-sm {
    @extend .bem-btn_x-sm;
    height: 28px;
    padding: 0 6px;
    font-size: 0.85rem;
}

.bem-btn_footer__green {
    background-color: $new-green;
    color: #fff;
}

.bem-btn_footer__red {
    background-color: $white;
    color: $footer-btn-border-pink;
    border: 1px solid $footer-btn-border-pink;
}

.bem-btn-full-width {
    height: 40px !important;
    font-size: .75rem !important;
    line-height: 40px !important;
    background-color: #fff !important;
    border: 1px solid #e02e7e !important;
    border-radius: 5px !important;
    color: #e02e7e !important;
    width: 100% !important;
}

.bem-btn-import-file {
    height: 40px !important;
    font-size: .75rem !important;
    line-height: 40px !important;
    background-color: #fff !important;
    border: 1px solid #e02e7e !important;
    border-radius: 5px !important;
    color: #e02e7e !important;
    width: 45% !important;
}
.img-action-button{
    height: 34px;
    margin-top: 12px;
}
