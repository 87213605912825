.roaming-policy-modal {
    .warning {
        @include display(flex);
        @include align-items(center);
        @include justify-content(center);

        padding: 7px;
        margin-bottom: 30px;

        font-size: 24px;
        text-align: center;

        .icon-warning {
            span {
                font-size: 50px;

                &::before {
                    color: $modal-pink;
                }
            }
        }

        .new-icon-warning {
            display: inline-block;
            border-radius: 50px;
            padding: 0.5em 0.6em;
            font-size: 30px;
            color: #ef429e;
            border: 1px solid #ef429e;
        }

        .warning-text {
            display: inline-block;
            margin-left: 10px;
            font-size: 24px;
            font-weight: 700;
            color: $modal-pink;
        }
    }

    .add-horizontal-divider {
        padding-top: 30px;
        border-top: 1px solid $code-border;
    }
}

.restriction-form-table {
    .table-bordered {
        .checkbox {
            margin-right: 25px;
        }

        .disabled-checkbox {
            &::after {
                color: $search-grey;
            }
        }

        .byterate {
            .disabled {
                color: $search-grey;
            }
        }

        th {
            &:last-of-type {
                border-left: 1px solid $border-color;
            }
        }

        td {
            &:last-of-type {
                padding-left: 0;
            }
        }
    }
}

.roaming-settings-positioning {
    left: 2.5%;
}

.roaming-settings-width {
    width: 95%;
}