.terminal {
    height: 300px;
    padding: 23px 23px 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: monospace, monospace;
    @include responsive-font-size($font-size-sm);
    color: $search-grey;
    text-align: left;
    background: $black;

    p {
        margin: 0 0 1em;
    }

    .pointer-green {
        font-weight: bold;
        color: $green;
    }

    .pointer-purple {
        font-weight: bold;
        color: $alert-purple;
    }

    table {
        width: 100%;

        td {
            padding: 7px;
            border: 1px solid $search-grey;
        }
    }
}