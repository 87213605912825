#user-profile-form {
    .indented {
        &:before {
            content: '-';
            margin: 0 20px;
        }
    }
}

.resource-name-max-width {
    min-width: 295px;
}
