.full-page-loader {
    @include display(flex);
    @include align-items(center);
    @include justify-content(center);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    height: 100%;
    background: transparentize($dark-grey, .2);
}

.loader {
    @include transform(translateZ(0));
    position: relative;
    width: 10em;
    height: 10em;
    margin: 60px auto;
    font-size: 10px;
    text-indent: -9999em;
    border-top: 1.1em solid $loader;
    border-right: 1.1em solid $loader;
    border-bottom: 1.1em solid $loader;
    border-left: 1.1em solid $blue;
    border-radius: 50%;
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;

    &:after {
        width: 10em;
        height: 10em;
        border-radius: 50%;
    }
}

.loading-modal {
    position: relative;
    display: block;
    min-width: 400px;
    background: $white;
    border-radius: 5px;

    section {
        padding: 20px 40px;
    }

    h4 {
        @include responsive-font-size($font-size-md);
        color: $blue;
    }

    .processes {
        margin: 10px 0 30px;

        span {
            display: block;
            margin: 0 0 5px;
            @include responsive-font-size($baseFontSize);
        }
    }

    .text {
        margin-top: 35px;
        @include responsive-font-size($font-size-lg);
    }

    .close {
        position: absolute;
        top: -24px;
        right: -24px;

        @include responsive-font-size($font-size-lg);

        color: $input-background-dark-grey;
        cursor: pointer;
    }

    @-webkit-keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes load8 {
        0% {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }
}
