@import "../helpers";

.thing-form-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;

    overflow-y: scroll;

    width: 100%;
    height: 100%;

    background: transparentize($dark-grey, .2);

    .modal {
        position: absolute;
        width: 100%;
        z-index: 3;
    }
}

.thing-form {
    position: relative;

    .sub-header h4 {
        margin: 0;
        float: right;
        line-height: 75px;
        font-size: 18px;
        color: $security-grey;
    }

    .thing-subheader-imsi-title {
        line-height: 75px;
    }

    .thing-form-thing-state-title {
        color: $blue;
        text-transform: uppercase;
        font-size: 18px;
        font-size: 20px;
        font-weight: 500;
        margin-left: 6px;
    }

    .thing-form-state-title-grey {
        color: $security-grey;
        text-transform: uppercase;
        font-size: 18px;
        font-size: 20px;
        font-weight: 500;
        margin-left: 6px;
    }

    $input-width: 292.3px;
    $label-width: 150px;

    margin-bottom: 85px;

    .imsi-container {

        .input-name {
            margin-top: 10px;
        }

        #thing-imsi {
            position: absolute;
            left: 150px;
            top: 0;
            width: max-content;

            .radiobutton {
                margin-right: 22px;
                vertical-align: middle;
            }

        }

        .checkbox {
            vertical-align: 10px;
            margin-right: 25px;
        }

        .imsi-info {
            line-height: 46px;
            color: #333;
            border: 0;
            border-radius: 46px;
            outline: 0;
            @include responsive-font-size($font-size-md);
            font-weight: normal;
        }
    }

    .close {
        right: calc(3% - 25px);
        position: absolute;

        @include responsive-font-size($font-size-lg);

        color: $input-background-dark-grey;
        cursor: pointer;
    }

    .thing-block {
        padding: 2%;
        background-color: $white;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    .actions {
        //@include display(flex);
        //@include justify-content(space-between);
        //@include align-items(center);

        //position: relative;
        //padding: 10px;
        //margin-bottom: 10px;
        //
        //&::after {
        //    position: absolute;
        //    bottom: 0;
        //    left: -2%;
        //
        //    width: 104%;
        //    height: 1px;
        //
        //    background-color: $border-color;
        //    content: '';
        //}

        .title-for-actions {
            @include responsive-font-size($font-size-lg);

            .thing-current-state {
                position: absolute;
                top: -20px;
                left: 15%;

                .name {
                    text-transform: uppercase;
                }
                .status {
                    color: $extra-semi-grey;
                    text-transform: uppercase;
                }
            }
        }

        .status-action {
            padding: 10px;
            display: inline-block;
            background-color: transparent;

            &.checked {
                background-color: rgb(218,235,248);
                border: 1px $border-color solid;
                border-radius: 5px;

                .icon {
                    position: relative;

                    &::after {
                        @include calc(left, '50% - 23px');

                        position: absolute;
                        top: 0;

                        display: inline-block;
                        width: 46px;
                        height: 46px;

                        border-radius: 6px;

                        content: '';
                    }

                    .icon-suspend::before {

                    }
                }
            }

            &:focus {
                box-shadow: $shadow-for-focused-input;
            }

            .icon-pin-on-map {
                &::before {
                    color: $blue;
                }
            }
        }

        .vertical-divider {
            width: 1px;
            height: 90px;
            border-left: 1px $border-color solid;
        }

        .status {
            .status-action {
                margin-right: 20px;

                &:disabled:not(.checked) {
                    .icon .icon-bg:before, .icon .icon-limited-by-bundle .path1:before {
                        color: $search-grey;
                    }
                }

                .icon {
                    span {
                        color: $blue;
                        font-size: 34px;
                    }
                    .icon-bg:before {
                        color: $blue;
                    }

                    .icon-suspend {
                        .path1::before {
                            color: $blue;
                        }
                    }
                    .icon-limited-by-bundle {
                        .path2::before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-left: 0;
                        }
                    }
                }
            }

            &:last-child {
                margin-right: 0;
            }

        }

        .event {
            // float: left;
            .status-action {
                padding: 0;
                margin-top: 20px;
                &:not(:last-of-type) {
                    margin-right: 20px;
                }
                .icon {
                    i {
                        color: $blue;
                    }
                    i, span {
                        .icon-billings, .icon-SMS, .icon-refresh, .icon-pin-on-map {
                            color: $blue;
                        }
                        font-size: 34px;
                    }
                    .icon-billings, .icon-SMS, .icon-refresh, .icon-pin-on-map, .icon-refresh-page-option {
                        color: $blue;

                        .path1::before {
                            color: $blue;
                        }
                    }

                    .icon-SMS .path2::before {
                        color: $white;
                    }


                    .icon-bootstrap1, .icon-bootstrap2 {
                        .path2::before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-left: 0;
                        }
                    }

                    .icon-limited-by-bundle {
                        .path2::before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            margin-left: 0;
                        }
                    }
                }

                &.disabled {
                    .icon {
                        cursor: default;

                        i {
                            color: $search-grey;
                        }

                        span {

                            &.icon-pin-on-map {
                                &::before {
                                    color: $search-grey;
                                }
                            }

                            &.icon-refresh-page-option, &.icon-billings::before, .path1::before {
                                color: $search-grey;
                            }
                    }
                        // .icon-pin-on-map {
                        //    &:before {
                        //        color: $search-grey;
                        //    }
                        // }
                    }

                    .description {
                        color: $extra-semi-grey;
                    }
                }
            }
        }

        .icon {
            font-size: 46px;
            text-align: center;

            cursor: pointer;
        }
    }

    .thing-details {
        position: relative;

        .restriction-btn {
            float: right;
            background-color: $tag-button;
            border-radius: 12px;
        }

        .restriction-indicator {
            position: absolute;
            top: 43px;
            right: 10px;
        }
    }

    .title {
        @include display(flex);
        @include align-items(center);
        @include flex-flow(column nowrap);
        @include justify-content(center);

        height: 100px;
        background-color: $input-background-grey;

        border-top-right-radius: 10px;
        border-top-left-radius: 10px;


        h3 {
            margin-bottom: 8px;
            font-size: 38px;
            font-weight: 100;
            text-transform: uppercase;
        }
    }

    .section {
        .divided-rows {
            padding-top: 0;

            .alert {
                cursor: default;
            }

            td {
                line-height: 40px;

                &:last-of-type {
                    text-align: left;
                }
            }

            td,
            th {
                &:first-child {
                    width: 8px;
                    padding: 0;
                }
            }
        }

        .choise-ips-table {

            .bem-sortable-table-header {
                cursor: initial;
            }

            #network-select {
                width: 100%;
            }

            #ipv-type {
                width: 100%;
            }

            .ipv4 {
                margin-bottom: 0;
            }

            .item.ipv4 .btn-auto {
                left: 280px;
                padding: 0;
                top: 0;
            }

            .item.ipv4 .edit-field {
                left: 357px;
            }

            .item.ipv4 .flag {
                left: 382px;
            }
        }

    }

    .section-title {
        margin-bottom: 15px;

        .name {
            @include responsive-font-size($font-size-lg);
            font-weight: 500;
        }

        &.blocked-title {
            margin-bottom: 35px;
        }

        .date {
            @include responsive-font-size($baseFontSize);
            color: $things-colors-date-color;
            display: inline-block;
            margin-left: 20px;
            font-weight: 300;
        }

        &.sim-data{
            margin-bottom: 35px;
        }

        &.choise-ips-title {
            margin-bottom: 30px;

            .bem-btn {
                float: right;
                margin-right: 15px;
            }
        }
    }

    //[type=text]:disabled {
    //    background-color: $white;
    //}

    //[type=text] {
    //    width: $input-width;
    //    background-color: $input-background-dark-grey;
    //}

    #mobile-network-name {
        padding-left: 34px;
    }

    .group-name {
        margin-bottom: 5px;
    }

    // .left {
    //     float: left;
    //     width: 50%;
    //
    //     .styled-select {
    //         width: 290px;
    //     }
    // }
    //
    // .right {
    //     float: right;
    //     width: 50%;
    // }

    .input-name {
        display: inline-block;
        width: $label-width;
        font-weight: 400;
        color: $security-grey;

        &.mobile-network-name {
            width: 157px;
        }
    }

    .item {
        position: relative;
        margin-bottom: 15px;

        textarea {
            height: 80px;
            border-radius: 20px;
            resize: none;
        }

        .label-for-textarea {
            position: relative;
            top: -60px;
        }

        .title-tags {
            float: left;
            width: $label-width;
            font-weight: 300;
        }

        .tags-form {
            button {
                position: relative;
                top: 1px;
            }
        }

        .edit-field {
            //position: absolute;
            //top: 19%;
            //left: $label-width + $input-width + 8px;
            @include responsive-font-size($font-size-lg);
            color: $one-more-grey;

            &.folder {
                cursor: pointer;
            }
        }

        .flag {
            //position: absolute;
            //top: 8px;
            //left: 481px;
            font-size: 26px;

            .lock-trigger {

                &::before {
                    font-family: 'Aqua-Icons';
                    color: $btn-green-color-2;
                    cursor: pointer;
                    content: '\e903';
                }
            }

            [type=checkbox]:checked + .lock-trigger::before {
                color: $thing-form-pink;
                content: '\e902';
            }
        }

        .priceplan-tooltip {
            position: absolute;
            top: 19%;
            left: $label-width + $input-width + 8px;
            @include responsive-font-size($font-size-md);
            color: $white;
            text-align: center;
            background-color: $blue;
            height: 28px;
            border-radius: 15px;
            line-height: 26px;
            padding: 1px 10px;
        }

        .billingstate-tooltip {
            position: absolute;
            top: 19%;
            left: $label-width + $input-width + 8px;
            @include responsive-font-size($font-size-md);
            color: $white;
            text-align: center;
            background-color: $blue;
            height: 28px;
            border-radius: 15px;
            line-height: 26px;
            padding: 1px 10px;
        }

        %button {
            position: absolute;
            left: 376px;

            width: 70px;
            height: 38px;

            color: $white;

            border-top-right-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }

        .btn-auto {
            @extend %button;
            cursor: initial;
            background-color: $btn-green-color-2;
        }

        .btn-check {
            @extend %button;
            background-color: $thing-form-pink;
        }

        &.ipv6,
        &.ipv4 {
            [type=text] {
                width: 350px;
            }

            .btn-auto {
                left: 433px;
            }

            .edit-field {
                left: 511px;
            }

            .flag {
                left: 540px;
            }

            &.input-has-error {
                .validation {
                    left: 165px;
                }
            }
        }

        .disabled-item {
            background-color: $one-more-grey;
        }

    }

    .actions-blocked {
        .item {
            margin-right: 15px;
        }

        .checkbox-eye {
            margin-right: 10px;

            &::before {
                font-size: 27px;
            }

            & + .auto-label {
                color: $btn-green-color-2;
            }
        }

        [type=checkbox]:checked {
            & + .checkbox-eye {
                &::before {
                    font-size: 25px;
                }
            }

            & ~ .auto-label {
                color: $thing-form-pink;
            }
        }
        .auto-label {
            display: inline-block;
            min-width: 250px;
            @include responsive-font-size($font-size-md);
            font-weight: 300;
            cursor: pointer;
        }
    }

    .thing-measures {
        .item {
            margin-right: 15px;
            margin-bottom: 15px;

            @include responsive-font-size($font-size-lg);
            font-weight: 300;
        }
    }

    .events-filters {
        .titles {

        }

        // [type=text] {
        //     @extend .bem-form-input;
        //     height: 33px;
        //     padding: 0;
        //     margin: 0;
        // }

        // .styled-select {
        //     @extend .bem-form-select;
        //     width: 200px;
        //     height: 33px;
        //     line-height: initial;

        //     option {
        //         padding: 10px 15px;

        //         &:hover {
        //             background-color: $cancel-grey;
        //         }
        //     }
        // }

        .btn-filter-go {
            width: 60px;
            height: 33px;
            border-radius: 20px;

            background-color: $btn-green-color-2;
            color: $white;
        }
    }

    .blocks {
        @include display(flex);
        @include justify-content(space-between);

        .block {
            position: relative;

            padding: 10px 20px;
            width: 30%;
            height: 180px;

            &:last-child {
                padding: 0;
            }

            .name {
                margin-bottom: torem(15px);
                font-size: torem(20px);
                font-weight: 300;
            }

            .stat {
                font-size: 40px;
                font-weight: 200;

                color: $thing-form-pink-2;

                .big {
                    font-size: 60px;
                    font-weight: 300;
                }

                &.blue {
                    color: $tag-button;
                }
            }

            .comment {
                @include responsive-font-size($baseFontSize);
                color: $input-background-dark-grey;
            }

            &.alarms {
                width: 10%;

                text-align: center;
            }

            &.map {
                width: 20%;

                .name {
                    position: absolute;
                    top: 10px;
                    left: 20px;
                    z-index: 1;
                }

                .coord {
                    position: absolute;
                    bottom: 42px;
                    left: 15px;

                    @include responsive-font-size($baseFontSize);
                    font-weight: 500;
                }

                .btn-map {
                    position: absolute;
                    bottom: 0px;

                    width: 100%;
                    height: 40px;

                    background-color: $tag-button;
                    color: white;

                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                }

            }
        }
    }

    .buttons {
        .button {
            height: 36px;
            padding: 0 15px;
            margin-right: 4px;
            font-weight: 300;
            color: $white;
            background-color: $btn-green-color-2;
            border-radius: 46px;
        }
    }

    .clear-fix {
        &::after {
            display: table;
            clear: both;
            content: '';
        }
    }

    .load-more-wrapper {
        padding: 0 15px;
    }

    .load-more {
        display: block;
        width: 100%;
        height: 3rem;
        margin: 1rem 0;
        font-weight: 500;
        color: $extra-dark-grey;
        background: $security-background-grey;
        border: 1px solid $radio-border-grey;
        border-radius: 4px;
    }

    .validation-thing-msisdn {
         left: 157px;
     }

    .bem-modifier_no-bottom-margin {
        margin-bottom: 0;
    }

    .bem-modifier_no-border-radius {
        border-radius: 0;
    }

    .bem-modifier_padding-top-0 {
        padding-top: 0;
    }

    .bem-modifier_padding-bottom-1 {
        padding-bottom: 1px;
    }

    .bem-modifier_padding-top-10 {
        padding-top: 10px;
    }
}

.modal-things-groups {
    .modal {
        border-radius: 5px;

        .step-1 {
            padding: 20px 15px;
            text-align: left;

            .title {
                @include responsive-font-size($font-size-lg);
            }
        }

        .close {
            position: absolute;
            top: -22px;
            right: -20px;

            @include responsive-font-size($font-size-lg);

            color: $input-background-dark-grey;
            cursor: pointer;
        }

        .step-2 {
            padding: 0 15px 20px;
            max-width: 550px;

            &.login-page {
                max-width: 750px;
            }

            .title {
                @include responsive-font-size($font-size-lg);
                text-transform: uppercase;
            }

            .icon {
                margin-top: 20px;
                font-size: 100px;

                span {
                   &::before {
                    color: $modal-pink;
                   }
                }
            }

            .warning-text {
                line-height: 24px;
                margin-bottom: 30px;
                color: $modal-pink;
            }

            .conf {
                margin-bottom: 30px;
                font-weight: 300;
            }
        }
    }

    header {
        h1 {
            margin: 0px;
            font-size: 50px;
            text-transform: none;
        }
    }

    .block {
        padding: 10px 0;
        margin: 10px 0 35px;
    }

    .group {
        [type=radio]:checked + label {
            background-color: #e3f2ff;
        }

        label {
            display: block;
            width: 100%;
            padding: 5px 20px;
        }
    }

    .footer {
        @include display(flex);
        @include justify-content(center);

        padding: 0;

        %btn-a {
            width: 135px;
            height: 42px;
            border-radius: 20px;

        }
        .btn-save {
            @extend %btn-a;

            margin-right: 20px;

            background-color: $tag-button;

            @include responsive-font-size($baseFontSize);
            font-weight: 600;
            color: #fff;
            text-transform: uppercase;
        }

        .btn-cancel {
            @extend %btn-a;

            background-color: $white;
            border: 1px solid $security-border-grey;

            color: $security-border-grey;
            font-weight: 300;
        }
    }
}

.modal-thing-form-map {
    .modal {
        width: 90%;
        height: 90%;

        .close {
            position: absolute;
            top: -22px;
            right: -20px;

            @include responsive-font-size($font-size-lg);

            color: $input-background-dark-grey;
            cursor: pointer;
        }
    }
}

.things-form-fix {
    .things-form-network {
        &.input-has-error {
            .validation {
                width: 240px;
            }
        }
    }
}

.bem-thing-form__input-hint {
    top: 15px;
    left: 400px;
}

.bem-thing-current-state {
    //position: absolute;
    //top: -20px;
    //left: 15%;
    font-size: torem(20px);
}

.bem-thing-current-state-name {
    text-transform: uppercase;
}
.bem-thing-current-state-status {
    color: $extra-semi-grey;
    text-transform: uppercase;
}

.thing-actions-positioning-ver {
    top: 0;
    right: 0;
}

.thing-actions-positioning-hr {
    left: 0;
    bottom: 0;
}

//.bem-ver-divider {
//    width: 1px;
//    height: 90px;
//    border-left: 1px $border-color solid;
//}

.bem-edit-field {
    @include responsive-font-size($font-size-lg);
    color: $one-more-grey;

    position: absolute;
    top: 8px;
    right: 25px;

    //&.folder {
    //    cursor: pointer;
    //}
}

.bem-edit-field_tags-position {
    top: 20px;
}

.bem-priceplan-tooltip {
    font-weight: 500;
    @include responsive-font-size($font-size-md);
    color: $white;
    text-align: center;
    background-color: $blue;
    height: 28px;
    border-radius: 15px;
    line-height: 26px;
    padding: 1px 10px;
}

.bem-dummy-icon {
    width: 28px;
    height: 24px;
}

.bem-block {
    background-color: #fff;
    border: 1px solid #bbb;
    border-radius: 5px;

    position: relative;

    padding: 10px 20px;
    width: 100%;
    height: 180px;
}

.bem-block-name {
    margin-bottom: torem(15px);
    font-size: torem(20px);
    font-weight: 300;
}

.bem-block-stats {
    font-size: torem(40px);
    font-weight: 200;

    color: $thing-form-pink-2;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bem-block-stats__big-fonts {
    font-size: torem(60px);
    font-weight: 300;
    white-space: nowrap;
}

.bem-block-stats__blue {
    color: $tag-button;
}

.bem-block-comment {
    font-size: torem(16px);
    color: $input-background-dark-grey;
}

.bem-block_alarms {
    width: 75%;

    text-align: center;
}

.bem-block_map {
    padding: 0;
    width: 99%;
}

.bem-block_map__name {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 1;
    font-size: 16px;
    letter-spacing: -0.4px;
}

.bem-block_map__coord {
    position: absolute;
    font-size: 0.86rem;

    @include responsive-font-size('0.86rem');
    font-weight: 400;
}

.bem-block_map__coord__position-map-date {
    bottom: 106px;
    left: 10px;
}

.bem-block_map__coord__position-cellid {
    bottom: 85px;
    left: 10px;
}

.bem-block_map__coord__position-lat-long {
    bottom: 64px;
    left: 10px;
}

.bem-block_map__coord__position-iccid,
.bem-block_map__coord__position-lat-long-thing {
    bottom: 42px;
    left: 10px;
}

//.btn-map {
//    position: absolute;
//    bottom: 0px;
//
//    width: 100%;
//    height: 40px;
//
//    background-color: $tag-button;
//    color: white;
//
//    border-bottom-right-radius: 5px;
//    border-bottom-left-radius: 5px;
//}

.thing-styled-icon {
    color: $blue;
    font-size: 34px;
}

.mobile-subscriber-browser {
    .tumbler-container {
        display: flex;
        align-items: center;
        height: 100%;

        .bem-tumbler::after {
            line-height: 18px;
        }
    }
}

.icon-SMS__path2-thing-form-positioning {
    position: absolute;
    left: 34px;
    top: 0px;
}
