.profile-form {
    margin: 0;

    form {
        padding: 25px;

        label {
            width: 220px;
        }
    }

    .suplementary-services {
        .suplementary-h4 {
            font-size: 26px;
            color: $blue;
        }

        .in-form-title {
            margin: 32px 0 5px;
        }

        @media screen and (max-width: $break-profile-form) {
            .in-form-title {
                margin: 20px 0 0px;
            }
        }

        .suplementary-adaptive-labels {
            .suplementary-labels {
                @media screen and (max-width: $break-profile-form) {
                    float: left;
                    padding-top: 20px;
                    margin-right: 55px;
                    margin-bottom: 27px;
                    line-height: 19px;
              }
            }
        }

        .suplementary-labels {
            width: 240px;
            margin-right: 10px;
        }

        .radiobutton-block {
            line-height: 46px;
        }

        .input-has-error {
            .styled-select {
                padding: 0 13px;
                background-position: right 10px center;
            }
        }

        .validation {
            top: 5px;
            left: 12px;
            width: 150px;
        }
    }

    .input-field {
        line-height: normal;

        .input-label {
            float: left;
            //padding-top: 10px;
        }
    }

    .form-table {
        margin-top: 20px;
    }

    .table-bordered {
        td:last-of-type {
            padding: 15px;
            @include responsive-font-size($font-size-md);
            text-align: left;
            border-left: 1px solid $border-color;
        }

        th {
            height: 77px;
            @include responsive-font-size($font-size-xl);
            font-weight: 100;
        }

        th:last-of-type {
            border-left: 1px solid $border-color;
        }

        .w-38 {
            width: 38%;
        }

        .w-36 {
            width: 36%;
        }

        .w-32 {
            width: 32%;
        }

        .w-30 {
            width: 30%;
        }

        .w-20 {
            width: 20%;
        }

        .w-16 {
            width: 16%;
        }

        .w-15 {
            width: 15%;
        }

        .w-14 {
            width: 14%;
        }

        .w-10 {
            width: 10%;
        }

        .w-9 {
            width: 9%;
        }

        .w-6 {
            width: 6%;
        }

        .td-gray {
            //background: $security-background-grey;
        }

        .styled-select {
            width: 100%;
            padding: 0;
            line-height: 30px;
            background-color: inherit;
            background-position: right 0 center;
            border: 0;
        }

        [type=text] {
            width: 100%;
        }

        label {
            line-height: 1;
            top: 8px;
        }
    }
}

.table-row {
    [type=text] {
        width: 100%;
    }
}

.pdpsubs-header {
    @include display(flex);

    .pdpsubs-td {
        @include display(flex);
        @include align-items(center);
        @include justify-content(flex-start);
        padding: 0 15px;
        margin-right: 5px;
        font-weight: 400;

        &.fake {
            width: 25px;
        }
    }

    &.wider {
        .pdpsubs-td {
            width: 127px;

            &.fake {
                width: 25px;
            }
        }
    }
}

.pdpsubs-container {
    padding: 20px 0;

    h4 {
        color: $blue;
    }

    .table-bordered {
        th {
            @include responsive-font-size($font-size-md);
        }
    }

    .row {
        @include display(flex);
        @include flex-direction(row);
        @include justify-content(flex-start);
        @include align-items(center);
        height: 150px;
        padding-left: 20px;
        margin-bottom: 20px;
    }

    [type=text] {
        width: 100%;
    }

    .cell {
        position: relative;
        width: 125px;
        padding: 0 10px 0 0;

        .notification {
            position: absolute;
            width: 150px;
            text-align: center;
            left: -14%;

            &::before {
                position: absolute;
                left: 50%;
                width: 2px;
                height: 20px;
                background-color: $input-background-dark-grey;
                content: '';
            }

            &.top {
                top: -45px;

                &::before {
                    top: 25px;
                }
            }

            &.bottom {
                bottom: -45px;

                &::before {
                    bottom: 25px;
                }
            }
        }
    }

    .shadow {
        &:focus {
            box-shadow: $shadow-for-focused-input;
        }
    }

    .buttons {
        display: inline;
        margin-right: 10px;

        button {
            width: 38px;
            height: 38px;
            background-color: white;
            border: 2px solid black;
            font-size: 38px;
            line-height: 30px;
            font-weight: 300;

            &:focus {
                box-shadow: $shadow-for-focused-input;
            }
        }
    }
}

.right-block {
    &.after-pdpsubs {
        .validation {
            top: 0;
            left: 275px;
            width: 260px;
        }
    }
}

.after-pdpsubs {
    padding: 20px 10px;
    margin-top: 30px;
}

.margin-label {
    margin-right: 1rem;
}

.vx-profile-block {
    width: 275px;
}

.first-right-block {
    margin-top: 56px;
}

.select-button {
    position: relative;
    display: inline-block;
    margin-bottom: 10px;

    .select-result {
        position: absolute;
        top: 38px;
        left: 10px;
    }
}

.security-form {
    .input-field {
        &.select-options {
            height: 92px;
        }
    }
}

.in-form-title {
    height: 46px;
    margin: 18px 0 18px;
    line-height: 46px;
    color: $blue;
}

.left-edit-block {
    float: left;
    width: 50%;
    // margin-right: 40px;

    .validation {
        left: 255px;
    }
}

.right-edit-block {
    float: right;
    width: 50%;
}

.left-edit-block,
.right-edit-block {
    @media screen and (max-width: $break-large) {
        float: none;
        width: 100%;
    }
}

.qos-modal {
    .modal {
        overflow-y: auto;
        position: absolute;
        top: 10px;

        form {
            [type=text],
            [type=password],
            .file,
            .styled-select {
                width: 275px;
                height: 46px;
                margin-bottom: 0;
            }

            label {
                display: inline-block;
                float: left;
                width: 150px;
            }
        }

        .modal-content {
            h4 {
                padding-top: 10px;
                @include responsive-font-size($font-size-md);
                font-weight: 400;
            }
        }

        .hr-divider {
            margin-top: 15px;
            margin-bottom: 30px;
            border-top: 1px solid $semi-grey;
            opacity: .5;
        }

        footer {
            max-width: 600px;
        }

        .left-block {
            float: left;
            margin-right: 40px;
        }

        .right-block {
            float: right;
        }
        .validation-Guaranteed-Bit-Rate-For-Downlink-extended,
        .validation-Maximum-Bit-Rate-For-Downlink-extended {
            margin-top: 12px;
        }

        .validation {
            left: 155px;
        }
    }
}

.security-profile-block {
    margin: 15px;
}
