.modal-wrapper {
    .rejection-approval-comments {

        header {
            h4 {
                color: $black;
            }
        }

        .container {
            padding: 5px 35px;
            text-align: left;
        }

        .comments-field {
            width: 100%;
            height: 250px;

            margin: 0;
            padding: 5px;

            border-radius: 5px;
            border: 0;

            resize: none;

            line-height: initial;

            background-color: $input-background-dark-grey;

            &.comment-span {
                height: auto;
            }
        }

        .additional-styles {
            line-height: 1.5;
            padding: 16px;
        }

        &.comment-modal {
            &.modal {
                //position: absolute;
                //top: 35px;
                //margin-bottom: 20px;
            }
        }

    }
}

.rejection-approval-comments {

    //width: 800px;

    .warning-text {
        margin-top: 10px;
        margin-bottom: 10px;

        font-size: 24px;
        font-weight: 400;

        color: $notification-pink;
    }

    .date-info {

        margin-bottom: 10px;
        @include responsive-font-size($font-size-md);

        .plan-name {
            font-weight: 400;
            color: $tag-button;
        }

    }

    .modify-info {

        margin-bottom: 40px;

        .gray {
            color: $light-grey;
            font-weight: 300;
        }

        .author {
            font-weight: 500;
        }

    }

    .required-info {

        margin-bottom: 20px;

        .red {
            color: $notification-pink;
        }

    }

    .footer {
        .btn {
            line-height: initial;
        }

        .cncl-btn {
            margin-right: 20px;
        }
    }
}
