$dir: '../font/aqua-icons/';
$font-path: './';
$font: 'Aqua-Icons';

@font-face {
  font-family: 'ui-grid';
  src: url('#{$font-path}ui-grid.eot');
  src: url('#{$font-path}ui-grid.eot#iefix') format('embedded-opentype'),
  url('#{$font-path}ui-grid.woff') format('woff'),
  url('#{$font-path}ui-grid.ttf?') format('truetype'),
  url('#{$font-path}ui-grid.svg?#ui-grid') format('svg');
  font-weight: normal;
  font-style: normal;
}

//@font-face {
//  font-family: 'Aqua-Icons';
//  src:
//    url('#{$dir}#{$font}.woff2?fih6ns') format('woff2'),
//    url('#{$dir}#{$font}.ttf?fih6ns') format('truetype'),
//    url('#{$dir}#{$font}.woff?fih6ns') format('woff'),
//    url('#{$dir}#{$font}.svg?fih6ns#Aqua-Icons') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'Aqua-Icons';
  src:
  url('#{$dir}#{$font}.woff2?mbaq93') format('woff2'),
  url('#{$dir}#{$font}.ttf?mbaq93') format('truetype'),
  url('#{$dir}#{$font}.woff?mbaq93') format('woff'),
  url('#{$dir}#{$font}.svg?mbaq93#Aqua-Icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:not(.fas), [class*=" icon-"]:not(.fas) {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Aqua-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//insert new icons here

.icon-organizationOne {
  &:before {
    content: $icon-organizationOne;
  }
}
.icon-Success {
  &:before {
    content: $icon-Success;
    color: #4a4a4a;
  }
}
.icon-inventory {
  &:before {
    content: $icon-inventory;
  }
}
.icon-Start-1 {
  &:before {
    content: $icon-Start-1;
    color: #686769;
  }
}
.icon-Start-2 {
  &:before {
    content: $icon-Start-2;
    color: #686769;
  }
}
.icon-Stop-1 {
  &:before {
    content: $icon-Stop-1;
    color: #686769;
  }
}
.icon-Stop-2 {
  &:before {
    content: $icon-Stop-2;
    color: #686769;
  }
}
.icon-user_login {
  &:before {
    content: $icon-user_login;
  }
}
.icon-user_pass {
  &:before {
    content: $icon-user_pass;
  }
}
.icon-truck1 {
  &:before {
    content: $icon-truck1;
  }
}
.icon-car {
  &:before {
    content: $icon-car;
  }
}
.icon-bag {
  &:before {
    content: $icon-bag;
  }
}
.icon-building {
  &:before {
    content: $icon-building;
  }
}
.icon-bulb {
  &:before {
    content: $icon-bulb;
  }
}
.icon-cart1 {
  &:before {
    content: $icon-cart1;
  }
}
.icon-cow {
  &:before {
    content: $icon-cow;
  }
}
.icon-drone {
  &:before {
    content: $icon-drone;
  }
}
.icon-duplicate {
  &:before {
    content: $icon-duplicate;
    color: #4a4a4a;
  }
}
.icon-factory {
  &:before {
    content: $icon-factory;
  }
}
.icon-gas {
  &:before {
    content: $icon-gas;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-house {
  &:before {
    content: $icon-house;
  }
}
.icon-mobile1 {
  &:before {
    content: $icon-mobile1;
  }
}
.icon-notification1 {
  &:before {
    content: $icon-notification1;
  }
}
.icon-pc {
  &:before {
    content: $icon-pc;
  }
}
.icon-people {
  &:before {
    content: $icon-people;
  }
}
.icon-plug {
  &:before {
    content: $icon-plug;
  }
}
.icon-shop {
  &:before {
    content: $icon-shop;
  }
}
.icon-traffic {
  &:before {
    content: $icon-traffic;
  }
}
.icon-water {
  &:before {
    content: $icon-water;
  }
}
.icon-wearables {
  &:before {
    content: $icon-wearables;
  }
}
.icon-Organization1 .path1 {
  &:before {
    content: $icon-Organization1-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path2 {
  &:before {
    content: $icon-Organization1-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path3 {
  &:before {
    content: $icon-Organization1-path3;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path4 {
  &:before {
    content: $icon-Organization1-path4;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path5 {
  &:before {
    content: $icon-Organization1-path5;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path6 {
  &:before {
    content: $icon-Organization1-path6;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path7 {
  &:before {
    content: $icon-Organization1-path7;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path8 {
  &:before {
    content: $icon-Organization1-path8;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path9 {
  &:before {
    content: $icon-Organization1-path9;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path10 {
  &:before {
    content: $icon-Organization1-path10;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path11 {
  &:before {
    content: $icon-Organization1-path11;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path12 {
  &:before {
    content: $icon-Organization1-path12;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path13 {
  &:before {
    content: $icon-Organization1-path13;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-Organization1 .path14 {
  &:before {
    content: $icon-Organization1-path14;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path15 {
  &:before {
    content: $icon-Organization1-path15;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-Organization1 .path16 {
  &:before {
    content: $icon-Organization1-path16;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-Organization1 .path17 {
  &:before {
    content: $icon-Organization1-path17;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-Organization1 .path18 {
  &:before {
    content: $icon-Organization1-path18;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-checkbox_on .path1 {
  &:before {
    content: $icon-checkbox_on-path1;
    color: rgb(54, 150, 218);
  }
}
.icon-checkbox_on .path2 {
  &:before {
    content: $icon-checkbox_on-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-checkbox_off {
  &:before {
    content: $icon-checkbox_off;
    color: #fff;
  }
}
.icon-bootstrap1 .path1 {
  &:before {
    content: $icon-bootstrap1-path1;
    color: rgb(87, 62, 125);
  }
}
.icon-bootstrap1 .path2 {
  &:before {
    content: $icon-bootstrap1-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-bootstrap2 .path1 {
  &:before {
    content: $icon-bootstrap2-path1;
    color: rgba(224, 46, 126, .85);
  }
}
.icon-bootstrap2 .path2 {
  &:before {
    content: $icon-bootstrap2-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-limited-by-bundle .path1 {
  &:before {
    content: $icon-bootstrap2-path1;
    color: rgba(224, 46, 126, .85);
  }
}
.icon-limited-by-bundle .path2 {
  &:before {
    content: $icon-bootstrap2-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-bill {
  &:before {
    content: $icon-bill;
  }
}
.icon-browser {
  &:before {
    content: $icon-browser;
  }
}
.icon-exit1 {
  &:before {
    content: $icon-exit1;
  }
}
.icon-pause1 {
  &:before {
    content: $icon-pause1;
  }
}
.icon-pin-on-map {
  &:before {
    content: $icon-pin-on-map;
  }
}
.icon-play1 {
  &:before {
    content: $icon-play1;
  }
}
.icon-refresh-page-option {
  &:before {
    content: $icon-refresh-page-option;
  }
}
.icon-APN_id .path1 {
  &:before {
    content: $icon-APN_id-path1;
    color: rgb(47, 53, 63);
  }
}
.icon-APN_id .path2 {
  &:before {
    content: $icon-APN_id-path2;
    margin-left: -1.3330078125em;
    color: rgb(255, 255, 255);
  }
}
.icon-SMS .path1 {
  &:before {
    content: $icon-SMS-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-SMS .path2 {
  &:before {
    content: $icon-SMS-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-thingsgroup {
  &:before {
    content: $icon-thingsgroup;
  }
}
.icon-pin-on-map2 {
  &:before {
    content: $icon-pin-on-map2;
  }
}
.icon-billings {
  &:before {
    content: $icon-billings;
  }
}
.icon-global_pool {
  &:before {
    content: $icon-global_pool;
  }
}
.icon-audit_log {
  &:before {
    content: $icon-audit_log;
  }
}
.icon-network_providers {
  &:before {
    content: $icon-network_providers;
  }
}
.icon-price_plan {
  &:before {
    content: $icon-price_plan;
  }
}
.icon-profiles {
  &:before {
    content: $icon-profiles;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-move_between_groups {
  &:before {
    content: $icon-move_between_groups;
  }
}
.icon-download_csv {
  &:before {
    content: $icon-download_csv;
  }
}
.icon-upload_csv {
  &:before {
    content: $icon-upload_csv;
  }
}
.icon-question-mark {
  &:before {
    content: $icon-question-mark;
    color: rgba(224, 46, 126, .85);
  }
}
.icon-warning2 {
  &:before {
    content: $icon-warning2;
    color: #f69421;
  }
}
.icon-approve .path1 {
  &:before {
    content: $icon-approve-path1;
    color: rgb(142, 198, 64);
  }
}
.icon-approve .path2 {
  &:before {
    content: $icon-approve-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-reject .path1 {
  &:before {
    content: $icon-reject-path1;
    color: rgb(204, 51, 102);
  }
}
.icon-reject .path2 {
  &:before {
    content: $icon-reject-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-active .path1 {
  &:before {
    content: $icon-active-path1;
    color: rgb(142, 198, 64);
  }
}
.icon-active .path2 {
  &:before {
    content: $icon-active-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-active .path3 {
  &:before {
    content: $icon-active-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-suspend .path1 {
  &:before {
    content: $icon-suspend-path1;
    color: rgba(224, 46, 126, .85);
  }
}
.icon-suspend .path2 {
  &:before {
    content: $icon-suspend-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-suspend .path3 {
  &:before {
    content: $icon-suspend-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-suspend .path4 {
  &:before {
    content: $icon-suspend-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-suspend .path5 {
  &:before {
    content: $icon-suspend-path5;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-provisioning .path1 {
  &:before {
    color: rgb(80, 82, 82) !important;
    content: $icon-provisioning-path1;
  }
}
.icon-provisioning .path2 {
  &:before {
    content: $icon-provisioning-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-provisioning .path3 {
  &:before {
    content: $icon-provisioning-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-api {
  &:before {
    content: $icon-api;
  }
}
.icon-imsi {
  &:before {
    content: $icon-imsi;
  }
}
.icon-archive {
  &:before {
    content: $icon-archive;
  }
}
.icon-calendar1 {
  &:before {
    content: $icon-calendar1;
    color: #4a4a4a;
  }
}
.icon-datausage {
  &:before {
    content: $icon-datausage;
  }
}
.icon-profit {
  &:before {
    content: $icon-profit;
  }
}
.icon-smsusage {
  &:before {
    content: $icon-smsusage;
  }
}
.icon-apn {
  &:before {
    content: $icon-apn;
  }
}
.icon-blocked1 {
  &:before {
    content: $icon-blocked1;
  }
}
.icon-ping .path1 {
  &:before {
    content: $icon-ping-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-ping .path2 {
  &:before {
    content: $icon-ping-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-ping .path3 {
  &:before {
    content: $icon-ping-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-ping .path4 {
  &:before {
    content: $icon-ping-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-refresh .path1 {
  &:before {
    content: $icon-refresh-path1;
    color: rgb(142, 198, 64);
  }
}
.icon-refresh .path2 {
  &:before {
    content: $icon-refresh-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-retire .path1 {
  &:before {
    content: $icon-retire-path1;
    color: rgb(237, 31, 36);
  }
}
.icon-retire .path2 {
  &:before {
    content: $icon-retire-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-sms .path1 {
  &:before {
    content: $icon-sebd-sms-path1;
    color: rgb(249, 160, 96);
  }
}
.icon-sebd-sms .path2 {
  &:before {
    content: $icon-sebd-sms-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-sms .path3 {
  &:before {
    content: $icon-sebd-sms-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-sms .path4 {
  &:before {
    content: $icon-sebd-sms-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-sms .path5 {
  &:before {
    content: $icon-sebd-sms-path5;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-ussd .path1 {
  &:before {
    content: $icon-sebd-ussd-path1;
    color: rgb(212, 64, 39);
  }
}
.icon-sebd-ussd .path2 {
  &:before {
    content: $icon-sebd-ussd-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-ussd .path3 {
  &:before {
    content: $icon-sebd-ussd-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-ussd .path4 {
  &:before {
    content: $icon-sebd-ussd-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-ussd .path5 {
  &:before {
    content: $icon-sebd-ussd-path5;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-sebd-ussd .path6 {
  &:before {
    content: $icon-sebd-ussd-path6;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-test-active .path1 {
  &:before {
    content: $icon-test-active-path1;
    color: rgba(224, 46, 126, .85);
  }
}
.icon-test-active .path2 {
  &:before {
    content: $icon-test-active-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-test-active .path3 {
  &:before {
    content: $icon-test-active-path3;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-test-active .path4 {
  &:before {
    content: $icon-test-active-path4;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-unblocked {
  &:before {
    content: $icon-unblocked;
  }
}
.icon-roaming {
  &:before {
    content: $icon-roaming;
  }
}
.icon-product {
  &:before {
    content: $icon-product;
  }
}
.icon-closepopup .path1 {
  &:before {
    content: $icon-closepopup-path1;
    color: rgb(255, 255, 255);
  }
}
.icon-closepopup .path2 {
  &:before {
    content: $icon-closepopup-path2;
    margin-left: -1em;
    color: rgb(1, 1, 1);
  }
}
.icon-customers {
  &:before {
    content: $icon-customers;
  }
}
.icon-profiles2 {
  &:before {
    content: $icon-profiles2;
  }
}
.icon-lock1 {
  &:before {
    content: $icon-lock1;
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock;
  }
}
.icon-update {
  &:before {
    content: $icon-update;
  }
}
.icon-warning1 .path1 {
  &:before {
    content: $icon-warning1-path1;
    color: rgb(229, 115, 115);
  }
}
.icon-warning1 .path2 {
  &:before {
    content: $icon-warning1-path2;
    margin-left: -1em;
    color: rgb(229, 115, 115);
  }
}
.icon-default-sim .path1 {
  &:before {
    content: $icon-default-sim-path1;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-default-sim .path2 {
  &:before {
    content: $icon-default-sim-path2;
    margin-left: -1em;
    color: rgb(254, 191, 16);
  }
}
.icon-default-sim .path3 {
  &:before {
    content: $icon-default-sim-path3;
    margin-left: -1em;
    color: rgb(254, 191, 16);
  }
}
.icon-default-sim .path4 {
  &:before {
    content: $icon-default-sim-path4;
    margin-left: -1em;
    color: rgb(254, 191, 16);
  }
}
.icon-default-sim .path5 {
  &:before {
    content: $icon-default-sim-path5;
    margin-left: -1em;
    color: rgb(254, 191, 16);
  }
}
.icon-default-sim .path6 {
  &:before {
    content: $icon-default-sim-path6;
    margin-left: -1em;
    color: rgb(254, 191, 16);
  }
}
.icon-default-sim .path7 {
  &:before {
    content: $icon-default-sim-path7;
    margin-left: -1em;
    color: rgb(254, 191, 16);
  }
}
.icon-default-sim .path8 {
  &:before {
    content: $icon-default-sim-path8;
    margin-left: -1em;
    color: rgb(254, 191, 16);
  }
}
.icon-profile-help {
  &:before {
    content: $icon-profile-help;
  }
}
.icon-profile-legal {
  &:before {
    content: $icon-profile-legal;
  }
}
.icon-profile-logout {
  &:before {
    content: $icon-profile-logout;
  }
}
.icon-profile-setting {
  &:before {
    content: $icon-profile-setting;
  }
}
.icon-profile-support {
  &:before {
    content: $icon-profile-support;
  }
}
.icon-thing_building .path1 {
  &:before {
    content: $icon-thing_building-path1;
    color: rgb(197, 202, 230);
  }
}
.icon-thing_building .path2 {
  &:before {
    content: $icon-thing_building-path2;
    margin-left: -1em;
    color: rgb(161, 169, 213);
  }
}
.icon-thing_building .path3 {
  &:before {
    content: $icon-thing_building-path3;
    margin-left: -1em;
    color: rgb(190, 59, 38);
  }
}
.icon-thing_building .path4 {
  &:before {
    content: $icon-thing_building-path4;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path5 {
  &:before {
    content: $icon-thing_building-path5;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path6 {
  &:before {
    content: $icon-thing_building-path6;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path7 {
  &:before {
    content: $icon-thing_building-path7;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path8 {
  &:before {
    content: $icon-thing_building-path8;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path9 {
  &:before {
    content: $icon-thing_building-path9;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path10 {
  &:before {
    content: $icon-thing_building-path10;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path11 {
  &:before {
    content: $icon-thing_building-path11;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path12 {
  &:before {
    content: $icon-thing_building-path12;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path13 {
  &:before {
    content: $icon-thing_building-path13;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_building .path14 {
  &:before {
    content: $icon-thing_building-path14;
    margin-left: -1em;
    color: rgba(224, 46, 126, 1);
  }
}
.icon-thing_car .path1 {
  &:before {
    content: $icon-thing_car-path1;
    color: rgb(56, 72, 80);
  }
}
.icon-thing_car .path2 {
  &:before {
    content: $icon-thing_car-path2;
    margin-left: -1em;
    color: rgb(56, 72, 80);
  }
}
.icon-thing_car .path3 {
  &:before {
    content: $icon-thing_car-path3;
    margin-left: -1em;
    color: rgb(85, 111, 122);
  }
}
.icon-thing_car .path4 {
  &:before {
    content: $icon-thing_car-path4;
    margin-left: -1em;
    color: rgb(85, 111, 122);
  }
}
.icon-thing_car .path5 {
  &:before {
    content: $icon-thing_car-path5;
    margin-left: -1em;
    color: rgb(85, 111, 122);
  }
}
.icon-thing_car .path6 {
  &:before {
    content: $icon-thing_car-path6;
    margin-left: -1em;
    color: rgb(85, 111, 122);
  }
}
.icon-thing_car .path7 {
  &:before {
    content: $icon-thing_car-path7;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_car .path8 {
  &:before {
    content: $icon-thing_car-path8;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_factory .path1 {
  &:before {
    content: $icon-thing_factory-path1;
    color: rgb(190, 59, 38);
  }
}
.icon-thing_factory .path2 {
  &:before {
    content: $icon-thing_factory-path2;
    margin-left: -1em;
    color: rgb(229, 77, 37);
  }
}
.icon-thing_factory .path3 {
  &:before {
    content: $icon-thing_factory-path3;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_factory .path4 {
  &:before {
    content: $icon-thing_factory-path4;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_factory .path5 {
  &:before {
    content: $icon-thing_factory-path5;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_factory .path6 {
  &:before {
    content: $icon-thing_factory-path6;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_factory .path7 {
  &:before {
    content: $icon-thing_factory-path7;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_factory .path8 {
  &:before {
    content: $icon-thing_factory-path8;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_gas .path1 {
  &:before {
    content: $icon-thing_gas-path1;
    color: rgb(45, 184, 234);
  }
}
.icon-thing_gas .path2 {
  &:before {
    content: $icon-thing_gas-path2;
    margin-left: -1em;
    color: rgb(240, 100, 139);
  }
}
.icon-thing_house .path1 {
  &:before {
    content: $icon-thing_house-path1;
    color: rgb(232, 233, 245);
  }
}
.icon-thing_house .path2 {
  &:before {
    content: $icon-thing_house-path2;
    margin-left: -1em;
    color: rgb(197, 202, 230);
  }
}
.icon-thing_house .path3 {
  &:before {
    content: $icon-thing_house-path3;
    margin-left: -1em;
    color: rgb(197, 202, 230);
  }
}
.icon-thing_house .path4 {
  &:before {
    content: $icon-thing_house-path4;
    margin-left: -1em;
    color: rgb(183, 32, 37);
  }
}
.icon-thing_house .path5 {
  &:before {
    content: $icon-thing_house-path5;
    margin-left: -1em;
    color: rgb(216, 70, 39);
  }
}
.icon-thing_house .path6 {
  &:before {
    content: $icon-thing_house-path6;
    margin-left: -1em;
    color: rgb(0, 88, 156);
  }
}
.icon-thing_house .path7 {
  &:before {
    content: $icon-thing_house-path7;
    margin-left: -1em;
    color: rgb(245, 138, 104);
  }
}
.icon-thing_phone .path1 {
  &:before {
    content: $icon-thing_phone-path1;
    color: rgb(85, 111, 122);
  }
}
.icon-thing_phone .path2 {
  &:before {
    content: $icon-thing_phone-path2;
    margin-left: -1em;
    color: rgb(89, 194, 239);
  }
}
.icon-thing_phone .path3 {
  &:before {
    content: $icon-thing_phone-path3;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path4 {
  &:before {
    content: $icon-thing_phone-path4;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path5 {
  &:before {
    content: $icon-thing_phone-path5;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path6 {
  &:before {
    content: $icon-thing_phone-path6;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path7 {
  &:before {
    content: $icon-thing_phone-path7;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path8 {
  &:before {
    content: $icon-thing_phone-path8;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path9 {
  &:before {
    content: $icon-thing_phone-path9;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path10 {
  &:before {
    content: $icon-thing_phone-path10;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path11 {
  &:before {
    content: $icon-thing_phone-path11;
    margin-left: -1em;
    color: rgb(181, 228, 250);
  }
}
.icon-thing_phone .path12 {
  &:before {
    content: $icon-thing_phone-path12;
    margin-left: -1em;
    color: rgb(56, 72, 80);
  }
}
.icon-thing_settings {
  &:before {
    content: $icon-thing_settings;
  }
}
.icon-thing_store .path1 {
  &:before {
    content: $icon-thing_store-path1;
    color: rgb(208, 216, 220);
  }
}
.icon-thing_store .path2 {
  &:before {
    content: $icon-thing_store-path2;
    margin-left: -1em;
    color: rgb(176, 190, 197);
  }
}
.icon-thing_store .path3 {
  &:before {
    content: $icon-thing_store-path3;
    margin-left: -1em;
    color: rgb(70, 91, 101);
  }
}
.icon-thing_store .path4 {
  &:before {
    content: $icon-thing_store-path4;
    margin-left: -1em;
    color: rgb(228, 242, 252);
  }
}
.icon-thing_store .path5 {
  &:before {
    content: $icon-thing_store-path5;
    margin-left: -1em;
    color: rgb(62, 131, 196);
  }
}
.icon-thing_store .path6 {
  &:before {
    content: $icon-thing_store-path6;
    margin-left: -1em;
    color: rgb(144, 164, 174);
  }
}
.icon-thing_store .path7 {
  &:before {
    content: $icon-thing_store-path7;
    margin-left: -1em;
    color: rgb(87, 140, 62);
  }
}
.icon-thing_store .path8 {
  &:before {
    content: $icon-thing_store-path8;
    margin-left: -1em;
    color: rgb(87, 140, 62);
  }
}
.icon-thing_store .path9 {
  &:before {
    content: $icon-thing_store-path9;
    margin-left: -1em;
    color: rgb(87, 140, 62);
  }
}
.icon-thing_store .path10 {
  &:before {
    content: $icon-thing_store-path10;
    margin-left: -1em;
    color: rgb(125, 179, 66);
  }
}
.icon-thing_store .path11 {
  &:before {
    content: $icon-thing_store-path11;
    margin-left: -1em;
    color: rgb(125, 179, 66);
  }
}
.icon-thing_store .path12 {
  &:before {
    content: $icon-thing_store-path12;
    margin-left: -1em;
    color: rgb(125, 179, 66);
  }
}
.icon-thing_store .path13 {
  &:before {
    content: $icon-thing_store-path13;
    margin-left: -1em;
    color: rgb(125, 179, 66);
  }
}
.icon-thing_store .path14 {
  &:before {
    content: $icon-thing_store-path14;
    margin-left: -1em;
    color: rgb(249, 160, 27);
  }
}
.icon-thing_store .path15 {
  &:before {
    content: $icon-thing_store-path15;
    margin-left: -1em;
    color: rgb(249, 160, 27);
  }
}
.icon-thing_store .path16 {
  &:before {
    content: $icon-thing_store-path16;
    margin-left: -1em;
    color: rgb(249, 160, 27);
  }
}
.icon-thing_store .path17 {
  &:before {
    content: $icon-thing_store-path17;
    margin-left: -1em;
    color: rgb(249, 160, 27);
  }
}
.icon-thing_store .path18 {
  &:before {
    content: $icon-thing_store-path18;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_store .path19 {
  &:before {
    content: $icon-thing_store-path19;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_store .path20 {
  &:before {
    content: $icon-thing_store-path20;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_store .path21 {
  &:before {
    content: $icon-thing_store-path21;
    margin-left: -1em;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_suitcase .path1 {
  &:before {
    content: $icon-thing_suitcase-path1;
    color: rgb(39, 50, 56);
  }
}
.icon-thing_suitcase .path2 {
  &:before {
    content: $icon-thing_suitcase-path2;
    margin-left: -1em;
    color: rgb(39, 50, 56);
  }
}
.icon-thing_suitcase .path3 {
  &:before {
    content: $icon-thing_suitcase-path3;
    margin-left: -1em;
    color: rgb(56, 72, 80);
  }
}
.icon-thing_suitcase .path4 {
  &:before {
    content: $icon-thing_suitcase-path4;
    margin-left: -1em;
    color: rgb(120, 144, 156);
  }
}
.icon-thing_traficlights .path1 {
  &:before {
    content: $icon-thing_traficlights-path1;
    color: rgb(47, 138, 202);
  }
}
.icon-thing_traficlights .path2 {
  &:before {
    content: $icon-thing_traficlights-path2;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-thing_traficlights .path3 {
  &:before {
    content: $icon-thing_traficlights-path3;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-thing_traficlights .path4 {
  &:before {
    content: $icon-thing_traficlights-path4;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-thing_traficlights .path5 {
  &:before {
    content: $icon-thing_traficlights-path5;
    margin-left: -1em;
    color: rgb(65, 175, 82);
  }
}
.icon-thing_traficlights .path6 {
  &:before {
    content: $icon-thing_traficlights-path6;
    margin-left: -1em;
    color: rgb(248, 149, 29);
  }
}
.icon-thing_traficlights .path7 {
  &:before {
    content: $icon-thing_traficlights-path7;
    margin-left: -1em;
    color: rgb(237, 18, 95);
  }
}
.icon-thing_truck .path1 {
  &:before {
    content: $icon-thing_truck-path1;
    color: rgb(253, 193, 19);
  }
}
.icon-thing_truck .path2 {
  &:before {
    content: $icon-thing_truck-path2;
    margin-left: -1em;
    color: rgb(145, 117, 181);
  }
}
.icon-thing_truck .path3 {
  &:before {
    content: $icon-thing_truck-path3;
    margin-left: -1em;
    color: rgb(145, 117, 181);
  }
}
.icon-thing_truck .path4 {
  &:before {
    content: $icon-thing_truck-path4;
    margin-left: -1em;
    color: rgb(145, 117, 181);
  }
}
.icon-thing_truck .path5 {
  &:before {
    content: $icon-thing_truck-path5;
    margin-left: -1em;
    color: rgb(145, 117, 181);
  }
}
.icon-thing_truck .path6 {
  &:before {
    content: $icon-thing_truck-path6;
    margin-left: -1em;
    color: rgb(145, 117, 181);
  }
}
.icon-thing_truck .path7 {
  &:before {
    content: $icon-thing_truck-path7;
    margin-left: -1em;
    color: rgb(120, 92, 167);
  }
}
.icon-thing_truck .path8 {
  &:before {
    content: $icon-thing_truck-path8;
    margin-left: -1em;
    color: rgb(120, 92, 167);
  }
}
.icon-thing_truck .path9 {
  &:before {
    content: $icon-thing_truck-path9;
    margin-left: -1em;
    color: rgb(120, 92, 167);
  }
}
.icon-thing_truck .path10 {
  &:before {
    content: $icon-thing_truck-path10;
    margin-left: -1em;
    color: rgb(120, 92, 167);
  }
}
.icon-thing_truck .path11 {
  &:before {
    content: $icon-thing_truck-path11;
    margin-left: -1em;
    color: rgb(56, 72, 80);
  }
}
.icon-thing_truck .path12 {
  &:before {
    content: $icon-thing_truck-path12;
    margin-left: -1em;
    color: rgb(56, 72, 80);
  }
}
.icon-thing_truck .path13 {
  &:before {
    content: $icon-thing_truck-path13;
    margin-left: -1em;
    color: rgb(120, 144, 156);
  }
}
.icon-thing_truck .path14 {
  &:before {
    content: $icon-thing_truck-path14;
    margin-left: -1em;
    color: rgb(120, 144, 156);
  }
}
.icon-thing_truck .path15 {
  &:before {
    content: $icon-thing_truck-path15;
    margin-left: -1em;
    color: rgb(70, 91, 101);
  }
}
.icon-thing_water .path1 {
  &:before {
    content: $icon-thing_water-path1;
    color: rgb(44, 184, 235);
  }
}
.icon-thing_water .path2 {
  &:before {
    content: $icon-thing_water-path2;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-thing_water .path3 {
  &:before {
    content: $icon-thing_water-path3;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-thing_water .path4 {
  &:before {
    content: $icon-thing_water-path4;
    margin-left: -1em;
    color: rgb(77, 160, 216);
  }
}
.icon-thing_water .path5 {
  &:before {
    content: $icon-thing_water-path5;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-thing_water .path6 {
  &:before {
    content: $icon-thing_water-path6;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-thing_water .path7 {
  &:before {
    content: $icon-thing_water-path7;
    margin-left: -1em;
    color: rgb(47, 138, 202);
  }
}
.icon-thing_water .path8 {
  &:before {
    content: $icon-thing_water-path8;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-thing_water .path9 {
  &:before {
    content: $icon-thing_water-path9;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-thing_water .path10 {
  &:before {
    content: $icon-thing_water-path10;
    margin-left: -1em;
    color: rgb(226, 242, 252);
  }
}
.icon-alert-error {
  &:before {
    content: $icon-alert-error;
  }
}
.icon-alert {
  &:before {
    content: $icon-alert;
  }
}
.icon-arrowdown {
  &:before {
    content: $icon-arrowdown;
  }
}
.icon-arrowup {
  &:before {
    content: $icon-arrowup;
  }
}
.icon-checkmark1 {
  &:before {
    content: $icon-checkmark1;
  }
}
.icon-checkbox {
  &:before {
    content: $icon-checkbox;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-Copy {
  &:before {
    content: $icon-Copy;
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard;
  }
}
.icon-download1 {
  &:before {
    content: $icon-download1;
  }
}
.icon-drag {
  &:before {
    content: $icon-drag;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-events {
  &:before {
    content: $icon-events;
  }
}
.icon-expand {
  &:before {
    content: $icon-expand;
  }
}
.icon-fav_off {
  &:before {
    content: $icon-fav_off;
  }
}
.icon-fav_on {
  &:before {
    content: $icon-fav_on;
  }
}
.icon-gaspumps .path1 {
  &:before {
    content: $icon-gaspumps-path1;
    color: rgb(44, 184, 235);
  }
}
.icon-gaspumps .path2 {
  &:before {
    content: $icon-gaspumps-path2;
    margin-left: -1em;
    color: rgb(45, 184, 234);
  }
}
.icon-gaspumps .path3 {
  &:before {
    content: $icon-gaspumps-path3;
    margin-left: -1em;
    color: rgb(240, 100, 139);
  }
}
.icon-geo_fencing {
  &:before {
    content: $icon-geo_fencing;
  }
}
.icon-iot_store {
  &:before {
    content: $icon-iot_store;
  }
}
.icon-login_go_down {
  &:before {
    content: $icon-login_go_down;
    color: #7f7f7f;
  }
}
.icon-login_go_up {
  &:before {
    content: $icon-login_go_up;
  }
}
.icon-map_black .path1 {
  &:before {
    content: $icon-map_black-path1;
    color: rgb(0, 0, 0);
  }
}
.icon-map_black .path2 {
  &:before {
    content: $icon-map_black-path2;
    margin-left: -1em;
    color: rgb(0, 0, 0);
  }
}
.icon-map_black .path3 {
  &:before {
    content: $icon-map_black-path3;
    margin-left: -1em;
    color: rgb(160, 159, 159);
  }
}
.icon-map_green .path1 {
  &:before {
    content: $icon-map_green-path1;
    color: rgb(65, 175, 81);
  }
}
.icon-map_green .path2 {
  &:before {
    content: $icon-map_green-path2;
    margin-left: -1em;
    color: rgb(65, 175, 81);
  }
}
.icon-map_green .path3 {
  &:before {
    content: $icon-map_green-path3;
    margin-left: -1em;
    color: rgb(0, 150, 136);
  }
}
.icon-map_red .path1 {
  &:before {
    content: $icon-map_red-path1;
    color: rgb(229, 30, 37);
  }
}
.icon-map_red .path2 {
  &:before {
    content: $icon-map_red-path2;
    margin-left: -1em;
    color: rgb(191, 32, 38);
  }
}
.icon-map_red .path3 {
  &:before {
    content: $icon-map_red-path3;
    margin-left: -1em;
    color: rgb(221, 69, 81);
  }
}
.icon-menuclose {
  &:before {
    content: $icon-menuclose;
  }
}
.icon-menuopen {
  &:before {
    content: $icon-menuopen;
  }
}
.icon-network {
  &:before {
    content: $icon-network;
  }
}
.icon-password {
  &:before {
    content: $icon-password;
  }
}
.icon-plus_green .path1 {
  &:before {
    content: $icon-plus_green-path1;
    color: rgb(98, 192, 136);
  }
}
.icon-plus_green .path2 {
  &:before {
    content: $icon-plus_green-path2;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}
.icon-pricing {
  &:before {
    content: $icon-pricing;
  }
}
.icon-radio_off {
  &:before {
    content: $icon-radio_off;
  }
}
.icon-radio_on {
  &:before {
    content: $icon-radio_on;
  }
}
.icon-reports {
  &:before {
    content: $icon-reports;
  }
}
.icon-search1 {
  &:before {
    content: $icon-search1;
  }
}
.icon-security {
  &:before {
    content: $icon-security;
  }
}
.icon-sort {
  &:before {
    content: $icon-sort;
  }
}
.icon-tag {
  &:before {
    content: $icon-tag;
  }
}
.icon-things {
  &:before {
    content: $icon-things;
  }
}
.icon-trafficlights .path1 {
  &:before {
    content: $icon-trafficlights-path1;
    color: rgb(47, 138, 202);
  }
}
.icon-trafficlights .path2 {
  &:before {
    content: $icon-trafficlights-path2;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-trafficlights .path3 {
  &:before {
    content: $icon-trafficlights-path3;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-trafficlights .path4 {
  &:before {
    content: $icon-trafficlights-path4;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-trafficlights .path5 {
  &:before {
    content: $icon-trafficlights-path5;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-trafficlights .path6 {
  &:before {
    content: $icon-trafficlights-path6;
    margin-left: -1em;
    color: rgb(65, 175, 82);
  }
}
.icon-trafficlights .path7 {
  &:before {
    content: $icon-trafficlights-path7;
    margin-left: -1em;
    color: rgb(248, 149, 29);
  }
}
.icon-trafficlights .path8 {
  &:before {
    content: $icon-trafficlights-path8;
    margin-left: -1em;
    color: rgb(237, 18, 95);
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-upload1 {
  &:before {
    content: $icon-upload1;
  }
}
.icon-username {
  &:before {
    content: $icon-username;
  }
}
.icon-users {
  &:before {
    content: $icon-users;
  }
}
.icon-waterpipes .path1 {
  &:before {
    content: $icon-waterpipes-path1;
    color: rgb(44, 184, 235);
  }
}
.icon-waterpipes .path2 {
  &:before {
    content: $icon-waterpipes-path2;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-waterpipes .path3 {
  &:before {
    content: $icon-waterpipes-path3;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-waterpipes .path4 {
  &:before {
    content: $icon-waterpipes-path4;
    margin-left: -1em;
    color: rgb(77, 160, 216);
  }
}
.icon-waterpipes .path5 {
  &:before {
    content: $icon-waterpipes-path5;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-waterpipes .path6 {
  &:before {
    content: $icon-waterpipes-path6;
    margin-left: -1em;
    color: rgb(44, 184, 235);
  }
}
.icon-waterpipes .path7 {
  &:before {
    content: $icon-waterpipes-path7;
    margin-left: -1em;
    color: rgb(47, 138, 202);
  }
}
.icon-waterpipes .path8 {
  &:before {
    content: $icon-waterpipes-path8;
    margin-left: -1em;
    color: rgba(59, 73, 73, 0.16);
  }
}
.icon-waterpipes .path9 {
  &:before {
    content: $icon-waterpipes-path9;
    margin-left: -1em;
    color: rgb(226, 242, 252);
  }
}
