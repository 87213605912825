.reports {
    .header-filters {
        height: 47px;

        background-color: $reports-green;

        .select {
            float: left;

            height: 100%;
            padding: 8px;

            .input-field {
                margin-bottom: 0;
            }

            .styled-select {
                width: 200px;
                height: 30px;
                padding: 0 14px;

                line-height: initial;

                color: $white;
                background-color: $reports-green-input;
            }

            &.month {
                .styled-select {
                    width: 145px;
                }
            }
        }

        .vertical-divider {
            float: left;
            margin-top: 8px;
        }

        #reports-start-date,
        #reports-end-date {
            height: 27px;
            width: 92px;
            padding: 0;

            line-height: initial;

            background-color: $reports-green;
            color: white;
        }

        .date {
            float: left;
            height: 100%;
            padding: 8px;

            .input-date {
                display: inline-block;
                width: 40px;
                height: 30px;
                padding: 3px 0;
                margin-right: 5px;

                font-size: 21px;
                color: $white;
                text-align: center;

                cursor: pointer;

                background-color: $reports-green-input;
                border-radius: 10px;

            }

            .date-text {
                color: $white;
                cursor: pointer;
            }
        }

        .current-date-to {
            float: left;
            height: 100%;
            padding: 15px 3px;

            color: $white;
        }
    }

    .detail-info {
        padding: 25px 20px;

        .left {
            float: left;
            width: 26%;
        }

        .right {
            float: left;
            width: 74%;
        }

        .full-report {
            width: 250px;
            height: 115px;
            margin-bottom: 13px;

            border-radius: 10px;

            cursor: pointer;

            &:last-child {
                margin-bottom: 0;
            }

            &.active-report {
                border: 1px solid $reports-green;
            }

            .pict,
            .measures {
                display: inline-block;
                width: 50%;
                height: 100%;
            }

            .pict {
                position: relative;
                float: left;

                background-color: $reports-green-one-more;

                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;

                .icon {
                    padding-top: 18px;
                    font-size: 45px;
                    color: $white;
                    text-align: center;
                }

                .sign {
                    position: absolute;
                    bottom: 0;

                    width: 100%;
                    height: 30px;
                    padding-top: 6px;

                    color: $white;
                    text-align: center;

                    background-color: $alert-gray;
                    border-bottom-left-radius: 10px;
                }

                &.red {
                    background-color: $reports-red;
                }

                &.green {
                    background-color: $reports-green-stop-it;
                }
            }

            .measures {
                padding-top: 20px;

                text-align: center;

                background-color: $white;
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;

                .title {
                    color: $reports-green-one-more;

                    &.red {
                        color: $reports-red;
                    }

                    &.green {
                        color: $reports-green-stop-it;
                    }
                }

                .number {
                    font-weight: 100;
                    color: $reports-gray;

                    .num {
                        font-size: 44px;
                    }

                    .metric {
                        font-size: 30px;
                        text-transform: uppercase;
                    }
                }

                &.profit {
                    padding-top: 28px;

                    .number {
                        .num {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        .data-usage-bar {
            width: 100%;
            height: 410px;

            .title {
                margin-bottom: 15px;
                color: $reports-green-one-more;
            }

            .red {
                color: $reports-red;
            }
        }
    }

    .reports-table {
        padding: 25px 20px;

        .table-bordered {
            th {
                position: relative;
                cursor: pointer;

                &:last-child {
                    text-align: right;
                }

                .sort-icon {
                    position: absolute;
                    top: 24px;
                    right: 14px;

                    @include responsive-font-size($font-size-md);
                    color: $border-color;
                }

                .metric {
                    display: inline-block;
                    margin-right: 5px;

                    @include responsive-font-size($baseFontSize);
                    font-weight: 300;
                    color: $black;

                    text-transform: uppercase;
                }

            }
        }

        .edit {
            font-weight: 100;
        }
    }
}

.reports-page {
    .expandableRow {
        position: relative;
        z-index: 0 !important;
    }
}
