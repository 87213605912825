$protected-images-dir: '../images/protected/';

.protected {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url($protected-images-dir + 'cloud_bg.png');
    background-size: cover;

    input:focus,
    input:focus:not([readonly]),
    input:not([type]):focus:not([readonly]),
    input[type=text]:focus:not([readonly]),
    input[type=number]:focus:not([readonly]),
    input[type=password]:focus:not([readonly]),
    textarea.materialize-textarea:focus:not([readonly]) {
        border: 0;
        outline: 0;
        box-shadow: none !important;
    }

    form {
        @include transform(translateY(-50%));
        position: absolute;
        top: 50%;
        left: 235px;
        width: 471px;
        background: #121317;
        border-radius: 14px;
        box-shadow: 0 12px 21px rgba(0,0,0,.35);

        header {
            padding: 40px 0;
            font-size: 40px;
            font-weight: 200;
            color: $white;
            text-align: center;
            background-color: #2b2d31;
            border-top-right-radius: 14px;
            border-top-left-radius: 14px;

            small {
                display: block;
                font-size: 28px;
                color: #979696
            }
        }

        .form-content {
            padding: 50px 50px 23px;
        }

        .input-field {
            margin: 0;
        }

        input[type=text],
        input[type=text].ng-dirty.ng-valid {
            height: 70px;
            margin-bottom: 36px;
            padding: 0 40px;
            font-size: 24px;
            font-weight: 200;
            line-height: 70px;
            color: #eeeeee !important;
            background-color: #18191e;
            border: 0;
            outline: 0;
            box-sizing: border-box;
            box-shadow: inset 0 1px rgba(255, 255, 255, .04);

            &:hover,
            &:hover:not([readonly]),
            &:focus,
            &:focus:not([readonly]),
            &.ng-dirty.ng-valid,
            &.ng-dirty.ng-valid {
                border: 0;
                outline: 0;
                box-shadow: none;
            }

            + label {
                top: 19px;
                left: 40px;
                font-size: 24px;
                font-weight: 300;
                color: #979696 !important;
            }

            &.iccid {
                padding-right: 65px;
                background-image: url($protected-images-dir + 'iccid_icon.png');
                background-position: 320px;
                background-size: 34px 41px;
                background-repeat: no-repeat;
            }
        }

        input.ng-dirty.ng-valid {
            border: 0;
            box-shadow: none;
        }

        .toggle-icon {
            position: absolute;
            top: 30px;
            right: 22px;
            width: 24px;
            height: 14px;
            background-image: url($protected-images-dir + 'arrow_down.png');
            cursor: pointer;

            &.on {
                background-image: url($protected-images-dir + 'arrow_up.png');
            }
        }

        .list {
            position: absolute;
            z-index: 100;
            width: 371px;
            margin-top: -37px;
            color: #eeeeee;
            background-color: #18191e;

            ul {
                margin: 0;
                padding: 0 70px 0 20px;
            }

            li {
                height: 64px;
                padding: 0 40px 0 20px;
                font-size: 24px;
                font-weight: 200;
                line-height: 64px;
                border-top: 1px solid #575759;
                cursor: pointer;
            }
        }

        .input-field label.active {
            @include transform(translateY(-180%));
            font-size: 18px !important;
            color: #979696;
        }

        .big-btn {
            display: block;
            height: 80px;
            font-size: 24px;
            font-weight: 500;
            line-height: 80px;
            color: #6c411b;
            background-color: #d29d41;
            text-align: center;
            text-transform: uppercase;
            text-shadow: 0 1px 1px rgba(255, 255, 255, .3);
            box-shadow: inset 0 1px rgba(255, 255, 255, .2);

            &.disabled {
                background-color: $search-grey;
            }
        }


        .accept {
            margin-top: 1rem;

            label {
                margin-top: 10px;
                padding-left: 30px;
                @include responsive-font-size($font-size-lg);
                color: #8b8b8b;
                //z-index: -1;
            }

            [type="checkbox"].filled-in:not(:checked) + label:after {
                border-color: #04a9f4;
                border-radius: 5px;
            }

            [type="checkbox"].filled-in:checked + label:after {
                border-color: #04a9f4;
                background-color: #04a9f4;
                border-radius: 5px;
            }

            [type="checkbox"].filled-in:not(:checked) + label:after,
            [type="checkbox"].filled-in:checked + label:after {
                top: 2px;
            }

            [type="checkbox"].filled-in:checked + label:before {
                top: 2px;
            }
        }
    }

    .success {
        @include transition(all .4s $ease-out-circ);
        //@include transform(translateX(-50%));
        position: absolute;
        top: 0;
        left: 50%;
        width: 700px;
        margin-left: -350px;
        background: #c5e67b;
        border-radius: 0 0 7px 7px;
        box-shadow: 0 2px 5px rgba(0,0,0, .2);

        p {
            margin: 10px 0;
            text-align: center;
        }

        &.hidden {
            @include transform(translateY(-120%));
        }
    }
}
