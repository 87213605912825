.tags-form {
    font-weight: 300;

    [type=text] {
        width: 220px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    [type=button] {
        height: 46px;
        padding: 0 25px;
        margin-left: -4px;
        line-height: 46px;
        background-color: $tag-button;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

.tags-list {
    padding: 20px 0 0;
}

.tag {
    position: relative;
    display: inline-block;
    padding: 3px 40px;
    margin: 0 10px 10px 0;
    font-weight: 300;
    color: $extra-semi-grey;
    border: 1px solid $tag-border;
    border-radius: 20px;

    &::before {
        position: absolute;
        top: 5px;
        left: 10px;
        font-family: 'Aqua-Icons';
        @include responsive-font-size($font-size-md);
        color: $tag-button;
        content: '\e93b';
    }

    i {
        position: absolute;
        top: 8px;
        right: 10px;
        font-size: .75rem;
        font-style: normal;
        cursor: pointer;

        &:hover {
            color: $black;
        }
    }
}
