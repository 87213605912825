@import "helpers";

.body-modal-layer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.modal-wrapper {
    //@include display(flex);
    //@include align-items(center);
    //@include justify-content(center);
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: transparentize($dark-grey, .2);

    &.allocate-modal {
        .modal {
            min-width: 900px;

            form {
                label {
                    width: 100px;
                }

                .validation {
                    left: 120px;
                }
            }
        }
    }



    .modal {
        position: relative;
        //position: absolute;
        z-index: 9999;
        display: flex;
        flex-direction: column;
        //min-width: 500px;
        //width: auto;
        //max-width: 900px;
        background: $white;
        border-radius: 5px;
        margin: 10px;
        //top: 35px;
        //margin-bottom: 15px;

        header {
            padding: 15px;
            margin-bottom: 15px;

            h4 {
                text-transform: capitalize;
                word-break: break-word;
            }
        }

        section {
            padding: 15px;
        }

        .modal-content {
            display: flex;
            flex-direction: column;
            width: auto;
            padding: 20px 40px;
        }

        footer {
            padding: 15px;
            margin-top: 15px;
        }

        .footer {
            @include display(flex);
            @include justify-content(center);

            padding: 30px 0;
        }

        .confirm-action {
            overflow-y: auto;
            max-height: 500px;
            @include responsive-font-size($font-size-md);
        }

        .close {
            position: absolute;
            top: -24px;
            right: -24px;

            @include responsive-font-size($font-size-lg);

            color: $input-background-dark-grey;
            cursor: pointer;
        }

        .network-modal-custom-select {
            width: 80%;
        }

        &.send-sms-ussd {
            $width: 400px;

            .input-field {
                margin-bottom: 15px;
            }

            .label-sms {
                width: $width;
                margin: 0 auto 14px;
                text-align: left;
            }

            .msg-from {
                width: $width;
                background-color: $input-background-dark-grey;
            }

            .textarea {
                width: $width;
                height: 135px;
                margin: 0;
                background-color: $input-background-dark-grey;
                border-radius: 20px;
                resize: none;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        .modal {
            //position: absolute;
            width: auto;
            background: $white;
            border-radius: 5px;
            margin: 30px auto;
            max-width: 90%;
            //top: 35px;
            //margin-bottom: 15px;
        }

        .bem-confirm-action-modal {
            max-width: 830px;
        }

        .bem-upload-imsi-info-modal {
            max-width: torem(500px);
        }
    }

    @include media-breakpoint-up(md) {
        .modal {
            max-width: 80%;
        }

        .bem-confirm-action-modal {
            max-width: 830px;
        }

        .bem-roaming-policy-widths {
            max-width: 82%;
        }

        .bem-upload-imsi-info-modal {
            max-width: torem(500px);
        }

        .bem-small-modal {
            max-width: torem(700px);
        }
    }

    @include media-breakpoint-up(lg) {
        .modal {
            max-width: 55%;

            &.simple {
                max-width: 25%;
                min-height: 350px;
            }
        }

        .bem-confirm-action-modal {
            max-width: 830px;
        }

        .bem-upload-imsi-info-modal {
            max-width: torem(500px);
        }

        .bem-roaming-policy-widths {
            max-width: 65%;
        }

        .bem-small-modal {
            max-width: torem(700px);
        }

        .bem-large-modal {
            max-width: 80%;
        }
    }

    //@include media-breakpoint-down(sm) {
    //    .modal {
    //        min-width: 500px;
    //        margin: 30px auto;
    //    }
    //}

    .assign-modal {
        .modal-section {
            width: 550px;
            padding-top: 5px;

            .top-assign-title {
                margin-bottom: 20px;
                @include responsive-font-size($font-size-lg);
            }

            [type=text] {
                width: 275px;
                background-color: $input-background-grey;
                border: 0;
            }
        }
    }

    .send-sms-ussd,
    .ping-thing,
    .ping-ip {
        width: 650px;

        section {
            max-height: 500px;
            overflow-y: auto;
            @include responsive-font-size($font-size-md);
        }

        .create {
            width: 280px;
            height: 42px;
            padding: 0;
            font-weight: 300;
            line-height: normal;
        }

        .input-field {
            margin: 0;

            input {
                width: 326px;
                margin: 0;
            }
        }

        label {
            display: block;
            margin-bottom: 14px;
        }
    }

    .info-modal {
        //max-width: 800px;

        footer {
            display: block !important;
            text-align: center;
        }
    }
}

.apply-settings {
    .bold-header {
        @include responsive-font-size($font-size-md);
        font-weight: 500;
        color: $modal-pink;
    }

    .warning-text {
        margin-top: 20px;
        font-size: 24px;
        font-weight: 300;
    }

    .checkbox {
        margin-right: 15px;
    }

    .footer {
        text-align: center;

        button {
            text-transform: none !important;
            margin-right: 15px;

            &:last-of-type {
                margin-right: 0;
            }

            &:focus {
                box-shadow: $shadow-for-focused-input;
            }
        }
    }
}

.code-wrapper {
    padding: 10px;
    margin-bottom: 10px;
    font-family: monospace;
    @include responsive-font-size($font-size-xs);
    font-weight: 400;
    line-height: 1.6;
    border: 1px solid $code-border;
    border-radius: 3px;
}

.settings-modal-item {
    margin: 10px;
    text-align: left;
}

.bem-rf-description-text {
    margin-bottom: 10px;
    @include responsive-font-size($font-size-md);
}

.bem-rf-rejection-comment {
    @include responsive-font-size($baseFontSize);
    color: $black;
    font-weight: 400;
}

.bem-qos-modal-h4 {
    margin: 0;
    @include responsive-font-size($font-size-md);
    font-weight: 400;
}

.bem-hr-divider-qos {
    margin-top: 15px;
    margin-bottom: 30px;
    border-top: 1px solid $semi-grey;
    opacity: .5;
}

.bem-warning-icon {
    margin-top: 20px;
    font-size: 100px;
}

.warning-new-text-color-red {
    color: $modal-new-warning-text-red;
}

.warning-new-warning-text-styles {
    font-size: 2.5rem;
}

.warning-new-message-text {
    font-weight: normal;
    color: $modal-new-warning-message-text-color;
    font-size: 1rem;
}

.warning-new-icon-size {
    font-size: 2.75rem;
}

#xml_msg {
    font-size: 12pt;
    font-family: Arial;
}
