.audit-logs {
    .audit-logs-filters {
        //@include display(flex);
        //@include justify-content(space-between);
        //margin: 1%;
        //input, select {
        //    width: 250px;
        //    padding: 0 20px;
        //    &.audit-logs-datetime-filter {
        //        text-align: center;
        //    }
        //}
        &.audit-logs-filters-customer-operator {
            @include justify-content(flex-start);
            &:not(:last-of-type) {
                input, select {
                    margin-right: 20px;
                }
            }
        }
    }
    .bem-table-container {
        margin-bottom: 20px;
    }
    .audit-logs-pagination-container {
        display: table;
        margin: 0 auto;
    }
    .validation {
        margin-top: 5px;
        left: 0 !important;
    }
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 0 0 20px 0;
  border-radius: 3px;
}
.pagination > li {
  display: inline;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 16px;
  line-height: 1.846;
  text-decoration: none;
  color: #2196f3;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  margin-left: -1px;
  font-weight: normal;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 3px;
  border-top-right-radius: 3px;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  z-index: 2;
  color: #0a6ebd;
  background-color: #eeeeee;
  border-color: #dddddd;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  z-index: 3;
  color: #ffffff;
  background-color: #e02e7e;
  border-color: #e02e7e;
  cursor: default;
}
.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
  color: #bbbbbb;
  background-color: #ffffff;
  border-color: #dddddd;
  cursor: not-allowed;
}
