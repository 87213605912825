.divided-rows {
    width: 100%;
    padding-top: 20px;
    border-collapse: separate;
    border-spacing: 0 5px;

    &.table-hover {
        tr:not(.skip-hover):hover {
            cursor: pointer;
            background-color: $security-background-grey;
        }
    }

    th {
        @include responsive-font-size($font-size-md);
        font-weight: normal;
        text-align: left;
    }

    tbody tr {
        background-color: $white;
        border: 1px solid $border-color;
        border-radius: 5px;
    }

    tbody td {
        position: relative;
        padding: 10px;
        @include responsive-font-size($baseFontSize);
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        &:not(.skip-divider) {
            &::after {
                display: none;
            }
        }

        &:first-of-type {
            border-left: 1px solid $border-color;
            border-radius: 5px 0 0 5px;

            &::after {
                display: none;
            }
        }

        &:last-of-type {
            text-align: right;
            border-right: 1px solid $border-color;
            border-radius: 0 5px 5px 0;

            &::after {
                display: none;
            }
        }

        &:not(.skip-divider) {
            &::after {
                position: absolute;
                top: 50%;
                right: 0;
                display: block;
                width: 1px;
                height: 31px;
                margin-top: -15.5px;
                background: $extra-semi-grey;
                content: '';
            }
        }

        .icon-drag {
            position: relative;
            top: 3px;
            font-size: 2rem;
            color: $radio-grey;
            cursor: move;
        }
    }

    .color-code-background {
        color: $white;
        background-color: $pink;

        &.on {
            background-color: $green;
        }
        &.redirect {
            background-color: $orange;
        }
    }

    .color-code-color {
        line-height: 2;
        color: $pink;

        &.on {
            color: $green;
        }
        &.redirect {
            color: $orange;
        }
    }

    .service-description {
        font-size: 16.8px;
        color: $extra-semi-grey;
    }

    .source-cell {
        width: 145px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .icon-drag {
        float: left;
        font-size: 32px;
        color: $radio-grey;
        cursor: move;
    }
}
