.login {
    @include display(flex);
    @include justify-content(center);
    @include align-items(center);
    //position: absolute;
    //top: 0;
    //left: 0;
    width: 100%;
    height: 100%;
    color: $white;
    background: url('../images/iot-bg.jpg') no-repeat center / cover;

    a {
        font-weight: bold;
        color: $white;
        text-decoration: none;
    }

    .forgot {
        position: relative;
        padding: 1.5rem 0 1rem;
        @include responsive-font-size($font-size-lg);

        .bem-question-mark-round-tooltip {
            top: 25px;
            left: 170px;
        }

        .tooltip-content {
            top: 14px;
            width: 235px;
            line-height: 21px;
        }
    }

    .create {
        padding-top: .5rem;
        font-size: 16.5px;
    }

    /*.logo img {
        width: 96px;

        &[alt=JpU] {
            width: 110px !important;
            margin-left: 30px;
        }

        // We should think of a better way to achieve this in the future
        &[alt=Kandy] {
            width: 140px !important;
        }

    }*/

    /*form {
        width: 500px;
    }*/

    /*.container {
        z-index: 1;
        padding-left: 150px;
    }*/

    /*.logo {
        margin-bottom: 2.5rem;
        margin-left: -1.5rem;
    }*/

    .form-group {
        height: 70px;
        line-height: 70px;
        margin: 0 40px;
    }

    [type=text],
    [type=password] {
        width: 100%;
        //background: red;
        background-color: transparent;
        //height: 70px;
        padding: 0 1rem;
        @include responsive-font-size($font-size-lg);
        color: $white;

        border: 0;
        border-radius: 6px;
        outline: 0;
        box-sizing: border-box;
        font-weight: 300;

        @include placeholder {
            font-size: 25px;
            font-weight: 300;
            color: $white;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 1000px white inset;
            transition: background-color 5000s ease-in-out 0s;
        }
    }

    [type=password] {
        //padding-right: 4.5rem;
    }

    .underline::after {
        position: relative;
        top: -6px;
        display: block;
        height: 1px;
        background-color: $white;
        content: '';
    }

    .input-field {
        position: relative;
    }

    //.btn {
    //    position: absolute;
    //    top: .75rem;
    //    right: -2.5rem;
    //    font-size: 2.25rem;
    //    color: $white;
    //    background: transparent;
    //
    //    &:hover {
    //        color: #cccccc;
    //    }
    //
    //    &:focus {
    //        box-shadow: none;
    //    }
    //}

    .icon-username,
    .icon-password {
        position: absolute;
        top: 1.2rem;
        left: 5px;
        font-size: 2rem;
        color: $white;
    }

    .icon-secondary-page {
        top: 12px;
        left: -42px;
    }

    .checkbox::after,
    .checkbox::before {
        color: $white;
    }

    .validation {
        position: absolute;
        bottom: -1rem;
        left: 0;
        width: 400px;
        &.validation-Username, &.validation-Password {
            display: none;
        }
    }

    .mask-over-bg {
        position: absolute;
        top: 0px;
        left: 0px;

        width: 100%;
        height: 100%;

        background-color: $black;
        opacity: 0.4;
    }

    .input-field.underline.error {
        .icon-username,
        .icon-password {
            color: $bright-pink;
        }

        &::after {
            background-color: $bright-pink;
        }
    }

    .error-message {
        position: absolute;
        bottom: -30px;
        left: 0px;

        white-space: nowrap;
        font-weight: 200;

        color: $bright-pink;
    }
}

.bem-logo-container img {
    width: 96px;

    &[alt=jpu] {
        width: 110px !important;
        margin-left: 15px;
    }

    // We should think of a better way to achieve this in the future
    &[alt*='kandy'] {
        width: 140px !important;
    }

    &[alt*='monogoto'] {
        width: 180px !important;
        filter: contrast(1.9) brightness(0.9);
        margin-left: 30px;
    }

    &[alt*='kore'] {
        width: auto !important;
        height: 44px !important;
        margin-left: 15px;
    }

    &[alt*='verint'] {
        width: auto !important;
        height: 44px !important;
        margin-left: 15px;
    }

    &[alt*='traffilog'] {
        width: 180px !important;
        margin-left: 18px;
    }

    &[alt*='aexonis'] {
        width: 130px !important;
        filter: contrast(1.9) brightness(0.9);
        margin-left: 30px;
    }

    &[alt*='coreone'] {
        width: 280px !important;
    }

    &[alt*='global1sim'] {
        width: 180px !important;
    }

}

#spUsername,
#spPassword,
#oldPassword,
#twoFactorAuth {
    &.ng-invalid {
        border: 0 !important;
    }
}

.google-btn {
    width: 100%;
    text-decoration: none;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .2);
    cursor: pointer;
    align-self: center;
    user-select: none;
    transition: all 400ms ease 0s;
    display: flex;
}
.google-btn .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    background-color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    user-select: none
}
.google-btn .google-icon-svg {
    //position: absolute;
    background-image: url('#{$images}google-icon.svg');
    background-repeat: no-repeat;
    background-size: 25px;
    margin: 8px;
    padding: 12px;
    //width: 40px;
    //height: 40px;
    user-select: none
}
.google-btn .btn-text {
    //float: right;
    margin: 11px 14px 40px 40px;
    color: #fcfcfc;
    font-size: 16px;
    letter-spacing: .2px;
    //font-family: Roboto;
    user-select: none
}
.google-btn:hover {
    box-shadow: 0 3px 8px rgba(117, 117, 117, .5);
    user-select: none
}
.google-btn:active {
    box-shadow: 0 1px 1px #757575;
    background: #3367d6;
    color: #fff;
    user-select: none
}
