@import "../helpers";

%map-settings {
    position: absolute;
    z-index: 1;
    content: '';
}

.stats {
    position: relative;
    height: #{$stat-height};
    overflow: hidden;
    background: $sidebar-text-color;

    &::before {
        @extend %map-settings;
        top: -5px;
        right: 0;
        left: 0;
        height: 5px;
        box-shadow: 0 0 152px 76px $map-shadow;
    }

    .map {
        height: 400px;

        &::before {
            @extend %map-settings;
            right: 0;
            bottom: -5px;
            left: 0;
            height: 5px;
            box-shadow: 0 0 20px 10px $map-shadow;
        }

        .location-btn {
            @include display(flex);
            @include align-items(center);
            @include justify-content(center);

            width:28px;
            height: 28px;
            margin-top: 40px;
            margin-right: 10px;

            border-radius: 3px;
            background-color: $white;
            cursor: pointer;

            i {
                @include responsive-font-size($font-size-md);
            }
        }
    }
}

.things {
    position: absolute;
    top: 0;
    z-index: 10;

    .thing {
        display: inline-block;
        min-width: 50px;
        margin: 10px 0 0 15px;
        @include responsive-font-size($font-size-xs);
        color: $white;
        text-align: center;

        &:nth-of-type(3n-1) {
            min-width: 80px;
        }

        strong {
            display: block;
            font-size: 38px;
            font-weight: 100;
        }
    }
}

.tabs {
    @include display (flex);
    //@include align-items (center);
    //@include justify-content (space-between);
    height: #{$tabs-row-height};
    padding: 0 15px;
    margin: 0;
    background: $blue;
    box-shadow: inset 0 -5px 5px $tab-shadow;
}

//@include media-breakpoint-down(lg) {
//    .tabs {
//        display: none;
//    }
//}

.tabs {
    &.tabs-vertical {
        position: absolute;
        opacity: 0.9;
        display: flex;
        height: torem(684px);
        flex-direction: column;
        flex-wrap: wrap;
        z-index: 2;
        width: torem(280px);

        .tab {
            a {
                padding: 10px 35px;
                border-radius: 10px;
                text-align: center;
            }
        }
    }

    &.tabs-vertical.ng-enter {
        transition: 0.5s linear all;
        opacity: 0;
    }

    &.tabs-vertical.ng-enter.ng-enter-active {
        opacity: 1;
    }

    &.tabs-vertical.ng-leave {
        transition: 0.5s linear all;
        opacity: 1;
    }

    &.tabs-vertical.ng-leave.ng-leave-active {
        opacity: 0;
    }
}

%tab-active {
    color: $input-color-grey;
    background: $background-grey;
    box-shadow: 0 -5px 5px $tab-shadow;
    text-decoration: none;
}

.nav-tabs {
    .nav-item {
        .nav-link {
            font-weight: 400;
            color: $input-color-grey;
            text-decoration: none;
        }
    }
}

.tab {
    position: relative;
    padding-top: 1px;
    padding-right: 2px;
    overflow: hidden;

    a {
        position: relative;
        display: block;
        padding: 10px 20px 21px;
        margin: 12px 3px 0 0 ;
        color: $white;
        text-decoration: none;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        font-weight: 400;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &:hover {
            @extend %tab-active;
        }

        &::after {
            display: block;
            height: 1px;
            margin-bottom: -1px;
            overflow: hidden;
            font-weight: bold;
            color: transparent;
            content: attr(title);
            visibility: hidden;
        }
    }

    .label {
        top: 24px;
        left: 67px;

        &.right {
            left: auto;
            right: 15px;
        }
    }

    .active {
        @extend %tab-active;
    }

    .active-with-label {
        padding-right: 45px;
    }
}

.tab.alert-tab {
    overflow: visible;
}

.tab-content {
    padding: 25px 15px;
    background: $background-grey;

    &.over-auto {
        overflow: auto;
    }
}

.block {
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 5px;
}

#map-thing-information {

    .item {
        @include responsive-font-size($baseFontSize);
        font-weight: normal;

        .name {
            display: inline-block;
            width: 70px;
            @include responsive-font-size($font-size-sm);

            font-weight: 300;
        }
    }

    .button-more {
        width: 100%;
        height: 37px;
        margin-top: 20px;
        background-color: $nav-link-color;
        border-radius: 56px;
        color: $white;
        font-weight: 500;

    }
}
