@import "../helpers";

$title-color-1: #4A4A49;
$title-color-2: #8B8A8C;
$delimiter-color: #EAEAEA;

$widget-min-height: 300px;

$dashboard-min-width: 768px;
$dashboard-max-width: 1300px;
$dashboard-breakpoints: (
        dashboard-medium: 1px,
        dashboard-large: $dashboard-max-width
);

$alert-colors: (
        'critical': #924a44,
        'error': #53a8e1,
        'info': #f4a629,
        'warning': #e91e63,
        'default': #53a8e1
);

$things-state-colors: (
        'active': #2ecc71,
        'provisioned': #e02e7e,
        'retired': #f39c12,
        'suspended': #e91e63,
        'test_active': #2ecc71,
        'default': #3498db,
        'connected': #2ecc71,
        'disconnected': #e91e63
);

$imsis-state-colors: (
        'active': #f6ed06,
        'available': #e02e7e,
        'serving': #f39c12,
        'provisioned': #f6ed06,
        'activated': #e02e7e,
        'expired': #e91e63,
        'default': #e02e7e
);

$ip-address-statuses-colors: (
        'free': #e02e7e,
        'assigned': #2c82be,
        'default': #2c82be
);

#chartjs-tooltip {
  @extend .jpu-tooltip;
  @extend .fade;
  opacity: .9;
  background-color: $tooltip-bg;
  color: #fff;
  // max-width: 200px;
  padding: .4rem;
  // text-align: center;
  border-radius: .25rem;
}

#dashboard__date-range-button {
  line-height: initial;
  text-transform: initial;
  font-size: 14px;
  font-weight: 400;
  padding-left: 14px;
  padding-right: 14px;

  span {
    font-weight: 600;
  }
}

#dashboard-section {
  padding: .5rem 0;
  max-width: $dashboard-max-width;
  min-width: $dashboard-min-width;
  margin: 0 auto;


  @include make-grid-columns($grid-columns, $grid-gutter-width, $dashboard-breakpoints);

  // initial container max-width for large screens
  //@media (min-width: 1300px) {
  //  & {
  //    max-width: 1300px;
  //  }
  //}
  // screen smaller then large should take full width
  //@media (max-width: 1300px) {
  //  & {
  //    max-width: 768px;
  //  }
  //}

  .bs-row {
    margin: 0 !important;

    .col {
      @extend .px-1;
      @extend .my-1;
      display: flex;
      flex-direction: column;

      .bs-row {
        margin: 0 !important;
        flex-grow: 1;

        .col {
          margin: 0 !important;
          //display: flex;
          //flex-direction: column;
        }

        .col:first-child {
          padding-left: 0 !important;
        }

        .col:last-child {
          padding-right: 0 !important;
        }
      }

      // Lay out inventory management widget for medium screen width
      &--inventory-management {
        @media (max-width: $dashboard-max-width) {
          order: 1;
        }
      }

      &--billing-charges {
        @media (max-width: $dashboard-max-width) {
          order: 2;
        }
      }
    }

    .card {
      background: $white;
      border: 0;
      box-shadow: $card-shadow;
      font-size: $font-size-xs;
      height: 100%;

      &--with-min-height {
        min-height: $widget-min-height;
      }

      a {
        text-decoration: none;
        color: $text-blue;
        font-weight: 400;
      }

      .card-body {
        padding: 17px 25px;
        // justify-content: space-between;
        display: flex;
        flex-direction: column;
        font-weight: normal;

        .loading-placeholder {
          display: none;
        }

        &.loading {
          .loading-placeholder {
            display: inline-block;
          }
          .loading-value {
            display: none;
          }
        }

        &.no-data {
          .totals-wrapper {
            display: none;
          }
          .delimiter {
            display: none;
          }
          .no-data-container {
            display: flex;
          }
        }

        .no-data-container {
          &.no-data {
            display: flex;
          }
          display: none;
          flex-grow: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          span {
            font-size: $font-size-xs;
            font-weight: normal;
            padding: .5rem;
          }
        }

        .title-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 1rem;

          .card-title {
            color: $title-color-2;
            margin: 0;
            font-size: $font-size-sm; //.8rem;
            font-weight: 500;
          }

          .totals-wrapper {
            .total-value {
              font-size: $font-size-md;
              font-weight: 400;
              color: $text-blue;
              margin-right: 1px;
            }

            .total-label {
              font-weight: 300;
              color: $text-grey;
            }
          }
        }

        .chart-container {
          position: relative;

          &.no-data {
            .chart {
              display: none;
            }
          }

          .chart-title {
            text-align: center;
            font-weight: 500;
            font-size: $font-size-xs;
            margin-bottom: 1rem;
            margin-top: .2rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        dashboard-table {
          flex-grow: 1;
        }

        .table-responsive {
          display: flex;
          flex-direction: column;
          &.no-data {
            height: 100%;
            /*.table-responsive-head,*/ .bs-table {
              display: none;
            }
            .no-data-container {
              display: flex;
            }
          }

          // margin-top: .75rem;
          .table-responsive-head {
            margin-bottom: .5rem;
            display: flex;
            justify-content: space-between;
            font-weight: 400;

            .title {
              font-size: .8rem;
            }

            a {
              font-size: $font-size-xs;
              padding-right: .5rem;
            }
          }

          .bs-table {
            margin: 0;

            tr {
              &:nth-of-type(odd) {
                background-color: whitesmoke;
              }

              td {
                padding: .25rem .5rem;
                border: 0;
                font-weight: 300;

                &:not(:last-child) {
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  max-width: 0;
                }

                &:last-child {
                  white-space: nowrap;
                  width: 1px;

                  &:not(:first-child) {
                    text-align: right;
                  }
                }
              }
            }
          }
        }

        .stacked-bar {
          box-shadow: $card-shadow;
          display: flex;

          .item {
            $border-radius: 2px;

            &:first-child {
              border-top-left-radius: $border-radius;
              border-bottom-left-radius: $border-radius;
            }

            &:last-child {
              border-top-right-radius: $border-radius;
              border-bottom-right-radius: $border-radius;
            }

            height: 10px;
            @each $state, $color in $things-state-colors {
              &--#{$state} {
                background-color: $color;
              }
            }
          }
        }

        .delimiter {
          padding: .9rem 0;

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 2px;
            background-color: $delimiter-color;
          }
        }

        .block-text {
          padding: 1rem;
          font-size: $font-size-xxl;
          background: #e02e7e;
          color: #fff;
          font-weight: 400;
          border-radius: .25rem;
          box-shadow: $card-shadow;

          &.darker {
            background: #e02e7e;
          }

          span {
            font-weight: 300;
            font-size: $font-size-xs;
          }
        }

        a.button-link {
          display: inline-block;
          padding: .25rem 1rem;
          // font-size: $font-size-xxl;
          font-weight: 400;
          border-radius: .25rem;
          box-shadow: $card-shadow;
        }

        .points-with-labels-row {
          display: flex;

          & > :nth-child(1) {
            flex: 1;
          }

          & > :nth-child(2) {
            width: 30%;
            // flex: 0 1;
          }

          .points-with-labels-container {
            // height: 100%;
            // width: 100px;
            // height: 100px;
            align-items: flex-start;
            display: flex;
            flex-wrap: wrap;

            .item {
              flex: 1 1 50%;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 14px;
              font-size: $font-size-xs;
              font-weight: 300;
              margin-bottom: .75rem;
              position: relative;
              text-transform: capitalize;

              &:before {
                content: '';
                display: block;
                position: absolute;
                left: 0px;
                top: 50%;
                margin-top: -4px;
                border-radius: 50%;
                width: 8px;
                height: 8px;
              }
            }

            &--imsis {
              .item {
                &:before {
                  background-color: map-get($imsis-state-colors, 'default');
                }

                @each $state, $color in $imsis-state-colors {
                  &--#{$state} {
                    &:before {
                      background-color: $color;
                    }
                  }
                }
              }
            }

            &--sims {
              .item {
                &:before {
                  background-color: map-get($things-state-colors, 'default');
                }

                @each $state, $color in $things-state-colors {
                  &--#{$state} {
                    &:before {
                      background-color: $color;
                    }
                  }
                }
              }
            }
          }
        }
      }

      &.card-count {
        .card-body {
          padding: .5rem;

          .title-wrapper {
            margin: 0;

            .card-title {
              color: $title-color-1;
              font-weight: 400;
            }
          }

          .card-text {
            text-align: center;
            font-size: $h1-font-size;
            color: $text-blue;
            font-weight: 300;
            margin-top: 0;
          }
        }
      }

      &.card-maps {
        .card-body {
          padding: 0;

          .bs-row {
            .col:nth-child(1) {
              padding: 0 !important;
              margin: 0 !important;

              .map {
                width: 100%;
                height: 100%;

                & > div {
                  border-top-left-radius: .25rem;
                  border-bottom-left-radius: .25rem;
                }
              }
            }

            .col:nth-child(2) {
              padding: 1rem !important;
              min-height: $widget-min-height;
            }
          }
        }
      }
      &.card-alerts {
        .card-body {

          .totals-wrapper {
            .total-value, .total-label {
              color: #de3351;
            }
          }

          .bs-table {
            .item {
              @each $severity, $color in $alert-colors {
                &__severity--#{$severity} {
                  color: $color;
                }
              }

              &__severity {
                width: 30%;
              }
            }
          }

          .circles-container {
            display: flex;
            align-items: flex-end;

            .items-wrapper {
              overflow-x: auto;
              flex: 0 0 80%;
              display: flex;
              justify-content: space-between;
              flex-flow: row;

              .item {
                display: flex;
                flex-flow: column;
                align-items: center;
                margin: 0 5px;
                font-size: $font-size-xxxl;

                .item-circle {
                  width: 45px;
                  height: 45px;
                  text-align: center;
                  border-radius: 50%;
                  border: 1px solid map-get($alert-colors, 'default');
                  color: map-get($alert-colors, 'default');
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-weight: 300;

                  @for $i from 2 through 10 {
                    &--chars-#{$i} {
                      font-size: 1em - $i / 10;
                    }
                  }
                }

                @each $severity, $color in $alert-colors {
                  &--#{$severity} .item-circle {
                    border-color: $color;
                    color: $color;
                  }
                }

                .item-label {
                  margin-top: 2px;
                  font-weight: 300;
                  font-size: $font-size-xs;
                }
              }
            }

            .edit-wrapper {
              flex-basis: 20%;
              text-align: right;
            }
          }
        }
      }

      &.card-inventory-management {
        .card-body {
          .bs-row {
            height: 100%;

            .col {
              justify-content: space-between;
              display: flex;
              flex-direction: column;
              // padding: 0 .5rem !important;

              .chart-container {
                height: 110px;
                &.no-data {
                  height: auto;
                  .chart-title {
                    margin-bottom: 0;
                  }
                }
              }
            }
          }
        }

        .links-container {
          margin-top: 1rem;

          a {
            margin-right: .5rem;
          }
        }

        .inventory-management-legend-container {
          display: flex;
          margin-top: .5rem;
          overflow-x: auto;
          justify-content: center;

          .item {
            display: flex;
            // width: 33%;
            flex: 0 1 0;
            font-weight: 300;
            position: relative;
            padding-left: 14px;

            &:not(:last-child) {
              margin-right: 26px;
            }

            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0px;
              top: 50%;
              margin-top: -4px;
              border-radius: 50%;
              background: map_get($ip-address-statuses-colors, 'default');
              width: 8px;
              height: 8px;
            }

            @each $status, $color in $ip-address-statuses-colors {
              &--#{$status} {
                &:before {
                  background-color: $color;
                }
              }
            }

            // padding
            .item-icon {
              font-size: 1.5rem;
              margin-right: .35rem;
              padding-top: 5px;
            }

            .item-content {
              font-weight: 500;

              .item-label {
                font-size: $font-size-xxs;
              }

              .item-value {
                font-size: $font-size-sm;
              }

            }
          }
        }
      }

      &.card-things-group {
        .things-group-container {
          display: flex;
          flex-wrap: wrap;

          .item {
            display: flex;
            width: 33%;
            // flex: 0 1 33%;
            font-weight: 300;
            margin-bottom: .5rem;
            // padding
            .item-icon {
              font-size: 1.5rem;
              margin-right: .35rem;
              padding-top: 2px;
            }

            .item-content {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .item-label {
                font-size: $font-size-xs;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .item-value {
                color: $text-gray;
                font-size: $font-size-xs;
              }
            }
          }
        }
      }

      &.card-things-summary {
        .chart-container {
          height: 24px;
          overflow: hidden;

          canvas {
            margin-top: -66px;
          }
        }

        .things-summary-legend-container {
          display: flex;
          // flex-wrap: wrap;
          margin-top: 1rem;
          // justify-content: space-between;
          // overflow-x: auto;
          .item {
            display: flex;
            // width: 33%;
            // flex: 0 1 33%;
            font-weight: 300;
            position: relative;
            padding: 0 5px 0 16px;
            margin-bottom: .5rem;
            overflow: hidden;

            &:before {
              content: '';
              display: block;
              position: absolute;
              left: 0px;
              top: 50%;
              margin-top: -4px;
              border-radius: 50%;
              background: map-get($things-state-colors, 'default');
              width: 8px;
              height: 8px;
            }

            @each $state, $color in $things-state-colors {
              &--#{$state} {
                &:before {
                  background-color: $color;
                }
              }
            }

            // padding
            .item-icon {
              font-size: 1.5rem;
              margin-right: .35rem;
              padding-top: 5px;
            }

            .item-content {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              .item-label {
                font-size: $font-size-xs;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              .item-value {
                color: $text-gray;
                font-size: $font-size-xs;
              }

            }
          }
        }
      }

      &.card-billing-charges {
        .timeline-container {
          color: $text-gray;
          margin-left: .5rem;
          margin-bottom: 2rem;
          display: flex;
          font-weight: 300;

          .item {
            &:not(:last-child) {
              margin-right: 2rem;
            }

            .item-value {
              margin-top: .2rem;
              font-size: $font-size-xl;
              font-weight: 400;
            }
          }
        }
      }
    }
  }
}

.map-info-modal {
  background: #576371 !important;
  border-radius: 8px;
  opacity: 0.8;
}

.gm-style-iw {
  border-radius: 8px;
  background-color: rgba(87, 99, 113, 0.8) !important;
  color: white;
  font-size: 0.8rem !important;
  max-height: initial !important;
  height: 160px !important;
  width: 300px !important;
  line-height: 20px !important;

  .item:not(:last-child) {
    @extend .text-truncate;
    max-width: 84%;
  }

  button {
    max-width: 60% !important;
    border-radius: 8px !important;
    background-color: white !important;
    opacity: 1 !important;
    color: #00a7ef !important;
    font-weight: 400 !important;
    font-size: 15px !important;
  }
}

.gm-style-iw-d {
  max-height: 160px !important;
  overflow: visible !important;
}

.gm-ui-hover-effect {
  margin: 11px !important;
}
