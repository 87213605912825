.things-groups-detail {
    margin: 0 auto 20px;

    .zoom-in-table {
        @include calc (max-height, '100vh - (#{$header-height} + #{$subheader-height} + #{$stat-height} + 40px)');
        min-height: 230px;
        overflow: hidden;

        .table-body {
            @include calc (max-height, '100vh - (#{$header-height} + #{$subheader-height} + #{$stat-height} + 115px)');
            min-height: 200px;
            overflow-y: auto;
        }
    }

    .things-form-table {
        @include calc (max-height, '100vh - (#{$header-height} + #{$subheader-height} + #{$stat-height} + 215px)');
        min-height: 230px;
        overflow-y: hidden;

        .table-body {
            @include calc (max-height, '100vh - (#{$header-height} + #{$subheader-height} + #{$stat-height} + 245px)');
            min-height: 200px;
            overflow-y: scroll;
        }
    }

    .empty {
        min-height: 50px;
    }

    .table {
        padding: 0;
        margin: 15px 15px 50px;
        border-top: 1px solid $border-color;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }

    thead {
        font-weight: bold;
    }

    //tr {
    //    td,
    //    th {
    //        width: 12.5%;
    //
    //        &:nth-of-type(1) {
    //            width: 45%;
    //        }
    //
    //        &:last-of-type {
    //            width: 8%;
    //        }
    //    }
    //}

    th,
    td {
        @include responsive-font-size($baseFontSize);
        text-align: left;
        border: 1px solid $border-color;
    }

    th {
        padding: 5px 15px;
        border-top: 0;

        &:first-of-type {
            border-left: 0;
        }

        &:last-of-type {
            border-right: 0;
        }
    }

    td {
        padding: 15px;
        border-bottom: 0;

        &:first-of-type {
            border-left: 0;
        }

        &:last-of-type {
            border-right: 0;
        }
    }
}

.things-group-form {

    button.billing-details {
        background-color: #fff;

        margin-right: 20px;
        .icon {
            span {
                color: $blue;
                font-size: 34px;
            }
            .icon-active  .path1:before, .icon-retire .path1:before {
                color: $blue;
            }

            .icon-suspend {
                .path1::before {
                    color: $blue;
                }
            }
        }

        &.disabled {

            span.icon-billings {
                color: $search-grey
            }

            .description {
                color: $extra-semi-grey;
            }
        }
    }
}
