.network-header {
    .radiobutton {
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-right: 1rem;
        margin-bottom: -.25em;
    }

    .checkbox {
        width: 15px;
        height: 15px;
        margin-right: 1rem;
    }

    .auto-label {
        cursor: pointer;
    }

    .margin-like-tabs {
        padding-bottom: 10px;
        margin-bottom: -5px;
    }
}

.network-row {
    margin-top: 5px;

    p {
        width: 220px;
    }

    .row {
        @include clearfix;
    }

    .title {
        width: 150px;
    }

    .data {
        @include calc (width, '100% - 150px');
        line-height: 46px;
    }

    .lease-data {
        padding-bottom: 15px;
        line-height: 46px;

        [type=text] {
            width: 70px;
            margin-right: -5px;
            text-align: right;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        select {
            padding: 0 46px 0 23px;
            line-height: 45px;
            border-bottom-left-radius: 0;
            border-top-left-radius: 0;
        }
    }

    .profile-data {
        select {
            width: 275px;
            padding: 0 46px 0 23px;
        }
    }

    .select-for-ip-profile {
        width: 275px;
        padding: 0 20px;
    }

}
