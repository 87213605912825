@import "helpers";

label {
    &.disabled {
        color: $input-disabled !important;
    }
}

%search-icon {
    position: absolute;
    top: 20px;
    right: 14px;
    width: 16px;
    height: 16px;
    background-image: url('#{$images}search.png');
    background-repeat: no-repeat;
}

.form-helper {
    display: none;
    margin-bottom: 1.5rem;

    &.visible {
        display: block;
    }
}

.input-field {
    position: relative;
    margin-bottom: 15px;
}

.input-field,
.item {
    [type=text]:focus:not(#spUsername, #twoFactorAuth) {
        font-weight: bold;
        color: $black;
    }
}

// [type=text],
// [type=password],
// .file,
// .styled-select {
//     height: 46px;
//     padding: 0 46px;
//     line-height: 46px;
//     color: $input-color-grey;
//     border: 0;
//     border-radius: 46px;
//     outline: 0;
//     @include responsive-font-size($font-size-md);
//     font-weight: normal;

//     &.grey {
//         color: $input-color-grey;
//         background-color: $input-background-grey;
//     }
// }

.file {
    cursor: pointer;

    + [type=file] {
        display: none;
    }
}

.text-button-combined {
    position: relative;

    .inside-text-field {
        position: relative;
        left: -2.5rem;
        background: $white;
        border: 0;
        outline: 0;
    }
}

[type=checkbox] {
    // display: none;
    opacity: 0;
    width: 1px;
    height: 1px;

    &:focus + label:before {
        // outline: 1px solid #66afe9;
        outline: auto;
        outline-color: -webkit-focus-ring-color;
    }
}

.tumbler-container {
    [type=checkbox] {
        display: none;
    }
}

.tumbler {
    @include transition (all .3s $ease-out-quart);
    position: relative;
    display: inline-block;
    width: 50px !important;
    height: 22px;
    cursor: pointer;
    background-color: $toggle-pink;
    border-radius: 20px;

    &::before {
        @include transition (all .3s $ease-out-quart);
        position: absolute;
        top: 2px;
        left: 2px;
        width: 18px;
        height: 18px;
        background-color: $white;
        border-radius: 15px;
        content: '';
    }
}

[type=checkbox]:checked + .tumbler {
    @include transition (all .3s $ease-out-quart);
    background-color: $toggle-green;

    &::before {
        @include transition (all .3s $ease-out-quart);
        left: 30px;
    }
}

[type=checkbox]:disabled + .tumbler {
    @include transition (all .3s $ease-out-quart);
    background-color: $radio-grey;
    cursor: not-allowed;
}

.checkbox {
    position: relative;
    top: 3px;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    cursor: pointer;

    &::before {
        position: absolute;
        font-family: 'Aqua-Icons';
        @include responsive-font-size($font-size-lg);
        line-height: 1;
        color: $extra-semi-grey;
        content: '\e986';
    }

    &::after {
        position: absolute;
        font-family: 'Aqua-Icons';
        font-size: 21px;
        line-height: 1;
        color: $black;
        content: '';
    }
}

[type=checkbox]:checked + .checkbox,
[type=checkbox]:checked + .new-checkbox {
    &::after {
        content: '\e985';
    }
}

.new-checkbox {
    @extend .checkbox;
    top: 0;
    width: 16px;
    height: 16px;

    &::before {
        display: block;
        position: absolute;
        width: 13px;
        height: 13px;
        border: 2px solid $extra-semi-grey;
        border-radius: 6px;
        line-height: 1;
        font-family: unset;
        //color: $extra-semi-grey;
        content: '';
        background-color: $white;
    }

    &::after {
        position: absolute;
        display: block;
        width: 13px;
        height: 13px;
        font-family: 'Aqua-Icons';
        font-size: 21px;
        line-height: 1;
        border-radius: 6px;
        color: $white;
        content: '';
    }
}

[type=checkbox]:checked + .new-checkbox {
    &::after {
        background-color: $deep-blue;
        border: 2px solid transparent;
        text-indent: -3.5px;
        line-height: 0.8;
    }
}

[type=checkbox]:disabled + .new-checkbox {
    cursor: default;
    &::after {
        background-color: $text-gray;
        opacity: 0.4;
        width: 17px;
        height: 17px;
    }
}

[type=checkbox]:checked:disabled + .new-checkbox {
    cursor: default;
    &::after {
        background-color: $text-gray;
        opacity: 1;
        width: 13px;
        height: 13px;
    }
}

.checkbox-eye {
    position: relative;

    display: inline-block;
    width: 20px;
    height: 20px;
    cursor: pointer;

    &::before {
        position: absolute;
        font-family: 'Aqua-Icons';
        @include responsive-font-size($font-size-lg);
        color: $btn-green-color-2;
        content: '\e9e6';
    }
}

[type=checkbox]:checked + .checkbox-eye {
    &::before {
        content: '\e9cb';
        color: $thing-form-pink;
    }
}

[type=checkbox]:checked + .d-flex .checkbox-eye {
    &::before {
        content: '\e9cb';
        color: $thing-form-pink;
    }
}

[type=checkbox]:checked + .d-flex .auto-label {
    color: $thing-form-pink !important;
}

.auto-label {
    cursor: pointer;
}

.styled-select {
    @include appearance(none);
    position: relative;
    color: $black;
    background: $input-background-grey url('../images/carret.png') no-repeat;
    background-position: right 20px center;
    border: 0;
    outline: 0;

    &:focus {
        box-shadow: $shadow-for-focused-input;
    }
}

.bem-styled-select_new-roaming-policy {
    padding: 0;
    background-color: $white;
}

.bem-styled-select_new-roaming-policy-byterate {
    width: 100px;
    height: 45px;
    padding: 0 15px;
    margin: 0;
    margin-right: 8px;
    line-height: initial;
    color: $black;
    background-color: $input-background-dark-grey;
    border: 0;
}

.bem-styled-select_new-roaming-policy-byterate_disabled {
    color: $search-grey;
    background-color: $background-grey-modal;
}

[type=radio] {
    display: none;
}

.radiobutton {
    position: relative;
    width: 22px;
    height: 22px;
    cursor: pointer;
    border: 1px solid $radio-border-grey;
    border-radius: 22px;
}

.served-by-label {
    vertical-align: 10px;
}

[type=radio]:checked + .radiobutton {

    &::before {
        position: absolute;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        background-color: $deep-blue;
        border-radius: 10px;
        content: '';
    }
}

.btn-icon {
    padding: 7px 10px;
    background: $white;
    border-radius: 3px;
    // box-shadow: 0 2px 4px transparentize($black, .7);
}

select {
    cursor: pointer;
}

.input-has-error {
    position: relative;

    // &::before {
    //     position: absolute;
    //     left: -25px;
    //     font-family: 'Aqua-Icons';
    //     font-size: 24px;
    //     color: $bright-pink;
    //     content: '\e906';
    // }

    select.ng-invalid.ng-touched,
    [type=password]:not(.ng-valid),
    [type=text]:not(.ng-valid) {
        border: 1px solid $bright-pink !important;

        @include placeholder {
            color: $bright-pink !important;
        }

        &.bem-form-input_right-border-transparent {
            border-right: transparent !important;
        }

        &.bem-form-input_left-border-transparent {
            border-left: transparent !important;
        }
    }

    select:not(.ng-valid) {
        border: 1px solid $bright-pink !important;
    }

    .dropdown-menu {
        top: 38px;
    }
}

.validation {
    position: relative;
    top: 0;
    //left: 220px;
    left: torem(10px);
    //width: 260px;
    width: auto;
    // padding-bottom: 10px;
    @include responsive-font-size($baseFontSize);
    font-weight: 300;
    line-height: 1.5;
    color: $bright-pink;
}

select.ng-invalid-validation.ng-touched,
[type=password].ng-invalid-validation.ng-touched,
[type=text].ng-invalid-validation.ng-touched {
    border: 1px solid $bright-pink !important;

    @include placeholder {
        color: $bright-pink !important;
    }

    &.bem-form-input_right-border-transparent {
        border-right: transparent !important;
    }

    &.bem-form-input_left-border-transparent {
        border-left: transparent !important;
    }
}

.standart-form {
    padding: 1%;

    .row {
        @include display(flex);
        @include flex-flow(row wrap);
        @include align-items(center);
        margin-bottom: 15px;

            .item {
                &:first-child {
                    margin-right: 3%;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
    }

    .input-field {
        .lbl {
            display: inline-block;
            width: 150px;
            font-weight: 400;
            color: $security-grey;
            @include responsive-font-size($font-size-md);
        }

        .field {
            width: 292.3px;
            background-color: $input-background-grey;
        }
    }
}

.file.upload-imsi-label {
    margin-left: 0;
}

.bem-form-container {
    margin: torem(22px) 0;
}

.bem-form {
    padding: torem(25px) torem(30px);
    font-size: torem(18px);
}

.bem-form__login-form {
    max-width: 500px;
}

.bem-form-input {
    @extend .form-control;
    color: $input-color-grey;
    // background-color: $input-background-grey;
    // padding: 0 torem(20px);
    font-weight: 400;
    // width: 100%;
    // height: torem(46px);
    //line-height: torem(46px);
    //border-radius: torem(46px);
    //outline: 0;
    //font-size: torem(18px);
}

[readonly].bem-form-input {
    background-color: lighten($input-background-grey, 4%);
}

.bem-form-input_thing-form-editable-field-padding {
    padding-right: 34px;
}

.input-carret {
    position: absolute;
    top: 17px;
    right: 32px;
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    pointer-events: none;
}

.input-carret_custom-position {
    right: 20px;
}

.bem-form__login-input-container {
    &::after {
        position: relative;
        //top: -6px;
        width: 100%;
        display: block;
        height: 1px;
        background-color: $white;
        content: '';
    }

    &.error {
        .icon-username,
        .icon-password {
            color: $bright-pink;
        }

        &::after {
            background-color: $bright-pink;
        }
    }
}

.bem-form-input__file {
    display: none;
}

.bem-form-input__color-schemes-input {
    width: torem(200px);
}

.bem-form-input__auth-code {
    max-width: torem(280px);
}

.bem-form-input_roaming-partner-sm-input {
    max-width: 85px;
}

.bem-form-input-absolute-helper {
    position: absolute;
    top: 14px;
    right: 60px;
    color: $radio-grey;
}

.bem-input-height {
    height: torem(38px);
}

.bem-minimum-input-height {
    min-height: torem(46px);
}

.bem-form-input_login-input {
    width: 100%;
    //height: torem(70px);
    //padding: 0 2rem 0 3rem;
    font-size: torem(20px);
    //line-height: torem(70px);
    color: $white;
    background-color: transparent;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    font-weight: 300;
    //border-radius: 6px;

    @include placeholder {
        font-size: 25px;
        font-weight: 300;
        color: $white;
    }
}

.bem-form-input_no-left-bradius {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.bem-form-input_no-right-bradius {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}

.bem-form-input_right-border-transparent {
    border-right: transparent;
}

.bem-form-input_left-border-transparent {
    border-left: transparent;
}

.bem-form-label {
    color: $text-label-dark-grey;
    display: inline-block;
    width: auto;
    //margin: torem(10px);
    font-size: 14px;
    font-weight: 400;
    // line-height: 1;
}

.bem-form-label__black-text {
    color: $black;
}

.bem-form-label__bold {
    font-weight: 600;
}

.bem-form-label__textarea-label {
    vertical-align: top;
}

.bem-form-label__fs18 {
    font-size: torem(18px);
}

.bem-form-label_text-no-select {
    @include prefixer(user-select, none, webkit moz o ms spec)
}

.bem-form-label__text-dark-grey {
    color: $text-label-dark-grey;
}

.bem-form-label_tag-lable-styles {
    vertical-align: top;
    margin-top: 20px;
}

.bem-form-label__alert-tags {
    margin-top: 15px;
}

.bem-form-block-title {
    margin-bottom: torem(35px);
}

.bem-form-block-title_name {
    font-size: torem(20px);
    font-weight: 400;
    color: $security-grey;
}

.bem-form-block-title_caption {
    font-size: torem(16px);
    font-weight: 500;
    margin-top: 0;
    color: $security-grey;
}

.bem-fieldset-block-title {
    font-size: torem(20px);
    font-weight: 400;
    color: $blue;
    text-transform: uppercase;
}

.bem-blocking-container {
    margin: 0 torem(15px) torem(15px) 0;
}

.bem-form-label_eye-label {
    position: relative;

    display: inline-block;
    width: torem(27px);
    height: torem(20px);
    cursor: pointer;
    font-size: torem(27px);

    &::before {
        position: absolute;
        font-family: 'Aqua-Icons';
        //font-size: torem(20px);
        color: $btn-green-color-2;
        content: '\e9e6';
        top: -4px;
    }
}

.bem-form-label_eye-label_opposite {
    position: relative;

    display: inline-block;
    width: torem(27px);
    height: torem(20px);
    cursor: pointer;
    font-size: torem(27px);
    &::before {
        position: absolute;
        font-family: 'Aqua-Icons';
        //font-size: torem(20px);
        color: $thing-form-pink;
        content: '\e9cb';
        top: -4px;
    }
}

.bem-form-label_eye-label-neighbor {
    color: $btn-green-color-2;
    //min-width: torem(250px);
    font-size: torem(19px);
    font-weight: 300;
    cursor: pointer;
}

.bem-form-label_eye-label-neighbor_opposite {
    color: $thing-form-pink;
    //min-width: torem(250px);
    font-size: torem(19px);
    font-weight: 300;
    cursor: pointer;
}

[type=checkbox]:checked + .d-flex .bem-form-label_eye-label {
    &::before {
        content: '\e9cb';
        color: $thing-form-pink;
    }
}

[type=checkbox]:checked + .d-flex .bem-form-label_eye-label_opposite {
    &::before {
        content: '\e9e6';
        color: $btn-green-color-2;
    }
}

[type=checkbox]:checked + .d-flex .bem-form-label_eye-label-neighbor {
    color: $thing-form-pink;
}

[type=checkbox]:checked + .d-flex .bem-form-label_eye-label-neighbor_opposite {
    color: $btn-green-color-2;
}

[type=checkbox]:disabled + .d-flex .bem-form-label_eye-label {
    cursor: not-allowed;

    &:focus {
        outline: none;
    }

    &::before {
        color: $input-disabled;
    }
}

[type=checkbox]:disabled + .d-flex .bem-form-label_eye-label_opposite {
    cursor: not-allowed;

    &:focus {
        outline: none;
    }

    &::before {
        color: $input-disabled;
    }
}

[type=checkbox]:disabled + .d-flex .bem-form-label_eye-label-neighbor {
    cursor: not-allowed;
    color: $input-disabled;
}

[type=checkbox]:disabled + .d-flex .bem-form-label_eye-label-neighbor_opposite {
    cursor: not-allowed;
    color: $input-disabled;
}

.bem-form-checkbox-form-element {
    margin-top: 1rem;
}

.bem-form-checkbox {
    position: relative;
    top: torem(-2px);
    display: inline-block;
    width: torem(15px);
    height: torem(15px);
    line-height: torem(20px);
    cursor: pointer;
    margin-right: torem(16px);

    &::before {
        position: absolute;
        font-family: 'Aqua-Icons';
        font-size: torem(20px);
        line-height: 1;
        color: $extra-semi-grey;
        content: '\e986';
    }

    &::after {
        position: absolute;
        font-family: 'Aqua-Icons';
        font-size: torem(21px);
        line-height: 1;
        color: $black;
        content: '';
    }

    &:focus {
        outline: 0;
    }
}

.bem-form-checkbox__before-after-fix {
    &::before,
    &::after {
        top: 0;
        left: 0;
    }
}

.bem-form-label_radio {
    display: inline-block;
    position: relative;
    min-width: 18px;
    height: 18px;
    cursor: pointer;
    border: 1px solid $radio-border-grey;
    border-radius: 12px;

    &.disabled {
        border-color: $input-disabled;
    }
}

[type=radio]:checked + .bem-form-label_radio {

    &::before {
        position: absolute;
        display: block;
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        background-color: $deep-blue;
        border-radius: 10px;
        content: '';
    }
}

.bem-form-textarea {
    @extend .form-control;
    color: $input-color-grey;
    // background-color: $input-background-grey;
    // height: 100px;
    // resize: none;
    // padding: 0 torem(20px);
    font-weight: 400;
    // width: 100%;
    // line-height: torem(46px);
    // border-radius: torem(20px);
    outline: 0;
    font-size: torem(18px);
    resize: none;
}

.bem-form-textarea:disabled {
    background-color: lighten($input-background-grey, 4%);
    cursor: not-allowed;
}

[type=checkbox]:checked + .bem-form-checkbox {
    &::after {
        content: '\e985';
    }
}

.bem-form-select {
    // @include appearance(none);
    @extend .form-control;
    @include appearance(none);
    // position: relative;
    // width: 100%;
    color: $input-color-grey;
    // background: $input-background-grey url(../images/carret.png) right 20px center no-repeat;
    //background-position: right 20px center;
    // outline: 0;
    // height: torem(46px);
    // padding: 0 2rem 0 1.2rem;
    // line-height: torem(46px);
    // border-radius: torem(46px);
    // font: inherit;
    // font-size: torem( 18px);
    font-weight: 400;
    cursor: pointer;
    // margin: 0;
    // text-transform: none;
    // border: 0;
    padding-right: 2rem;
}

[readonly].bem-form-select {
    background-color: lighten($input-background-grey, 4%);
}

.bem-form-select__tabs-select {
    position: relative;
    display: block;
    background-color: $blue;
    background-image: none;
    color: $white;
    border-radius: 0;

    option {
        background-color: $input-background-grey;
        color: $black;
    }
}

.tabs-select-wrapper::after {
    content: "";
    position: absolute;
    display: block;
    border: 3px solid;
    border-top: 0;
    border-right: 0;
    border-color: $white;
    top: 15px;
    right: 23px;
    width: 10px;
    height: 10px;
    transform: rotate(-45deg);
    pointer-events: none;
}

// .bem-form-select__new-roaming-policy-styled {
//     padding: 0;
//     background-color: $white;
// }

.bem-select-container {
    position: absolute;
    top: 110%;
    //left: 202px;
    right: torem(90px);
    z-index: 10;
    width: 285px;
    max-height: 450px;
    padding: 5px;
    overflow-y: auto;
    @include responsive-font-size($baseFontSize);
    background-color: $white;
    border-radius: 10px;
    box-shadow: 0 0 12px 0 $billing-tooltip-shadow-color;
}

.bem-select-items {
    // padding: 0;
    // margin: 0;
    // list-style: none;
    overflow-x: auto;
    max-height: 500px;
    max-width: 300px;
}

.bem-select-item {
    padding: 8px 5px;
    margin: 0 5px;
    border-bottom: 1px solid $select-item-border-color;

    &:hover {
        cursor: pointer;
        background-color: $select-item-hover-color;
    }

    &:last-child {
        border-bottom: 0;
    }
}

.bem-select-item__search-item {
    padding: 2px 0 5px;

    &:hover {
        cursor: auto;
        background-color: $white;
    }
}

.bem-currency-search-container {
    margin: 0;
}

.bem-currency-search-input {
    @extend .bem-form-input;
    width: 255px;
    margin: 0;
    background-color: $search-background;
}

.bem-currency-search-icon {
    @extend %search-icon;
}

fieldset:disabled {
    .bem-form-input {
        background-color: lighten($input-background-grey, 4%);
        cursor: not-allowed;
    }
}

.bem-form-input:disabled {
    background-color: lighten($input-background-grey, 4%);
    cursor: not-allowed;
}

.bem-currency-search-icon__thing-search-positioning {
    top: 15px;
    right: 30px;
}

.bem-form-config {
    color: #333333!important;
    font-weight: 400!important;
}

.bem-tumbler {
    @include transition (all .3s $ease-out-quart);
    position: relative;
    display: inline-block;
    width: 60px !important;
    height: 30px;
    cursor: pointer;
    background-color: $input-background-grey;
    border-radius: 20px;
    border: 1px solid $radio-deep-grey;

    &::before {
        @include transition (all .3s $ease-out-quart);
        position: absolute;
        top: 3px;
        left: 2px;
        width: 21px;
        height: 21px;
        background-color: $cancel-grey-text;
        border-radius: 15px;
        content: '';
    }

    &::after {
        @include transition (all .3s $ease-out-quart);
        content: 'OFF';
        color: $cancel-grey-text;
        font-size: 15px;
        font-weight: 300;
        position: absolute;
        //z-index: 2;
        right: 6px;
        top: 6px;
    }
}

[type=checkbox]:checked + .bem-tumbler {
    @include transition (all .3s $ease-out-quart);
    background-color: $white;
    border: 1px solid $blue;
    border-radius: 20px;
    z-index: 1;

    &::before {
        @include transition (all .3s $ease-out-quart);
        position: absolute;
        top: 3px;
        left: 35px;
        width: 21px;
        height: 21px;
        background-color: $blue;
        border-radius: 15px;
        content: '';
    }

    &::after {
        @include transition (all .3s $ease-out-quart);
        content: 'ON';
        color: $blue;
        font-size: 16px;
        font-weight: 300;
        position: absolute;
        z-index: 2;
        left: 6px;
        top: 5px;
    }
}

.bem-new-tumbler {
    @extend .bem-tumbler;
    width: 26px!important;
    height: 15px;
    background-color: $new-tumbler-bg-color;
    border-radius: 3px;
    border: 1px solid $new-tumbler-border-color;

    &::before {
        top: 0px;
        left: 0px;
        width: 13px;
        height: 13px;
        background-color: $new-tumbler-runner-color;
        border-radius: 3px;
        content: '';
        outline: 0;
    }

    &::after {
        content: '';
        color: $new-tumbler-disabled-color;
        /* font-size: 15px; */
        position: absolute;
        right: 4px;
        top: 6px;
    }
}

[type=checkbox]:checked + .bem-new-tumbler {
    @include transition (all .3s $ease-out-quart);
    background-color: $blue;
    border: 1px solid $new-tumbler-border-color;
    border-radius: 3px;

    &::before {
        @include transition (all .3s $ease-out-quart);
        position: absolute;
        top: 0px;
        left: 11px;
        height: 13px;
        width: 13px;
        border-radius: 3px;
        content: '';
        background-color: $new-tumbler-runner-color;
        outline: 0;
    }

    &::after {
        @include transition (all .3s $ease-out-quart);
        position: absolute;
        z-index: 2;
        border-radius: 3px;
        content: '';
    }
}

.bem-tumbler-like-button__unchecked {
    @include transition (all .3s $ease-out-quart);
    position: relative;
    display: inline-block;
    width: 60px !important;
    height: 30px;
    cursor: pointer;
    background-color: $input-background-grey;
    border-radius: 20px;
    border: 1px solid $radio-deep-grey;

    &::before {
        @include transition (all .3s $ease-out-quart);
        position: absolute;
        top: 3px;
        left: 2px;
        width: 21px;
        height: 21px;
        background-color: $cancel-grey-text;
        border-radius: 15px;
        content: '';
    }

    &::after {
        @include transition (all .3s $ease-out-quart);
        content: 'OFF';
        color: $cancel-grey-text;
        font-size: 15px;
        font-weight: 300;
        position: absolute;
        z-index: 2;
        right: 6px;
        top: 6px;
    }
}

.bem-tumbler-like-button__checked {
    @include transition (all .3s $ease-out-quart);
    position: relative;
    display: inline-block;
    width: 60px !important;
    height: 30px;
    cursor: pointer;
    background-color: $white;
    border: 1px solid $blue;
    border-radius: 20px;
    z-index: 1;

    &::before {
        @include transition (all .3s $ease-out-quart);
        position: absolute;
        top: 3px;
        left: 35px;
        width: 21px;
        height: 21px;
        background-color: $blue;
        border-radius: 15px;
        content: '';
    }

    &::after {
        @include transition (all .3s $ease-out-quart);
        content: 'ON';
        color: $blue;
        font-size: 16px;
        font-weight: 300;
        position: absolute;
        z-index: 2;
        left: 6px;
        top: 5px;
    }
}

.currency-link-item {
    vertical-align: top;

    &:focus {
        box-shadow: 0 0 2px 1px $text-focus-border-color;
    }

    &:visited {
        color: $black;
    }
}
