.provisioning {

    label {
        line-height: inherit;
    }

    .content {
        margin-bottom: 15px;
    }

    .prov-form {
        padding: 1%;
        margin: 1%;

        .input-field {
            display: inline-block;
        }

        form {
            padding: 0;
        }
    }

    .btn {
        &.csv-info-icon {
            display: inline-block;
            height: 28px;
            padding: 1px 10px;
            margin-left: 10px;
            @include responsive-font-size($font-size-md);
            line-height: 26px;
            color: $white;
            background: $blue;
            border: 0;
            border-radius: 56px;
            outline: 0;
            vertical-align: bottom;
        }
    }

    .file {
        font-weight: 500;
        color: $white;
        text-align: center;
        background-color: $alert-green;

        &:focus {
            box-shadow: $shadow-for-focused-input;
        }
    }

    .csv-upload-button {
        margin-left: 0;
    }

    .file-name {
        display: inline-block;
        font-weight: normal;
        color: $text-label-dark-grey;
        font-size: 0.8rem;
        margin-top: 4px;
    }

    .file-info {
        max-width: 100px;
    }

    .progress {
        .progress-bar {
            position: relative;
            top: 4px;
            display: inline-block;

            .bar-1 {
                width: 180px;
                height: 20px;
                padding: 1px;
                border: 1px solid $security-border-grey;
                border-radius: 12px;
            }

            .bar-2 {
                width: 0;
                height: 100%;
                background-color: $progress-bar;
                border-radius: 12px;
            }
        }

        .close {
            position: relative;
            top: 1px;

            display: inline-block;
            line-height: normal;

            cursor: pointer;

            i {
                color: $security-border-grey;

                &:first-child {
                    @include responsive-font-size($font-size-md)
                }

                &:last-child {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    font-size: 9px;
                }
            }
        }
    }

    .footer-buttons {
        margin: 0;
    }

    .switch-table {
        height: 50px;

        .radio-field {
            @include display(flex);
            @include align-items(center);

            position: relative;
            top: 10px;

            margin-right: 10px;

            input:checked + .radio {
                border-color: $sidebar-nav-border;

                &::before {
                    display: block;
                }
            }

            input:checked ~ .auto-label {
                color: $sidebar-nav-border;
            }

            .radio {
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
                margin-right: 5px;
                border: 2px solid $black;
                border-radius: 50%;

                &::before {
                    position: absolute;
                    top: 3px;
                    left: 3px;

                    display: none;

                    width: 10px;
                    height: 10px;

                    background-color: $sidebar-nav-border;
                    border-radius: 50%;
                    content: '';
                }
            }

            .auto-label {
                @include responsive-font-size($font-size-xl);
                font-weight: 100;
                cursor: pointer;
            }
        }
    }

    .table-bordered {
        // th,
        // td {
        //     &:nth-last-child(-n+2) {
        //         border-right: 1px solid $border-color;
        //     }
        // }

        td:last-of-type {
            padding-left: 0;
        }
    }

    [name='prov.form'] {
        [type='file'] {
            display: none;
        }
    }

    [name='prov.simForm'] {
        [type='file'] {
            display: none;
        }
    }
}

.provisioning-modal {
    min-width: 570px;

    .success {
        color: $alert-green;
    }

    .error {
        color: $modal-pink;
    }

    .entries {
        width: 1200px;
        height: 450px;
        padding: 20px;
        overflow: auto;

        .table-row {
            .td:last-child {
                @include justify-content(flex-start);
            }
        }

        .sort-head {
            .th {
                position: relative;
                cursor: pointer;

                .sort-icon {
                    position: absolute;
                    top: 2px;
                    right: 10px;
                }
            }
        }

        .filt {
            width: 100%;
            height: 30px;
            padding: 0 20px;
            margin: 0;
            line-height: initial;
        }

        .success {
            font-family: monospace;
            @include responsive-font-size($font-size-xs);
            color: $alert-green;
        }

        .error {
            font-family: monospace;
            @include responsive-font-size($font-size-xs);
            color: $modal-pink;
        }

        .info {
            cursor: pointer;
        }
    }

    .btn {
        &.create {
            height: 40px;
            line-height: initial;
            background-color: $tag-button;
        }
    }
}

.provisioning-modal-message {
    .msg {
        //max-width: 450px;
        padding: 10px;
    }
}

.csv-about-text {
    margin-top: 40px;
    margin-bottom: 50px;
    @include responsive-font-size($baseFontSize);
}

.csv-example-title {
    @include responsive-font-size($font-size-lg);
    color: $blue;
}

.csv-file-example {
    background-color: $activity-header;
    border-radius: 5px;
    font-size: 10px;
    margin-top: 33px;
    padding: 14px;
    text-align: initial;
}

.bem-provisioning-radio-modifier {
    top: 50px;
    z-index: 3;
}
