.activity-toggle {
    @include transition (all .3s $ease-out-quart);
    @include calc (top, '50vh - 30px');
    position: fixed;
    right: 0;
    z-index: 12;
    // temporar
    display: none;
    width: 35px;
    height: 125px;
    color: $white;
    cursor: pointer;
    background-color: $activity-blue;
    border-radius: 5px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: -10px 0 10px 0 $top-shadow;

    &.activity-toggled {
        @include transition (all .3s $ease-out-quart);
        right: 340px;
    }

    // temporar fix for the icon
    i {
        @include transform (rotate(270deg));
        display: block;
        @include responsive-font-size($font-size-xl);
        line-height: 100px;
    }
}

%triangles {
    @include transition (all .3s $ease-out-quart);
    position: fixed;
    right: 0;
    z-index: 11;
    // temporar
    display: none;
    width: 30px;
    height: 40px;
    border-radius: 50%;

    &.activity-toggled {
        @include transition (all .3s $ease-out-quart);
        right: 340px;
    }
}

.top-triangle {
    @extend %triangles;
    @include calc (top, '50vh - 70px');
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
    box-shadow: 2px 10px 0 0 $activity-blue;
}

.bottom-triangle {
    @extend %triangles;
    @include calc (top, '50vh + 95px');
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 2px -10px 0 0 $activity-blue;
}

.activity {
    @include transition (all .3s $ease-out-quart);
    @include calc (top, '50vh - 400px');
    position: fixed;
    right: -340px;
    z-index: 10;
    width: 340px;
    height: 800px;
    background-color: $white;
    border: 2px solid $activity-blue;
    border-right: 0;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    &.activity-toggled {
        @include transition (all .3s $ease-out-quart);
        right: 0;
        box-shadow: -10px 0 10px 0 $top-shadow;
    }

    header {
        height: 70px;
        padding: 0 15px;
        @include responsive-font-size($font-size-xl);
        line-height: 70px;
        text-transform: uppercase;
        background-color: $activity-header;
        border-top-left-radius: 10px;
    }

    .period {
        padding: 10px;
        margin: 10px 5px 0 10px;
        @include responsive-font-size($font-size-lg);
    }

    .actions {
        padding: 10px;
        margin: 0 5px 10px 10px;
        @include responsive-font-size($font-size-lg);
        background-color: $background-grey-modal;

        a {
            font-weight: 400;
            color: $black;
            text-decoration: none;
        }
    }

    .avatar {
        display: inline-block;
        float: left;
        width: 50px;
        height: 50px;
        margin-right: 10px;
        background: url('#{$images}avatar.png') no-repeat center center;
        background-size: cover;
        border: 1px solid $tag-border;
        border-radius: 50%;
    }

    .name {
        display: inline-block;
    }

    .time {
        float: right;
        @include responsive-font-size($baseFontSize);
        font-weight: 300;
        color: $radio-grey;
    }

    .description {
        @include calc (width, '100% - 60px');
        display: inline-block;
        @include responsive-font-size($baseFontSize);
        font-weight: 300;
        color: $radio-grey;
    }

    .author-label {
        margin: 0 0 25px;
    }

    .action {
        @include clearfix;
        height: 30px;
        margin: 15px 0 0;
        @include responsive-font-size($font-size-md);
        font-weight: 300;
        line-height: 30px;
        color: $radio-grey;

        .icon {
            float: left;
            width: 50px;
            font-size: 24px;
            text-align: center;
        }

        .default {
            color: $black;
        }

        .alert {
            color: $red;
        }

        .type {
            display: inline-block;
        }

        .manage {
            float: right;
            @include responsive-font-size($font-size-lg);
            color: $security-modal-semi-grey;
        }
    }
}
