@import "helpers";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
//@import "../../node_modules/@fortawesome/fontawesome-pro/scss/light";

$actionCellWidth: 80px;
$colMinWidth: 160px;
$headerHeight: 50px;
$overlayHeight: 65px;

.ui-grid {
  &.no-data {
    .ui-grid-canvas {
      height: 1px !important;
    }
  }
  &:not(.no-data) {
    .ui-grid-canvas {
      height: auto !important;
    }
  }
}
.ui-grid, .ui-grid-viewport, .ui-grid-contents-wrapper {
  height: auto !important;
}

.ui-grid-header-canvas {
  height: $headerHeight !important;
}

.customer-link_like {
  color: $blue;
  cursor: pointer;

  &:hover {
    text-decoration: underline;

    svg path {
      stroke-width: 1px;
      stroke: #686768;
    }
  }
}

.ui-grid {
  .ui-grid-header {
    //padding-right: 17px;

    .ui-grid-header-viewport {
      width: 100%;

      .ui-grid-header-canvas {
        width: 100%;

        .ui-grid-header-cell-wrapper {
          display: block;
          width: 100%;

          .ui-grid-header-cell-row {
            display: flex;
            min-width: 0;
            height: 100%;

            .ui-grid-header-cell {
              flex: 1 1 0;
              //padding-bottom: 6px;
              border-color: $border-color;
              //max-width: $colMaxWidth !important;

              &:last-child {
                .ui-grid-column-resizer {
                  &.right {
                    border: 0;
                    //border-color: $border-color;
                  }
                }
              }

              .ui-grid-cell-contents {
                font-size: 0.875rem;
                font-weight: 400;
                color: $black;
                line-height: 18px;
                height: 20px;
              }

              .ui-grid-filter-dropdown {
                .multiselect-parent {
                  .btn.dropdown-toggle {
                    @extend .bem-btn;
                    @extend .bem-btn-filters;
                    @extend .bem-btn-icon;
                  }

                  .dropdown-header {
                    input.searchField {
                      @extend .form-control;
                      //@extend .bem-btn;
                      //@extend .bem-btn-filters;
                      //@extend .bem-btn-icon;
                    }
                  }

                  .dropdown-menu {
                    position: fixed;
                    left: auto;
                    top: auto;
                    height: auto !important;
                    max-height: 314px;
                    max-width: 350px;
                    li {
                      a.option {
                        position: relative;
                        padding-left: 25px;
                        span {
                          &:nth-child(1) {
                            position: absolute;
                            left: 0;

                            &:before {
                              content: '\e985';
                              opacity: 1;
                              font-family: 'Aqua-Icons';
                              font-size: 20px;
                              color: $gray-300;
                            }
                            &.glyphicon-ok {
                              &:before {
                                color: #000;
                              }
                            }
                          }
                        }
                      }
                    }
                    & > li:not(:nth-child(4)):not(:nth-child(3)):not(:nth-child(5)) {
                      @extend .dropdown-item;
                    }
                    .divider {
                      @extend .dropdown-divider;
                    }
                  }
                }
              }


              .ui-grid-filter-dropdown.select-single {
                .multiselect-parent {
                  .btn.dropdown-toggle {
                    @extend .bem-btn;
                    @extend .bem-btn-icon;
                    @extend .bem-btn-filters;
                  }

                  .dropdown-header {
                    input.searchField {
                      @extend .bem-btn;
                      @extend .bem-btn-icon;
                      @extend .bem-btn-filters;
                    }
                  }

                  .dropdown-menu {
                    position: fixed;
                    left: auto;
                    top: auto;
                    li {
                      a.option {
                        position: relative;
                        padding-left: 25px;
                        span {
                          &:nth-child(1) {
                            position: absolute;
                            left: 0;

                            &:before {
                              content: '\e985';
                              opacity: 1;
                              font-family: 'Aqua-Icons';
                              font-size: 20px;
                              color: $gray-300;
                            }
                            &.glyphicon-ok {
                              &:before {
                                color: #000;
                              }
                            }
                          }
                        }
                      }
                    }
                    & > li:not(:nth-child(3)):not(:nth-child(2)):not(:nth-child(4)) {
                      @extend .dropdown-item;
                    }
                    .divider {
                      @extend .dropdown-divider;
                    }
                  }
                }
              }

              .jpu-tags {
                auto-complete {
                  position: fixed;
                  width: 280px;
                  z-index: 2;
                }
              }
            }
          }
        }
      }
    }
  }

  .ui-grid-viewport {
    //overflow: visible !important;
    //display: flex;
    min-height: 42px;
    overflow-x: auto !important;

    &[role="rowgroup"] {
      //overflow: hidden !important;
    }

    .ui-grid-canvas {
      //flex: auto;
      min-width: 0;

      .ui-grid-row {

        &:nth-child(odd) {
          //background-color: $white;
          .ui-grid-cell {
            background-color: $white;
          }
        }

        &:nth-child(even) {
          //background-color: $background-grey-modal;
          .ui-grid-cell {
            background-color: $ui-grid-table-even-row-bg-color;
          }
        }

        [role="row"] {
          //display: flex;
          min-width: 0;

          .ui-grid-cell {
            //flex: 1 1 0;
            //max-width: $colMaxWidth !important;

            .ui-grid-cell-contents {
              &.cell-type-id {
                span {
                  &:before {
                    content: 'ID';
                    @extend .badge;
                    background: $dark-grey;
                    color: $white;
                    margin-right: .5rem;
                    vertical-align: text-bottom;
                  }
                  font-size: inherit !important;
                  max-width: 100%;
                }
              }
              &.cell-type-checkbox {
                height: auto;
                [type=checkbox] {
                  margin: 0;
                  opacity: 0;
                  width: 1px;
                  height: 1px;
                }
              }
              &.cell-type-select {
                height: auto;
                width: 100%;
              }
            }

            button.action-button {
                padding: 0 20px;
            }
          }
        }
      }
    }
  }
  .ui-grid-cell {
    border-color: $border-color;
  }

  .alerts-container {
    display: inline-block;
    min-width: 110px;

    .alerts {
      //padding: 20px;
      //border: none;
      //background: none;
      //box-shadow: none;

      .alert-item {
        width: 75px;
        padding: 5px;
        border: none;

        color: #fff;
        border-radius: 4px;
        text-align: center;

        &.critical {
          background-color: $alert-pink;
        }

        &.error {
          background-color: $alert-orange;
        }

        &.warning {
          background-color: $alert-purple;
        }

        &.info {
          background-color: $alert-blue;
        }


      }
    }
  }

  // Hide borders shadow when no data present
  &.data {
    .ui-grid-render-container-left, .ui-grid-render-container-right {
      &:before, &:after {
        display: block;
      }
    }
  }

  @mixin borderShadow {
    content: '';
    width: 10px;
    display: none;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 0;
  }
  .ui-grid-render-container-left {
    .ui-grid-viewport {
      overflow: hidden !important;
    }

    &:after {
      @include borderShadow;
      right: -10px;
      // box-shadow: inset 8px 0px 10px -10px rgba(68, 68, 68, 1);
    }
  }
  .ui-grid-render-container-right {
    &:before {
      @include borderShadow;
      left: -10px;
      // box-shadow: inset -8px 0px 10px -10px rgba(68, 68, 68, 1);
    }
  }
}

.ui-grid-column-menu {
  position: absolute;
  top: 4px !important;
  //right: 203px !important;

  .ui-grid-menu-inner {
    overflow: visible !important;
    border-radius: 4px !important;
    //max-height: 600px !important;
    //height: auto;
    //font-weight: 100;
    width: 220px !important;
  }

  .ui-grid-menu-item {
    font-weight: 400;
    font-size: 15px;
  }

  .ui-grid-menu-mid {
    /* overflow: scroll; */
    //border: 1px solid #d4d4d4;
  }
}

.ui-grid-menu {
  position: absolute;
  top: 35px;
  //right: 20px;

  .ui-grid-menu-item {
    font-weight: 400;
    font-size: 15px;
  }
}

//.ui-grid-contents-wrapper {
//    overflow: hidden;
//}

.ui-grid-menu-button .ui-grid-menu .ui-grid-menu-mid {
  /* overflow: scroll; */
  border: 1px solid #d4d4d4;
}

.no-animate {
  -webkit-transition: none !important;
  transition: none !important;
}

.ui-grid-menu-mid {
  @extend .no-animate; // the .ng-animate class is as defined above
}

.ui-grid-menu-button {
  height: 21px;
  top: 13px;
  right: 9px;
  background-color: transparent;
  border: 0;

  .ui-grid-icon-container {
    margin: 0;
    margin-top: 2px;
  }

  .ui-grid-icon-menu {
    &:before {
      @extend %fa-icon;
      @extend .fal;
      content: fa-content($fa-var-columns);
    }
  }
}

.ui-grid-menu-mid {
  overflow: visible !important;
  border-radius: 4px !important;
  //max-height: 600px !important;
  //height: auto;
  width: 220px !important;
}

.ui-grid-menu .ui-grid-menu-inner ul li button {
  padding: 10px;
}

.ui-grid-toggle-button {
  padding: 20px;
  font-weight: normal;
  border-bottom: 0;
  text-align: center;
}

.ui-grid-scrollbar-placeholder {
  display: none; // todo: check this
}

.ui-grid-render-container-left {
  //border-bottom: 1px solid $border-color;
}

.ui-grid-render-container-body {
  //border-left: 1px solid $border-color;
  //border-bottom: 1px solid $border-color;
}

.bem-table-container {
  background-color: $white;
  border: 1px solid $border-color;
  border-radius: 2px;
  margin-top: 0;
  //min-height: 125px;
}

.bem-table-bordered {
  position: relative;
  width: 100%;
  height: 100%;
}

.bem-table-container_no-borders {
  border: 0;
}

.bem-billing-entries-table-container {
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

.bem-things-table-container {
  height: 745px;
}

.bem-things-table-container__edit-thing {
  height: 685px;
}

.bem-table-bordered-form {
  &[name="ppform.tableForm"] {
    padding: 0 10px;
  }
}

.bulk-actions {
  display: flex;
  margin-top: 1rem;
  //text-align: right;
}

.vpn-bulk-actions {
  display: flex;
}

.selected-counter {
  padding: 10px 35px 0 0;
}

.bulk-actions__provisioning {
  height: torem(39px);
  padding: 0;
  position: absolute;
  z-index: 4;
  right: 15px;
  bottom: -3px;
}

.ui-grid-wrapper {
  &#table-voice {
    grid > div > div > div {
      z-index: 3;
    };
  }
  &#table-smsmo {
    grid > div > div > div {
      z-index: 10;
    };
  }
  .ui-grid-top-panel, .ui-grid-header-viewport {
    //overflow: visible;
  }
  .ui-grid-contents-wrapper {
    //overflow-x: hidden !important;
  }

  input[type="text"].ui-grid-filter-input {
    @extend .form-control;
    //@extend .bem-btn;
    //@extend .bem-btn-filters;
  }

  input[type="text"].ui-grid-filter-input::placeholder {
    font-size: 14px;
  }
  .ui-grid-header-canvas {
    //min-height: 112px !important;
    //height: auto !important;
  }
  .ui-grid-filter-container {
    padding: 0 10px !important;

    &.date-picker {
      padding: 0 20px 0 10px!important;
    }
  }
  .bem-table-bordered .ui-grid-header {
    //border-bottom: none !important;
  }
  .ui-grid-header-cell:first-of-type {
    input[type="text"].ui-grid-filter-input {
      // margin-left: -40px;
    }
  }
}

.daterangepicker.dropdown-menu {
  .calendar {
    .daterangepicker_input {
      input {
        border-radius: 20px;
        height: 33px;
        line-height: 50px;
        background: #edf1f2;
        border: none;
        padding: 0 10px 0 30px;
        font-size: 14px;
        margin: 0;
        width: auto;
      }
    }
  }

  .ranges li {
    font-size: 15px;
    font-weight: 400;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
    border-radius: 18px;
    color: #212529;
    padding: 5px 15px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .ranges li:hover {
    background-color: #e02e7e;
    color: #fff;
  }
}

.popover-body-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .items-list {
    display: inline-block;
    margin: 0 10px 0 10px;
    padding: 0;
    list-style: none;
  }
}

.ui-grid-header-canvas {
  .tags-container tags-input {
    border-radius: 20px;
    height: 33px;
    line-height: 50px;
    background: #edf1f2;
    border: none;
    padding: 0 10px;
    font-size: 14px;
    margin: 0;
  }

  .tags-container {
    margin-top: 0 !important;
    //width: 100% !important;
  }

}

.apply-datepicker-button {
  display: inline-block;
  height: 31px;
  padding: 0px 14px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 32px;
  color: #fff;
  cursor: pointer;
  background: #e02e7e;
  border: 0;
  border-radius: 14px;
  outline: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  margin: 3px;
}

.apply-datepicker-button:hover {
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.4);
}

.gridFirstColumnStyle {
  margin-left: -15px;
}

.gridFirstHeaderColumnStyle {
  left: -5px;
}

.ui-grid {
  position: relative;
  //background-color: $gray-400;
  border: 1px solid $border-color;
  border-radius: 2px;
  margin-top: 0;
  z-index: 50;

  @keyframes fadeIn {
    from {
      visibility: hidden;
    }
    to {
      visibility: visible;
    }
  }

  .grid-msg-overlay {
    position: absolute;
    top: $headerHeight + 1px; // 1px border
    bottom: 0;
    width: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.4);
    //z-index: 9;
    //height: $overlayHeight;
    height: calc(100% - #{$headerHeight + 1px}); // 1px border
    visibility: hidden;
    opacity: 0.95;

    &.show {
      animation: fadeIn;
      animation-delay: 0s;
      animation-duration: 0s;
      animation-fill-mode: forwards;
      &.with-delay {
        animation-duration: 1s;
        animation-delay: 1s;
      }
    }

    .msg {
      opacity: 1;
      position: absolute;
      /* top: 20%; */
      width: 100%;
      height: 100%;
      line-height: 42px;
      font-weight: 200;
      /* left: 20%; */
      /* width: 60%; */
      /* height: 50%; */
      background-color: #eee;
      /* border-radius: 4px; */
      /* border: 1px solid #555; */
      text-align: center;
      font-size: 20px;
      display: table;

      span {
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  .default-icon-container {
    //text-align: left;
    margin: 14px;
  }

}

.ui-grid, .ui-grid-viewport {
  //height: auto !important;
  //overflow-y: hidden;
  //overflow: visible !important;
}

.ui-grid-header {
  border-bottom: 1px solid $border-color;
}

.grid-tooltip {
  overflow: visible;
  z-index: 9999999;
  float: left;
}

.ui-grid-filter-input {
  border: 1px solid #d4d4d4;
  height: 30px;
  /* border-radius: 6px; */
}

.ui-grid-header-cell {
  background: $white;
  //height: 57px;
  font-size: 22px;
  font-weight: 300;
  //color: $blue;
  //padding-bottom: 1rem;
  text-align: center;
  border-top: 0;
  height: $headerHeight;
  //border-top-left-radius: 5px;
  //border-right: 1px solid $border-color;

  .movingColumn {
    z-index: 9;
    .jpu-tags {
      z-index: 9;
    }
  }

  //left: -10px;

  &:first-child div {
    //margin-left: 8px;
  }
}

.ui-grid-canvas {
  position: relative;
  padding-top: 0;
}

//.ui-grid-header-canvas {
//    height: 56px;
//}

.ui-grid-cell {
  //border-right: 1px solid $border-color;
  //border-bottom: 1px solid $border-color;
  border-bottom: 0 !important;
  overflow: visible;
  //display: table-cell;
  //height: auto !important;
  vertical-align: middle;
  //float: none;
  //@include responsive-font-size($font-size-xl);

  .popover {
    top: -25px !important;
    left: 0 !important;
  }

  .popover-content {
    position: fixed;
    padding: 6px;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
  }

  &.actions {
    flex: 0 0 auto;
    text-align: center;
    .ui-grid-cell-contents {
      padding-left: 0;
      padding-right: 0;
      height: 100%;
      .bem-btn_access {
        margin-left: 0;
      }
    }
  }
}

.ui-grid-pinned-container.ui-grid-pinned-container-left {
  //border-right: none;
  //left: -10px;
  .ui-grid-header-cell {
    border-right: 1px solid $border-color;
  }
}

.ui-grid-pinned-container.ui-grid-pinned-container-right {
  .ui-grid-header-cell {
    &:last-child {
      border-right: 0;
    }
  }
}

.ui-grid-pinned-container-right .ui-grid-render-container-right .ui-grid-viewport {
  overflow: hidden !important;
  .ui-grid-cell {
    &:last-child {
      border-right: 0;
    }
  }
}

.ui-grid-header-cell-last-col {
  .ui-grid-column-menu-button {
    margin-right: 50px;
  }
  .ui-grid-cell-contents {
    margin-right: 50px;
  }
  .adjust-filter-icon-position {
    margin-right: 30px;
  }
}

.ui-grid-cell-contents {
  @extend .align-items-center;
  @extend .h-100;
  @extend .d-flex;
  &.overflow {
    overflow: visible;
  }
  &.custom-line-height{
    line-height: 20px;
  }
  //position: relative;
  //vertical-align: middle;
  //height: auto !important;
  //overflow: visible;
  //transform: translate(1%, 7%);
  padding: 0 10px;
  //padding: 6px;
  outline: none;
  text-overflow: ellipsis;
  height: 16px;
  color: $ui-grid-text-color;

  .ui-grid-icon-ok:before {
    content: '\e985';
    opacity: 1;
    font-family: 'Aqua-Icons';
    font-size: 0;
  }

  .ui-grid-icon-ok.ui-grid-row-selected:before,
  .ui-grid-icon-ok.ui-grid-all-selected:before {
    margin: 0;
    position: relative;
  }

  .ui-grid-icon-ok.ui-grid-all,
  .ui-grid-icon-ok.ui-grid-all-selected {
    //margin: 11px 0 0 0;
  }

  .ui-grid-icon-ok.ui-grid-all-selected:before {
    font-size: 21px;
  }

  .ui-grid-icon-ok.ui-grid-row-selected:before {
    font-size: 21px;
  }

  .ui-grid-selection-row-header-buttons {
    //margin: 22px 0 0 3px;
    border: 2px solid $search-grey;
    border-radius: 2px;
    width: 19px;
    height: 19px;
    opacity: 0.8;
  }

  .ui-grid-selection-row-header-buttons.ui-grid-row-selected {
    border-color: $black;
  }
}

.ui-grid-pinned-container.ui-grid-pinned-container-left [role='rowgroup'] {

  .ui-grid-header-viewport {
    .ui-grid-header-canvas {
      .ui-grid-header-cell-wrapper {
        .ui-grid-header-cell-row:first-child {
          .ui-grid-header-cell:first-child {
            .ui-grid-cell-contents {
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 100%;
              [role='checkbox button'] {
                width: 19px;
                height: 19px;
                //margin-top: 65%;
                &::before {
                  top: 0;
                  left: 0;
                  margin-right: 0;
                }
              }
            }
          }
          .ui-grid-header-cell > div {
            height: 100%;
          }
        }

        .ui-grid-selection-row-header-buttons {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0;
          padding: 0;
          &::before {
            top: 0;
            left: 0;
            margin-right: -5px;
          }
        }
      }
    }
  }

  .ui-grid-cell-contents {
    .ui-grid-selection-row-header-buttons  {
      &::before {
        top: 0;
        left: -3px;
        margin-right: -5px;
      }
    }
   }
   .ui-grid-disable-selection {
     height: 100%;
     display: flex;
     padding: 0;
     justify-content: center;
     align-items: center;
   }
}

.ui-grid-cell:last-child,
.ui-grid-header-cell:last-child
{
  //border-right: 1px solid $border-color;
  border-left: 0;
  border-top: 0;
}

.ui-grid-cell:last-child {
  border-bottom: 1px solid $border-color;
}

.ui-grid-row {
  font-size: 0.875rem;;
  font-weight: normal;
  //@include responsive-font-size($baseFontSize);
  //display: table-row;
  //height: auto !important;

  .ui-grid-cell.ui-grid-row-header-cell {
    //border-right: none;
    border-bottom: 1px solid $border-color;
    //background: inherit !important;
  }
}

//.ui-grid-render-container-left {
//  .ui-grid-row {
//    &:last-child {
//      .ui-grid-cell {
//        &.ui-grid-row-header-cell {
//          border-bottom: 1px solid $border-color;
//        }
//      }
//    }
//    .ui-grid-cell {
//      &.ui-grid-row-header-cell {
//        border-bottom: 0;
//      }
//    }
//  }
//}

.ui-grid-row.ui-grid-row-selected {
  &:nth-child(odd) .ui-grid-cell,
  &:nth-child(even) .ui-grid-cell {
    background-color: $security-background-grey;
  }
}

.tags-popover-trigger {
  cursor: help;
}

.tags-popover {
  margin-top: -10px;
  .popover-body {
    padding-bottom: 0;
    .tags-table-container {
      .tags-list {
        margin: 0;
        li {
          margin: 0 .5rem .5rem 0;
        }
      }
    }
  }
}

.tags-table-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;

  .tags-list {
    display: inline-block;
    margin: 0 10px 0 10px;
    padding: 0;
    list-style: none;

    .operator-tag {
      background-color: $blue;
    }

    .customer-tag {
      background-color: #f4a629;
    }
  }

  .tags-list li {
    float: left;
    padding: 5px;
    width: auto;
    border: none;
    font-weight: 400;
    border-radius: 4px;
    text-align: center;
    color: $white;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .three-dots {
    position: absolute;
    right: 10px;
    top: 25px;
    color: $search-grey;
    font-size: 20px;
  }
}

.show-tags-btn button {
  margin: 23px 0 0 0;
  background-color: transparent;
  color: dimgrey;
}

.ui-grid-pager-row-count-picker select, .ui-grid-pager-control input {
  color: black;
  font-weight: 300;
}

.ui-grid-pager-panel {
  z-index: -1; // todo: fix other way
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: -45px;

  //&:before {
  //  content: '';
  //  flex: 0 1 20%;
  //}

  //font-weight: 400;
  //height: 45px;
  //position: absolute;
  //left: 35%;
  //bottom: -50px;
  //width: 60%;
  //padding-top: 3px;
  //padding-bottom: 3px;
  //line-height: 36px;

  .ui-grid-pager-container {
    display: flex;
    align-items: center;
    margin: auto;
  }

  .ui-grid-pager-count-container {
    //flex: 0 1 20%;

    .tags-list li {
      float: left;
      padding: 5px;
      margin: 18px 0 10px 10px;
      width: 85px;
      border: none;
      font-weight: 400;
      border-radius: 4px;
      text-align: center;
      color: $white;
      //@include responsive-font-size($font-size-sm);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .three-dots {
      position: absolute;
      right: 10px;
      top: 25px;
      color: $search-grey;
      //@include responsive-font-size($font-size-lg);
    }
  }

  .show-tags-btn button {
    margin: 15px 15px 0 0;
    float: right;
  }

  .ui-grid-pager-control {
    display: flex;
    align-items: center;

        button {
            transition: all 0.3s ease 0s;
        }

        button:hover {
            background-color: $white;
        }

        .first-bar {
            border-left: 2px solid $white;
        }
        .first-triangle {
            border-color: transparent $white transparent transparent;
            margin-left: 2px;
        }

        .ui-grid-pager-first:hover {
            .first-bar {
                border-left: 2px solid $sidebar-nav-border;
            }

            .first-triangle {
                border-color: transparent $sidebar-nav-border transparent transparent;
            }
        }

        .ui-grid-pager-previous:hover {
            .first-triangle {
                border-color: transparent $sidebar-nav-border transparent transparent;
            }
        }

        .last-triangle {
            border-color: transparent transparent transparent $white;
        }

        .last-bar {
            background-color: $blue;
            width: 2px;
            border-color: transparent transparent transparent $white;
        }

        .ui-grid-pager-last:hover {
            .last-bar {
                border-right: 2px solid $sidebar-nav-border;
                width: 2px;
            }
            .last-triangle {
                border-color: transparent transparent transparent $sidebar-nav-border;
            }

        }
        .ui-grid-pager-next:hover {
            .last-triangle {
                border-color: transparent transparent transparent $sidebar-nav-border;
            }
        }
    }

  .ui-grid-pager-control button {
    color: $white;
    padding: 8px 25px;
    background-color: $blue;
    width: 60px;
    height: 30px;
  }

  .ui-grid-pager-control button:hover {
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.4);
  }

  .ui-grid-pager-control input {
    padding: 5px;
    background-color: $white;
    margin: 7px;
    width: 55px;
    height: 25px;
  }

  .ui-grid-pager-max-pages-number {
    margin-right: 5px;
  }

  abbr {
    text-decoration: none;
  }

  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
  }

  abbr:hover {
    cursor: default;
  }

  .ui-grid-pager-row-count-picker {
    //display: none;
  }
}

.network-update-status {
    margin: 12px;
}

.provision-action-buttons {
  margin-right: 27px;
  margin-top: 12px;

  .btn-icon {
    margin-right: 3px;
  }
}

.border-right-none {
  border-right-width: 0;
}

.border-left-none {
  border-left-width: 0;
}

.border-top-none {
  border-top-width: 0;
}

.bem-table-bordered_border-modifier {
  th:first-of-type,
  td:first-of-type {
    border-left: 0;
  }

  th:last-of-type,
  td:last-of-type {
    border-right: 0;
    border-left: 0;
  }

  th:nth-last-child(-n+2),
  td:nth-last-child(-n+2) {
    border-right: 0;
  }
}

.bem-table-bordered_no-th-td-borders {
  th:first-of-type,
  td:first-of-type {
    border-left: 0;
  }

  th:last-of-type,
  td:last-of-type {
    border-right: 0;
    border-left: 0;
  }
}

.bem-table-modifier_no-first-tr-top-border {
  tr:first-of-type {
    border-top: 0;
  }
}

.bem-table-modifier_no-last-tr-bottom-border {
  tr:last-of-type {
    border-bottom: 0;
  }
}

.bem-table-bordered_no-first-tr-top-borders {
  tr:first-of-type td {
    border-top: 0;
  }
}

.bem-table-bordered_no-last-tr-bottom-borders {
  tr:last-of-type td {
    border-bottom: 0;
  }
}

// .bem-table-bordered_no-first-tr-th-top-borders {
//     tr:first-of-type th {
//         border-top: 0;
//     }
// }

.bem-table-bordered_border-radius-modifier {
  tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 5px;
  }

  tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 5px;
  }
}

.bem-sortable-table-header {
  cursor: pointer;
  user-select: none;
}

.bem-table-bordered_tbody-striped {
  tr:nth-of-type(2n) {
    background-color: $table-striped-row;

    &.tr-checked {
      background-color: $security-background-grey;
    }
  }
}

// .bem-table-bordered_tbody-striped_input-background-modifier {
//   tr:nth-of-type(2n) {
//     [type="text"] {
//       background-color: $input-background-dark-grey;
//     }
//   }
// }

.bem-table-bordered_tbody_input-full-width {
  td {
    [type="text"] {
      @extend .bem-form-input;
      width: 100%;
    }
  }
}

.bem-table-bordered_tbody_select-style-change {
  td {
    .styled-select {
      width: 100%;
      padding: 0;
      background-color: transparent;
      border: 0;
      border-radius: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }
}

.bem-table-bordered-th {
  padding: 15px;
  @include responsive-font-size($font-size-xl);
  font-weight: 300;
  color: $blue;
  text-align: left;
  border: 1px solid $border-color;
  border-top: 0;
  border-top-left-radius: 5px;
}

.bem-table-bordered-th_new {
  @include responsive-font-size($font-size-sm);
  font-weight: 500;
  color: initial;
}

.bem-table-bordered-th__min-width-130 {
  min-width: 130px;
}

.bem-table-bordered-th_text-right {
  text-align: right;
}

.bem-table-bordered-td {
  padding: 15px;
  //@include responsive-font-size($baseFontSize);
  font-weight: normal;
  border: 1px solid $border-color;
  border-bottom: 0;
}

.bem-table-bordered-td_custom-padding {
  padding: 5px 10px;
}

.bem-table-bordered-th_padding-7 {
  padding: 7px;
}

.bem-table-bordered_thing-td {
  padding: 0;
}

.bem-table-bordered_thing-td span {
  margin-left: 15px;
}

.bem-table-bordered-td_padding-7 {
  padding: 7px;
}

.bem-table-bordered-td_border-bottom-visible {
  border-bottom: 1px solid $border-color;
}

.bem-table-modifier_manage-td {
  min-width: 98px;
}

.bem-table-checkebox {
  margin-right: 5px;
}

.bem-lifecycle-table-tooltip-container {
  display: flex;
  justify-content: center;
}

.bem-lifecycle-table-price-modified-name {
  //display: block;
}

.bem-lifecycle-table-price-modified-date {
  //@include responsive-font-size($font-size-sm);
  color: $security-grey;
}

.bem-lifecycle-table-price-feeback-td {
  position: relative;
  width: 320px;
  padding: 10px 12px;
}

.bem-lifecycle-table-price-feeback-container {
  position: relative;
  height: 100%;
  padding-left: 20px;
}

.bem-lifecycle-table-price-feeback__icon {
  position: absolute;
  top: 0;
  left: 0;
  color: $approve-button-purple;
}

.bem-lifecycle-table-price-feeback__header {
  margin-top: 2px;
  margin-left: 10px;
  line-height: 0;
  font-size: 16px;
  @include responsive-font-size($baseFontSize);
  font-weight: 300;
  color: $approve-button-purple;
}

.bem-lifecycle-table-price-feeback__body {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  @include responsive-font-size($font-size-sm);
}

.bem-lifecycle-table-price-feeback__body-text {
  display: inline-block;
  width: 174px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}

.bem-lifecycle-table-price-feeback__body-full-feedback {
  position: absolute;
  top: 59px;
  left: -1px;
  z-index: 2;
  width: calc(100% + 2px);
  min-height: 120px;
  padding: 15px;
  background-color: $white;
  border: 1px solid $border-color;
  overflow: auto;
}

.bem-network-providers-table-containers {
  @include calc(width, '100% - 210px');
  line-height: 1;
}

.bem-imsi-ranges-table__range {
  margin-right: 25px;
}

.bem-network-providers-price-plan-table__price-name {
  @include responsive-font-size($baseFontSize);
}

.bem-network-providers-price-plan-table__price-currency {
  @include responsive-font-size($font-size-sm);
  font-weight: bold;
}

.bem-network-providers-price-plan-table__price-status-approved {
  color: $select-status-approved;
}

.bem-network-providers-price-plan-table__price-status-draft {
  color: $select-status-draft;
}

.bem-network-providers-price-plan-table__price-status-retired {
  color: $select-status-retired;
}

.bem-network-providers-price-plan-table__price-status-pending {
  color: $select-status-pending;
}

.bem-network-providers-price-plan-table__price-about {
  margin-top: 5px;
  @include responsive-font-size($font-size-sm);
  color: $security-grey
}

.bem-table-bordered__billing-table-th {
  @include responsive-font-size($font-size-md);
  text-align: center;
}

.bem-billing-entry-table__padding-changer {
  padding-right: 20px;
  padding-left: 10px;
}

.bem-modifier_billing-entry-table-padding-0 {
  padding: 0;
}

.bem-modifier_billing-entry-table-no-padding {
  padding: 0;
}

.bem-modifier_th-equal-size {
  th {
    max-width: 0;
  }
}

.bem-modifier_td-equal-size {
  td {
    max-width: 0;
  }
}

.bem-profiles-suplimentary-services-table__custom-label {
  position: relative;
  top: -10px;
  margin: 0;
  @include responsive-font-size($font-size-md);
  cursor: pointer;
  user-select: none;
}

.bem-customer-accessibe-price-plans-table__custom-width-th,
.bem-organization-accessible-price-plans-table__custom-width-th
{
  width: 10%;
}

.bem-table-container__color-schemes-pisitioning {
  left: 36%;
}

.bem-table-separate-container {
  padding: 0;
  margin: 0;
  background-color: inherit;
}

.bem-scrolable-table-dashboard__thead {
  th {

    &:first-of-type {
      width: 8px;
    }

    &:nth-of-type(2) {
      @include calc (width, '50% - (8px + 213px + 135px)');
    }

    &:nth-of-type(3) {
      width: 10%;
    }

    &:nth-of-type(4) {
      width: 15%;
    }

    &:nth-of-type(5) {
      width: 25%;
    }

    &:nth-of-type(6) {
      width: 135px;
    }

    &:last-of-type {
      width: 213px;
    }
  }
}

.bem-scrolable-table-things-messages__thead {
  th {

    &:first-of-type {
      width: 8px;
    }

    &:nth-of-type(2) {
      @include calc (width, '50% - (8px + 213px + 135px + 135px)');
    }

    &:nth-of-type(3) {
      width: 6%;
    }

    &:nth-of-type(4) {
      width: 22%;
    }

    &:nth-of-type(5) {
      width: 8%;
    }

    &:last-of-type {
      width: 213px;
    }
  }
}

.bem-scrolable-table-th,
.bem-separate-table-th {
  @include responsive-font-size($font-size-md);
  font-weight: 400;
}

.bem-table-separate-td {
  position: relative;
  padding: 10px;
  @include responsive-font-size($baseFontSize);
  // background-color: $white;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  font-weight: normal;

  &:not(.bem-skip-divider) {
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 1px;
      height: 31px;
      margin-top: -15.5px;
      background: $extra-semi-grey;
      content: '';
    }
  }
}

.bem-separate-table-add-first-last-td-borders {
  td:first-of-type {
    border-left: 1px solid $border-color;
    border-radius: 5px 0 0 5px;
  }

  td:last-of-type {
    border-right: 1px solid $border-color;
    border-radius: 0 5px 5px 0;
  }
}

.bem-separate-table-tr-bg-white {
  tr {
    background-color: $white;
  }
}

.bem-table-hover-rows {
  tr:not(.bem-table-hover-rows__skip-hover):hover {
    cursor: pointer;
    background-color: $security-background-grey;
  }
}

.bem-scrolable-table-th__edit-thing {
  padding: 10px 15px;
  border: 1px solid $border-color;
}

.bem-bem-scrolable-table-body-container {
  //@include calc (max-height, '100vh - (#{$header-height} + #{$subheader-height} + #{$stat-height} + 160px)');
  //min-height: 250px;
  overflow-y: auto;

  &.empty {
    min-height: 50px;
  }
}

.bem-no-tbody-scroll {
  overflow-y: visible;
}

.bem-table-separate-rows {
  width: 100%;
  // padding-top: 20px;
  border-collapse: separate;
  border-spacing: 0 5px;
}

.bem-separate-table-tr {
  tr {
    background-color: $white;
    border: 1px solid $security-background-grey;
    border-radius: 5px;
  }
}

.bem-table-separate-rows__dashboard-widths {
  tbody td {
    &:first-of-type {
      padding: 0;
      width: 8px;
    }

    &:nth-of-type(2) {
      @include calc (width, '50% - (8px + 213px + 135px)');
    }

    &:nth-of-type(3) {
      width: 10%;
    }

    &:nth-of-type(4) {
      width: 15%;
    }

    &:nth-of-type(5) {
      width: 25%;
    }

    &:nth-of-type(6) {
      width: 135px;
    }

    &:last-of-type {
      width: 213px;
    }
  }
}

.bem-table-separate__td-dashboard-alerts {
  position: relative;
  padding: 10px;
  @include responsive-font-size($baseFontSize);
  background-color: $white;
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  font-weight: normal;

  &:not(.bem-skip-divider) {
    &::after {
      display: none;
    }
  }

  &:first-of-type {
    width: 8px;
    padding: 0;
    border-left: 1px solid $border-color;
    border-radius: 5px 0 0 5px;

    &::after {
      display: none;
    }
  }

  &:last-of-type {
    text-align: right;
    border-right: 1px solid $border-color;
    border-radius: 0 5px 5px 0;

    &::after {
      display: none;
    }
  }

  &:not(.bem-skip-divider) {
    &::after {
      position: absolute;
      top: 50%;
      right: 0;
      display: block;
      width: 1px;
      height: 31px;
      margin-top: -15.5px;
      background: $extra-semi-grey;
      content: '';
    }
  }
}

.bem-table-separate__td-thing-messages-widths {
  tbody td {

    &:first-of-type {
      width: 8px;
    }

    &:nth-of-type(2) {
      @include calc (width, '50% - (8px + 213px + 135px + 135px)');
    }

    &:nth-of-type(3) {
      width: 6%;
    }

    &:nth-of-type(4) {
      width: 22%;
    }

    &:nth-of-type(5) {
      width: 8%;
    }

    &:last-of-type {
      width: 213px;
    }
  }
}

.bem-table-separate__td-severity {
  min-width: 100px;
}

.bem-table-modifier_color-blue {
  color: $blue;
}

.bem-things-form-container {
  border: 0;
  height: 100%;
}

.bem-scrolable-table__edit-thing-thead {
  height: auto;
  border-top: 0;
  border-bottom: 0;
  margin-right: 4px;
  // margin-bottom: 7px;
  background-color: #fff;
  border: 1px solid #bbb;
  border-right: 0;
  border-left: 0;
  border-radius: 5px;

  th {
    width: 10%;

    &:first-of-type {
      width: 48%;
    }

    &:last-of-type {
      width: 7%;
      border-right: 0;
    }
  }
}

.bem-scrolable-table__edit-thing-tbody {
  td {
    width: 10%;

    &:first-of-type {
      width: 48%;
    }

    &:last-of-type {
      width: 7%;
    }
  }
}

.bem-modifier_th-no-left-border {
  border-left: 0;
}

.bem-modifier_th-no-right-border {
  border-right: 0;
}

.bem-table-modifier-bg-color-inherit {
  background-color: inherit;
}

.bem-table-bordered__checkbox-alignment {
  &::before {
    left: 0;
  }

  &::after {
    left: 0;
  }
}

.bem-billing-reports__tbody {
  tr:first-of-type {
    background-color: $billing-reports-cullent-cycle-bg;
    color: $black;
  }
}

.bem-table-cell-modifier__text-left {
  &:last-of-type {
    text-align: left;
  }
}

.bem-table-cell-modifier__padding-top-bottom18 {
  padding: 18px 10px;
}

.bem-table-container-modifier__margin-top50 {
  margin-top: 50px;
}

.bem-table-container-modifier__margin-bottom100 {
  margin-bottom: 100px;
}

.bem-billing-entries-tr-changed {
  background-color: #fbbbbe;
}

.bem-billing-entries-tr-current {
  background-color: #c2e1f1;
}

.bem-billing-entries-tr-new {
  background-color: #e02e7e;
}

.grid-blue-row {
  background-color: $mobile-subscriber-active-row !important;
}
.actions-column_dropdown-toggle::after {
  content: "";
  margin: 0;
  border: 0;
}

.ui-grid-menu-inner {
  .ui-grid-menu-items {
    .ui-grid-icon-ok {
      position: relative;

      &::before {
        content: '';
        position: relative;
        display: inline-block;
        top: -3px;
        z-index: 1;
        color: $white;
        margin-left: 3px;
        margin-top: 0px;
        width: 10px;
        height: 5px;
        border-bottom: solid 2px currentColor;
        border-left: solid 2px currentColor;
        -webkit-transform: rotate(-50deg);
        transform: rotate(-50deg);
      }

      &::after {
        content: '';
        position: absolute;
        display: block;
        background-color: $ui-grid-columns-checkbox-bg-color;
        border-radius: 4px;
        top: 0px;
        left: 0px;
        height: 18px;
        width: 18px;
      }
    }
    .ui-grid-icon-cancel {
      &::before {
        content: '';
        position: relative;
        display: inline-block;
        top: 4px;
        left: -3px;
        color: $extra-semi-grey;
        width: 14px;
        height: 14px;
        border: 2px solid currentColor;
        border-radius: 4px;
        margin-right: -3px;
        margin-top: -10px;
      }
    }
  }
}

.table_th-left-text {
  th {
    text-align: left;
  }
}

.resources-wrapper {
  position: relative;
  border: 1px solid $border-color;
  border-radius: 2px;
  margin-top: 0;
  z-index: 50;
}

.adjust-filter-icon-position {
  margin-left: auto;
  margin-right: 18px;

  &.adjust-for-multiselects {
    position: absolute;
    right: -30px;
    top: -15px;
  }
}

.ui-grid-column-menu-button_custom-styles {
  right: 8px;
  top: 18px;
  font-size: .875rem;
  color: $grid-column-menu-icon-color;
}

.ui-grid-filter-container {
  .ui-grid-filter-button {
    top: 14px;
    right: 30px;
    font-size: 0.675rem;
    color: $grid-column-menu-icon-color;

    &.ui-grid-filter-button_date-modifier {
      right: 24px;
    }
  }
}

.ui-grid-modal-pagination-fix {
  .ui-grid-pager-panel {
    justify-content: center;
    .ui-grid-pager-container {
      margin: 0 0 0 auto;
    }
    .ui-grid-pager-control {
      margin: 0;

      button {
        padding: 0 10px;
        text-align: center;
        width: 34px;
        height: 30px;
      }
    }
    .ui-grid-pager-count-container {
      margin: 0 0 0 auto;
    }
  }
}

.ui-grid-expandable-buttons-cell {
  height: 100%;
  .ui-grid-cell-contents {
    text-overflow: unset;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0;
    justify-content: center;
  }
}

.reports-history-header {
  text-transform: none;
  color: $ui-grid-text-color;
  font-weight: 100;
  font-size: 1.55rem;
  margin-top: 10px;
  .rdsh {
    display: inline-block;
    width: 2rem;
    transform: rotate(90deg) translate(30%);
  }
}

.scrollFiller {
  border: none;
}

.expandableRow, .scrollFiller {
  background-color: $white;
}

.ui-grid-row-expanded {
  font-weight: bold;
  background-color: $ui-grid-row-expanded-background-color !important;
}

.expandableRow .ui-grid .ui-grid-viewport .ui-grid-canvas .ui-grid-row:nth-child(odd) .ui-grid-cell {
  background-color: $white;
}

.custom-th-styles {
  th {
    font-size: .875rem;
    font-weight: 400;
    color: $black;
  }
}

[id="grid-billing-overview.operator"] {
  .ui-grid {
    &.fix-menu-overlap {
      transform: none;
      z-index: auto;

      .ui-grid-menu {
        z-index: 10;
      }
    }
  }
}
