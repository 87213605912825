%billing-footer-buttons {
    height: 39px;
    padding: 0 35px;
    margin: 12px 0 0 5px;
    @include responsive-font-size($baseFontSize);
    font-weight: 600;
    line-height: 39px;
    color: $security-grey;
    background-color: $white;
    border-radius: 20px;
}

%version-button {
    width: 275px;
    height: 44px;
    margin: 0;
    color: $black;
    border: 1px solid $new-green;
    border-radius: 46px;
}

%table-header-buttons {
    position: relative;
    width: 105px;
    height: 42px;
    padding: 0 0 0 30px;
    font-weight: 400;
    background-color: transparent;
}

//%table-header-buttons:not(.add-entry-button) {
//    background-color: transparent;
//}

%th-styled {
    position: relative;
    min-width: 40px;
    padding: 20px 10px;
    @include responsive-font-size($baseFontSize);
    font-weight: 600;
    text-align: center;
}

%td-styled {
    height: 70px;
    padding: 0;
    @include responsive-font-size($baseFontSize);
    font-weight: 400;
    text-align: center;
}

%billing-tooltip-shadow {
    box-shadow: 0 0 8px 2px $billing-tooltip-shadow-color;
}

%csv-label {
    position: relative;
    display: inline-block;
    float: none;
    width: 105px;
    height: 42px;
    padding: 0 0 0 47px;
    margin: 0;
    line-height: 41px;
    background-color: transparent;
    border: 1px solid $new-green;
    border-radius: 20px;
}

//%search-icon {
//    position: absolute;
//    top: 8px;
//    right: 14px;
//    width: 16px;
//    height: 16px;
//    background-image: url('#{$images}search.png');
//    background-repeat: no-repeat;
//}

%sort-arrow {
    position: absolute;
    display: block;
    width: 9px;
    height: 9px;
    background-color: transparent;
    border: 2px solid #aaa;
}

%down-arrow {
    top: 17px;
    right: 12px;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg);
}

%up-arrow {
    top: 12px;
    right: 12px;
    border-bottom: 0;
    border-left: 0;
    transform: rotate(-45deg);
}

%width-75 {
    width: 75px;
}

%padding-0 {
    padding: 0;
}

.price-status {
    margin-left: 13px;
    @include responsive-font-size($baseFontSize);
    font-weight: 600;

    &.approved {
        color: $select-status-approved;
    }

    &.draft {
        color: $select-status-draft;
    }

    &.pending {
        color: $select-status-pending;
    }

    &.retired {
        color: $select-status-retired;
    }
}

.bem-billing-sort-arrow {
    position: absolute;
    top: 45%;
    right: 5px;
    width: 0;
    height: 0;

    &.ascending {
        border-right: 5px solid transparent;
        border-bottom: 5px solid $blue;
        border-left: 5px solid transparent;
    }

    &.descending {
        border-top: 5px solid $blue;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
}

.bem-billing-entries-table__hover-div {
    position: relative;
    height: 100%;
    padding: 25px 0;
    font-weight: 400;

    &:hover {
        color: $billing-hover-td;

        .bem-billing-entries-table__letter-i-icon {
            display: block;
        }
    }
}

.bem-billing-entries-table__letter-i-icon {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
    width: 20px;
    height: 20px;
    color: $white;
}

.bem-billing-entries-table__warning-icon {
    color: $billing-hover-td;
}

.bem-billing-entries-table__custom-tooltip-content {
    @extend %billing-tooltip-shadow;
    @include calc(top, '50% + 15px');
    width: 180px;
    @include responsive-font-size($baseFontSize);
    background-color: $billing-tooltip-color;

    &.right-tooltip {
        top: calc(50% + 10px);

        &::after {
            @include calc(top, '50% - 25px');
            border-right-color: $billing-tooltip-color;
        }
    }
}

input.bem-billing-entries-table__edit-input {
    // width: 150px;
    //
    &[type="text"] {
        width: 100%;
        height: 51px;
        padding: 0 5px;
        background-color: transparent;
        border: 0;
        border-radius: 0;
    }

    &:focus {
        outline: 2px solid $blue;
    }
}

.bem-billing-entry-table__td {
    // .tooltip {
    //     .tooltip-content {
    //         &::after {
    //             border-right-color: 0;
    //         }
    //     }

    //     &.danger-tooltip {
    //         position: absolute;
    //         top: 2px;
    //         right: 2px;
    //         display: none;
    //         // display: none;

    //         .tooltip-content {
    //             top: 10px;
    //             left: -62px;
    //             width: 140px;

    //             &::after {
    //                 top: -14px;
    //                 left: calc(52% - 10px);
    //             }
    //         }
    //     }
    // }

    .input-has-error {
        .bem-validation-icon {
            // display: block;
            visibility: visible;
        }
    }

    .validation {
        top: 0;
        left: 0;
        display: none;
    }

    select.bem-billing-entries-table__edit-select {
        width: auto;
        height: 51px;
        min-width: 100%;
        padding: 0 8px;
        background-color: $white;
        background-position: right 9px center;
        border: 0;
        border-radius: 0;

        &:focus {
            outline: 2px solid $blue;
        }
    }
}

.bem-validation-icon {
    position: absolute;
    top: 2px;
    right: 2px;
    // display: none;
    visibility: hidden;

    .icon-alert-error {
        color: $bright-pink;
    }
}

.bem-billing-entries-table__editable-row {
    outline: 2px solid $notification-green;

    .bem-btn_underline-button {
        &:focus {
            outline: 2px solid $blue;
        }
    }
}

.approval-buttons {
    //display: flex;
    //align-items: center;
    //justify-content: flex-end;
    //height: 75px;
    //margin-right: 20px;

    // button {
    //     background-color: $white;
    //     margin-left: 5px;
    // }

    .approve-btn {
        border: 1px solid $notification-green;
        color: $notification-green;
    }

    .reject-btn {
        border: 1px solid $notification-pink;
        color: $notification-pink;
    }

    .cancel-btn {
        border: 1px solid $light-grey;
        color: $light-grey;
    }

}

.user-form {

    .right-block {
        .version-button {
            @extend %billing-footer-buttons;
            @extend %version-button;
        }


    }



    .no-price-plans {
        padding: 20px;
    }

    .left-block,
    .right-block {

        .version-button {
            @extend %billing-footer-buttons;
            @extend %version-button;
        }

        .select-container {
            position: absolute;
            top: 110%;
            left: 202px;
            z-index: 10;
            width: 285px;
            max-height: 450px;
            padding: 5px;
            overflow-y: auto;
            @include responsive-font-size($baseFontSize);
            background-color: $white;
            border-radius: 10px;
            box-shadow: 0 0 12px 0 $billing-tooltip-shadow-color;

            .select-items {
                padding: 0;
                margin: 0;
                list-style: none;

                .select-item {
                    padding: 8px 5px;
                    margin: 0 5px;
                    border-bottom: 1px solid $select-item-border-color;

                    &:hover {
                        cursor: pointer;
                        background-color: $select-item-hover-color;
                    }

                    &:last-child {
                        border-bottom: 0;
                    }

                    .status-approved {
                        color: $select-status-approved;
                    }

                    .status-pending {
                        color: $select-status-pending;
                    }

                    &.select-search {
                        padding: 2px 0 5px;

                        &:hover {
                            cursor: auto;
                            background-color: $white;
                        }
                    }
                }

                .currency-search {
                    margin: 0;

                    [type='text'] {
                        width: 255px;
                        margin: 0;
                        background-color: $search-background;

                        //@include placeholder {
                        //    font-weight: 600;
                        //}
                    }

                    .search-icon {
                        @extend %search-icon;
                    }
                }
            }
        }
    }
}

.pp-status-approved {
    color: $select-status-approved;
}

.pp-status-pending {
    color: $select-status-pending;
}

.table-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 60px;

    background-color: $background-grey;

    .approve-legend {

        display: flex;
        align-items: center;

        .checkbox {
            margin: 0;
            margin-right: 12px;
        }

        .auto-label {
            margin: 0;
        }

        .item {
            display: flex;
            align-items: center;

            margin-left: 20px;
        }

        .square {
            display: inline-block;
            width: 20px;
            height: 20px;

            margin-right: 5px;
            border-radius: 3px;
        }

        .current-sq {
            background-color: #c2e1f1;
        }

        .changed-sq {
            background-color: #fbbbbe;
        }

        .new-sq {
            background-color: #e02e7e;
        }

        .text {
            font-weight: 300;
        }
    }

    .header-text {
        padding-left: 15px;
        margin: 0;
        font-size: 28px;
        text-transform: none;
    }

    .table-search {
        //flex-grow: 1;
        margin: 0;

        [type="text"] {
            // width: 95%;
            float: right;
            width: 260px;
            height: 42px;
            margin: 0 10px 0 0;
            background-color: $white;

            @include placeholder {
                @include responsive-font-size($baseFontSize);
            }
        }

        [type="submit"] {
            top: 13px;
            right: 22px;
        }

        .billing-table-form {
            padding: 0 0 10px;
        }

        .search-icon {
            @extend %search-icon;
            top: 12px;
            right: 28px;
        }
    }

    .entry-button-container {
        order: 1;
    }

    .import-buttons-container {
        order: 2;
        padding: 0 0 0 4px;
        margin-right: 10px;

        .csv-icon {
            position: absolute;
            top: 18%;
            left: 17%;
            width: 22px;
            height: 22px;
            @include responsive-font-size($font-size-xl);
        }

        .label-container {
            display: inline-block;

            .csv-label {
                @extend %csv-label;
            }
        }
    }

    .btn {
        &.export-button {
            @extend %billing-footer-buttons;
            @extend %version-button;
            @extend %table-header-buttons;
        }

        &.import-button {
            @extend %billing-footer-buttons;
            @extend %version-button;
            @extend %table-header-buttons;
        }

        &.add-entry-button {
            @extend %billing-footer-buttons;
            @extend %version-button;
            @extend %table-header-buttons;
            width: 165px;
            padding: 0;
            color: $input-color-grey;
            background-color: $white;
        }

    }
}

.table-container {
    width: 100%;
    //max-height: 535px;
    padding: 0 10px;
    overflow: auto;
    border-top: 1px solid $border-color;

    .entry-table-form {
        padding: 0;
        margin: 0;
    }

    .table-bordered {
        width: 100%;
        margin-bottom: 5px;



        &.data-table {

            tr {
                background-color: $white;
            }



            th {
                @extend %th-styled;
            }

            th:last-child {
                border-left: 1px solid $border-color;
            }

            td {
                @extend %td-styled;
            }

            .i-icon {
                position: absolute;
                top: 0;
                right: 0;
                display: none;
                width: 20px;
                height: 20px;
                color: $white;
                // background-color: $billing-tooltip-color;

                .icon-warning2 {
                    color: $billing-hover-td;
                }
            }

            .billing-hover-div {
                position: relative;
                height: 100%;
                padding: 25px 0;
                font-weight: 400;

                &:hover {
                    color: $billing-hover-td;

                    .i-icon {
                        display: block;
                    }
                }

                td:last-child {
                    padding-left: 0;
                    border-left: 1px solid $border-color;
                }

                .billing-select-styled {
                    width: 100px;
                    height: 96%;
                    padding: 0 8px;
                    background-color: $white;
                    background-position: right 9px center;
                    border: 0;
                    border-radius: 0;
                }
            }

            td:last-child {
                padding-left: 0;
                border-left: 1px solid $border-color;
            }

            .billing-select-styled {
                width: 100px;
                height: 96%;
                padding: 0 8px;
                background-color: $white;
                background-position: right 9px center;
                border: 0;
                border-radius: 0;

                &:focus {
                    outline: 2px solid $blue;
                }
            }

            .billing-edit-entry {
                background-color: transparent;

                &:hover {
                    color: $blue;
                    cursor: pointer;
                }

                &:focus {
                    outline: 2px solid $blue;
                }
            }
        }

        .table-input {
            width: 150px;
            height: 96%;
            padding: 0 5px;
            background-color: transparent;
            border: 0;
            border-radius: 0;

            &.width-100-per {
                width: 100%;
            }

            &:focus {
                outline: 2px solid $blue;
            }
        }
    }

    .editable-row {
        outline: 2px solid $notification-green;

        .tooltip {
            .tooltip-content {
                &::after {
                    border-right-color: 0;
                }
            }

            &.danger-tooltip {
                position: absolute;
                top: 2px;
                right: 2px;
                display: none;
                // display: none;

                .tooltip-content {
                    top: 10px;
                    left: -62px;
                    width: 140px;

                    &::after {
                        top: -14px;
                        left: calc(52% - 10px);
                    }
                }
            }
        }

        .input-has-error {
            .tooltip {
                &.danger-tooltip {
                    display: block;
                }
            }
        }

        .validation {
            top: 0;
            left: 0;
        }

        select.ng-invalid-validation.ng-touched,
        [type=password].ng-invalid-validation.ng-touched,
        [type=text].ng-invalid-validation.ng-touched {
            border: 1px solid transparent !important;
        }
    }

    .net-name {
        height: 100%;
        margin-top: 2px;
    }
}

.billing-footer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 25px;

    .footer-buttons {
        margin-right: 15px;

        .btn {
            height: 44px;
            padding: 0 10px;
            font-weight: 600;
            line-height: 0;

            &.cancel-button {
                @extend %billing-footer-buttons;
                margin: 0;
                background-color: $background-grey;
                border: 1px solid $security-grey;
            }

            &.save-button {
                width: 170px;
                height: 40px;
                @include responsive-font-size($baseFontSize);
            }
        }
    }
}

.network-provider-form {
    .add-new-button {
        padding: 12px 30px;
        margin: 12px 15px 10px 0;
        @include responsive-font-size($baseFontSize);
        font-weight: bold;
        color: $white;
        background-color: $alert-green;
        border-radius: 20px;

        &:focus {
            box-shadow: $shadow-for-focused-input;
        }
    }
}

.security-form {
    .imsi-ranges-container,
    .price-plan-container {
        .input-field {
            height: auto;
        }
    }
}

.bem-child-price {
    position: relative;
    padding-left: 50px;

    &:before {
        position: absolute;
        top: -28px;
        left: -13px;
        width: 50px;
        height: 50px;
        border: 3px solid $blue;
        border-top: 0;
        border-right: 0;
        content: '';
    }
}

.bem-grand-child-price {
    position: relative;
    padding-left: 100px;

    &:before {
        position: absolute;
        top: -28px;
        left: -13px;
        width: 100px;
        height: 50px;
        border: 3px solid $blue;
        border-top: 0;
        border-right: 0;
        content: '';
    }
}

.price-plans {
    @include calc(width, '100% - 210px');
    padding-left: 25px;
    line-height: 1;

    tr:nth-of-type(1) {
        td {
            border-top: 0;
        }
    }

    td {
        padding: 7px;
    }

    .child {
        position: relative;
        padding-left: 50px;

        &:before {
            position: absolute;
            top: -28px;
            left: -13px;
            width: 50px;
            height: 50px;
            border: 3px solid $blue;
            border-top: 0;
            border-right: 0;
            content: '';
        }

        .name-and-currency {
            .name {
                font-weight: normal;
            }
        }
    }

    .date-and-author {
        margin-top: 5px;
        @include responsive-font-size($font-size-sm);
        font-weight: normal;
        color: $security-grey;
    }

    .name-and-currency {
        .name {
            @include responsive-font-size($baseFontSize);
        }

        .currency {
            @include responsive-font-size($font-size-sm);
            font-weight: bold;
        }

        .plan-status {
            font-weight: normal;
            @include responsive-font-size($font-size-sm);

            &.approved {
                color: $select-status-approved;
            }

            &.draft {
                color: $select-status-draft;
            }

            &.pending {
                color: $select-status-pending;
            }

            &.retired {
                color: $select-status-retired;
            }
        }
    }
}

.modal-wrapper {
    .imsi-range-modal {
        min-width: 500px;

        .mcc-mnc {
            margin-bottom: 40px;
            @include responsive-font-size($font-size-xl);

            .item {
                margin-bottom: 10px;
            }
        }

        .imsi-range-input {
            position: relative;

            .first-part {
                position: absolute;
                top: 17px;
                right: 60px;
                color: $radio-grey;
            }
        }
    }

    .subscriber-fee-modal {
        min-width: 500px;

        .modal-content {
            padding: 20px 85px;

            .subscriber-info-container {
                margin-top: 30px;
                @include responsive-font-size($font-size-md);

                .info-block {
                    margin: 40px 0;
                }

                .info-block-title {
                    width: 120px;
                    margin-right: 55px;
                }
            }
        }

        .subscriber-fee-footer {
            justify-content: center;

            .btn {
                height: 50px;
                margin: 0 10px;
                line-height: 52px;
            }
        }
    }
}

.price-beta-tabs {
    font-weight: 400;

    .label {
        top: 24px;
        left: 76%;
    }

    // .left-container {
    //     margin-left: auto;
    // }

}

.billing-beta-table-container {
    width: 98%;
    margin: 20px 1%;
    background-color: $white;
    border: 1px solid $border-color;
    border-radius: 5px;

    %move-arrow {
        position: absolute;
        display: block;
        width: 15px;
        height: 15px;
        background-color: transparent;
        border: 2px solid #848484;
    }

    %forward-arrow {
        top: 23px;
        right: 10px;
        border-bottom: 0;
        border-left: 0;
        transform: rotate(45deg);
    }

    .table-bordered.billing-beta {
        th {
            padding: 10px;
            @include responsive-font-size($font-size-xl);
            font-weight: 100;
            color: $blue;

            .sort-arrow {

                &.active {
                    border-color: #747474;
                }

                &.up-arrow {
                    @extend %up-arrow;
                }

                &.down-arrow {
                    @extend %down-arrow;
                }
            }
        }

        th:last-child {
            border-left: 1px solid $border-color;

            .up-arrow,
            .down-arrow {
                left: 85%;
            }
        }

        td {
            height: 60px;
        }

        td:last-child {
            border-left: 1px solid $border-color;
        }

        .padding-0 {
            @extend %padding-0;
        }

        .tooltips-container {
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            margin: 0;

            .custom-content {
                left: -15px;
                width: 70px;
                text-align: center;
            }
        }

        .edit.custom {
            margin-left: 2px;

            .icon-unblocked {
                &.custom {
                    display: block;
                    width: 17px;
                    height: 20px;

                    &::before {
                        position: relative;
                        top: -1px;
                        left: -1px;
                        @include responsive-font-size($font-size-lg);
                    }
                }
            }
        }

        .remove.custom {
            margin-right: 2px;
            margin-left: 0;
        }

        .name-field {
            padding: 0 15px;
            text-align: left;

            .who {
                display: block;
                margin-top: -2px;
            }
            .date {
                margin-top: 10px;
                @include responsive-font-size($font-size-sm);
                color: $security-grey;
            }
        }
    }

    .move-arrow {
        @extend %move-arrow;

        &.forward-arrow {
            @extend %forward-arrow;
        }
    }
}

.submit-for-approval {
    height: 36px;
    padding: 0 10px;
    margin: 5px 50px 0 0;
    @include responsive-font-size($baseFontSize);
    font-weight: bold;
    line-height: 36px;
    color: $black;
    background-color: $white;
    border: 1px solid $new-green;
}

.width-75 {
    @extend %width-75;
}

.imsi-ranges-block {
    padding: 20px 0 0;
    //margin: 20px 0 0;
    border-top: 1px solid $code-border;
}

.date-picker-input-field {
    [date-picker-wrapper] {
        top: 0;
        //right: 40%;
        left: 220px;
    }
}
