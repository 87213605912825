.detail-header {
    padding-bottom: 5px;
    margin: 20px 15px -5px;
    @include responsive-font-size($font-size-md);

    [type=text] {
        width: 275px;
        padding: 0 20px;
        font-weight: 300;
        background-color: $input-background-grey;
    }

    .description {
        [type=text] {
            @include calc (width, '100% - 210px');
            @include responsive-font-size($font-size-md);
            font-weight: normal;
        }
    }

    .top-row {
        @include clearfix;
        padding: 15px 30px;
        border-bottom: 1px solid $border-color;
    }

    .bottom-row {
        @include clearfix;
        padding: 15px 30px 30px;
    }

    .input-field {
        height: 46px;
        margin-bottom: 15px;
        line-height: 46px;

        label:not(.radiobutton):not(.tumbler):not(.auto-label):not(.lbl) {
            display: inline-block;
            min-width: 190px;
            font-weight: 400;
            color: $security-grey;
        }

        .auto-label {
            font-weight: 300;
        }
    }

    .left-block {
        @include clearfix;
        float: left;
        width: 55%;
    }

    .right-block {
        @include clearfix;
        float: right;
        width: 45%;
    }

    .title {
        float: left;
        width: 80px;
        font-weight: 300;
        line-height: 46px;
    }

    .data {
        @include calc (width, '100% - 80px');
        float: right;
    }
}

%filter-buttons {
    display: inline-block;
    @include responsive-font-size($font-size-xl);
    color: $filters-gray;
    cursor: pointer;

    //&:hover {
    //    color: $black;
    //}
}

.trash {
    @extend %filter-buttons;
}

.star {
    @extend %filter-buttons;
    margin-left: 15px;
}

.sort {
    @extend %filter-buttons;
    margin: 0 15px;
}

.filter {
    height: 50px;
    margin: 0 15px;
    line-height: 50px;
    text-align: right;
}

.content {
    margin: 0 15px;
}

.table-bordered {
    width: 100%;

    tr {
        &:nth-of-type(2n):not(.roaming-management-custom-row) {
            background-color: $input-background-grey;

            [type=text] {
                background-color: $input-background-dark-grey;
            }
        }

        //&:nth-of-type(2n)input {
        //    background-color: $input-background-dark-grey;
        //}

        &:last-of-type {
            td {
                &:first-of-type {
                    border-bottom-left-radius: 5px;
                }

                &:last-of-type {
                    border-bottom-right-radius: 5px;
                }
            }
        }
    }

    th {
        padding: 15px;
        @include responsive-font-size($font-size-xl);
        font-weight: 100;
        color: $blue;
        text-align: left;
        border: 1px solid $border-color;
        border-top: 0;
        border-top-left-radius: 5px;

        &:first-of-type {
            border-left: 0;
        }

        &:last-of-type {
            &:not(.standart) {
                border-left: 0;
            }

            border-right: 0;
            border-top-right-radius: 5px;
        }

        &:nth-last-child(-n+2):not(.standart) {
            border-right: 0;
        }
    }

    td {
        padding: 15px;
        @include responsive-font-size($baseFontSize);
        border: 1px solid $border-color;
        border-bottom: 0;
        font-weight: normal;

        &:first-of-type {
            border-left: 0;
        }

        &:last-of-type {
            &:not(.standart) {
                padding-left: 5%;
                text-align: right;
                border-left: 0;
            }

            @include responsive-font-size($baseFontSize);
            border-right: 0;
        }

        &:nth-last-child(-n+2):not(.standart) {
            border-right: 0;
        }
    }

    .star {
        margin: 0;
        @include responsive-font-size($font-size-lg);
    }

    .checkbox {
        margin-right: 5px;
    }

    .manage {
        min-width: 90px;
    }
}

.date-picker-date-time {
    table {
        th {
            padding: auto;
            font-size: auto;
            font-weight: 100;
            color: black;
            text-align: center;
            border: 0;
        }

        td {
            padding: auto;
            @include responsive-font-size($baseFontSize);
            border: 0;

            &:first-of-type {
                border-left: 0;
            }

            &:last-of-type {
                padding-left: 0;
                @include responsive-font-size($baseFontSize);
                text-align: center;
            }
        }
    }
}

.tr-checked {
    background-color: $security-background-grey;

    .checkbox {
        &::before {
            color: $black;
        }
    }
}

.active-row {
    color: $blue;

    .edit:first-of-type {
        color: $white;
        background: $blue;
    }
}

.profile-data {

    .tabs {
        display: block;
        text-align: right;
        box-shadow: 0 -10px 10px -2px $top-shadow;

        .tab {
            display: inline-block;
            float: left;
            padding-top: 1px;
            padding-right: 2px;
        }

        .active {
            color: $black;
        }

        a:hover {
            color: $black;
        }
    }
}

.detail-footer {
    padding: 10px 15px 30px;
}

.new-rule-wrapper {
    @include clearfix;
    //float: right;
    margin-top: -63px;
}

.get-network-vpns-status-wrapper {
    @include clearfix;
    float: right;
    margin-top: -51px;
    margin-right: 170px;
}

.bem-modifier_margin-top-0 {
    margin-top: 0;
}

.no-entries-note {
    padding: 30px;
    @include responsive-font-size($font-size-md);
    text-align: center;
}
