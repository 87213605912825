.dropdown.show .dropdown-menu {
  display: block;
}

.dropdown-menu {
  &.dropdown-menu-right {
    right: 0;
    left: auto;
  }
}

.dropdown-item {
  i {
    width: 16px;
    display: inline-block;
    margin-right: 1rem;
  }
  //text-transform: capitalize;
  cursor: pointer;
}

.dropdown-multiselect-styled {
  display: block;
  overflow: auto;
  max-height: 314px;
  max-width: 350px;
}

.multiselect-list-item {
  display: block;
  position: relative;
  width: 100%;
  padding: .25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: $multiselect-list-item-font-color;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:active {
    color: $white;
    text-decoration: none;
    background-color: $multiselect-list-item-active-backgroud-color;
  }

  &:focus {
    color: $multiselect-list-item-focus-font-color;
    text-decoration: none;
    background-color: $multiselect-list-item-focus-backgroud-color;
  }

  &:hover {
    color: $multiselect-list-item-focus-font-color;
    text-decoration: none;
    background-color: $multiselect-list-item-focus-backgroud-color;
  }
}

.multiselect-search-input {
  border-radius: 20px;
  height: 33px;
  line-height: 50px;
  background: #edf1f2;
  border: none;
  padding: 0 10px;
  font-size: 14px;
  margin: 0;

  &:focus {
    background: #edf1f2;
  }
}

.multiselect-divider {
  height: 0;
  margin: .5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.multiselect-list-anchor {
  position: relative;
  padding-left: 25px;
}

.multyselect-icon-check {
  position: absolute;
  left: 0;

  &::before {
    content: '\e985';
    opacity: 1;
    font-family: Aqua-Icons;
    font-size: 20px;
    color: #dee2e6;
  }

  &.glyphicon-ok {
    &::before {
      color: $black;
    }
  }
}

.dropdown-toggle {
  &.no-caret {
    &:after {
      display: none;
      width: 0;
      height: 0;
      margin-left: 0;
      vertical-align: 0;
      content: "";
      border: 0;
    }
  }
}