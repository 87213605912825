.iot-coming-soon {
    @include calc (height, '100vh - (#{$header-height} + #{$subheader-height})');
    position: relative;
    text-align: center;
    background: $iot-bg-layer;

    &.tab-fix {
        @include calc (height, '100vh - (#{$header-height} + 265px + #{$tabs-row-height})');

        h1 {
            padding: 7% 0 40px;
        }
    }

    &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: url('#{$images}iot-bg.jpg') no-repeat center center fixed;
        background-size: cover;
        content: '';
    }

    h1,
    h2,
    h3 {
        padding: 0;
        margin: 0;
        font-size: 70px;
        font-weight: 200;
        color: $white;
        text-transform: uppercase;
    }

    h1 {
        padding: 15% 0 40px;
    }

    h2 {
        padding: 0 0 20px;
        font-size: 50px;
        text-transform: lowercase;
    }

    h3 {
        padding: 0 0 10px;
        font-size: 30px;
        text-transform: none;
    }

    .proposal {
        margin-top: 20px;

        button {
            padding: 10px 50px;
            font-weight: bold;
            color: $white;
            text-transform: uppercase;
            background: transparent;
            border: 1px solid $blue;
            border-radius: 25px;
        }
    }
}
