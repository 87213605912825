.fired-alert-modal {
    .modal {
        // width: 1000px;

        .section {
            padding: 15px;
            text-align: left;
        }
    }

    .block {
        margin: 15px;
    }

    .table-bordered {
        font-weight: 300;

        .bgc-gray {
            color: $light-grey;
        }

        .standart {
            text-align: left;
        }

        td {
            @include responsive-font-size($baseFontSize);
        }
    }

    .section-title {
        margin-bottom: 15px;
        @include responsive-font-size($font-size-lg);
        font-weight: 500;
    }

    .btn.green {
        height: 34px;
        line-height: 34px;
    }

    .row {
        @include display(flex);
        @include justify-content(flex-start);

        margin-bottom: 25px;

        .item {
            width: 33%;

            .key {
                display: inline-block;
                width: 130px;
                font-weight: 300;
            }

            .value {
                display: inline-block;
                color: $light-grey;
            }
        }
    }
}
