@import "../helpers";

.tooltip:not(.jpu-tooltip) {
    position: relative;

    &:hover .tooltip-content {
        @include transition(.3s opacity .3s);

        visibility: visible;
        opacity: 1;
    }

    &:hover .tooltip-content.priceplan-custom-tooltip {
        width: 230px;
        margin-top: 10px;
        line-height: 18px;
        text-align: left;
    }

    &:hover .tooltip-content.billingstate-custom-tooltip {
        width: 230px;
        margin-top: 10px;
        line-height: 18px;
        text-align: left;
    }

    &.danger-tooltip {
        color: $bright-pink;

        .tooltip-content {
            color: $bright-pink;
            background-color: $white;
            border: 2px solid $bright-pink;

            &::after {
                border: 6px solid transparent;
                border-bottom-color: $bright-pink;
            }
        }
    }

}

.tooltip-content {
    @include transition(.3s opacity .3s);

    position: absolute;
    z-index: 10;

    visibility: hidden;
    padding: 7px;

    line-height: 14px;
    @include responsive-font-size($baseFontSize);
    font-weight: 300;
    color: $white;
    background-color: $tooltips-bg;
    border-radius: 5px;
    // box-shadow: 0 0 4px 4px $tab-shadow;

    opacity: 0;

    &.list-buttons {
        top: -53px;
        left: -26px;

        width: 85.5px;

        @include responsive-font-size($font-size-sm);
        text-align: center;

        &.btn-access {
            left: 15px;
        }
    }

    &.overflow-buttons {
        top: -53px;
        left: -11px;

        width: 56.5px;

        @include responsive-font-size($font-size-sm);
        text-align: center;
    }

    &::after {
        @include calc(left, '50% - 10px');
        position: absolute;
        top: -10px;
        width: 0;
        height: 0;
        border: 10px solid transparent;
        border-top: 0;
        border-bottom-color: $tooltips-bg;
        content: '';
    }

    &.top-tooltip {
        top: -120%;

        &::after {
            @include calc(left, '50% - 10px');
            position: absolute;
            top: 100%;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-bottom: 0;
            border-top-color: $tooltips-bg;
            content: '';
        }
    }

    &.right-tooltip {
        @include calc(top, '50% - 10px');
        @include transform(translateY(-50%));
        left: 100%;
        margin-left: 15px;

        &::after {
            @include calc(top, '50% - 10px');
            position: absolute;
            left: -10px;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-left: 0;
            border-right-color: $tooltips-bg;
            content: '';
        }
    }

    &.bottom-tooltip {
        margin-top: 15px;
    }

    &.left-tooltip {
        @include calc(top, '50% - 10px');
        @include transform(translateY(-50%));
        right: 100%;
        margin-right: 15px;

        &::after {
            @include calc(top, '50% - 10px');
            position: absolute;
            left: 100%;
            width: 0;
            height: 0;
            border: 10px solid transparent;
            border-right: 0;
            border-left-color: $tooltips-bg;
            content: '';
        }
    }

    &.vpn-config {
        top: 17px;
        @include responsive-font-size($font-size-sm);
    }
}

.scroll-table {
    .table-row:first-child {
        .overflow-buttons {
            top: auto;
            bottom: -52px;

            &::after {
                top: -10px;
                border: 10px solid transparent;
                border-top: 0;
                border-bottom-color: $tooltips-bg;
            }
        }
    }
}

.bem-approve-tooltip {
    color: $white;
    background-color: $approve-button-green;

    i {
        display: block;
        width: 16px;
        height: 18px;

        .path1 {
            display: none;
        }

        .path2 {
            &::before {
                position: relative;
                top: -3px;
                left: 21px;
                font-size: 26px;
            }
        }
    }
}

.bem-reject-tooltip {
    color: $white;
    background-color: $approve-button-purple;

    i {
        display: block;
        width: 16px;
        height: 18px;

        .path1 {
            display: none;
        }

        .path2 {
            &::before {
                position: relative;
                top: -3px;
                left: 21px;
                font-size: 26px;
            }
        }
    }
}

.bem-lifecycle-tooltips-content-positioning {
    left: -15px;
    width: 70px;
    text-align: center;
}

.bem-question-mark-round-tooltip {
    position: absolute !important;
    @include responsive-font-size($font-size-md);
    color: $white;
    font-weight: 300;
    text-align: center;
    background-color: $blue;
    height: 28px;
    border-radius: 15px;
    line-height: 26px;
    padding: 1px 10px;
}

.bem-question-mark-round-tooltip__relative {
    position: relative !important;
}

.bem-question-mark-round-tooltip__absolute {
    position: relative !important;
}

.bem-question-mark-round-tooltip_apn-positioning {
    top: torem(-3px);
    right: -2.5rem;
    //left: torem(80px);
}

.bem-question-mark-round-tooltip_provisioning-positioning {
    position: relative !important;
    @include responsive-font-size($baseFontSize);
    color: $white;
    font-weight: 300;
    text-align: center;
    background-color: $blue;
    height: 22px;
    border-radius: 15px;
    line-height: 10px;
    padding: 8px 8px;
    margin-left: 10px;
}

.bem-question-mark-round-tooltip_input-positioning {
    top: torem(5px);
    margin-left: .8125rem;
    //margin-left: torem(5px);
}

.bem-question-mark-round-tooltip_select-positioning {
    top: torem(5px);
    right: -22px;
}

.bem-question-mark-round-tooltip_select-positioning-textarea {
    top: torem(5px);
    right: 0;
}

.bem-tooltip-width {
    width: 12rem;
}

.bem-customer-form-block-unblock-tooltip-pos {
    .tooltip-content {
        &.top-tooltip {
            top: -25%;
        }
    }
}

.bem-customer-tooltip {
    .tooltip-inner {
        max-width: 625px;
    }
}