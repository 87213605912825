@import 'helpers';
// Base class
.jpu-tooltip {
  position: absolute;
  z-index: $zindex-tooltip;
  display: block;
  margin: $tooltip-margin;
  // Our parent element can be arbitrary since tooltips are by default inserted as a sibling of their target element.
  // So reset our font and text properties to avoid inheriting weird values.
  @include reset-text();
  font-size: $tooltip-font-size;
  // Allow breaking very long words so they don't overflow the tooltip's bounds
  word-wrap: break-word;
  opacity: 0;

  &.show { opacity: $tooltip-opacity; }

  .arrow, .tooltip-arrow {
    position: absolute;
    display: block;
    width: $tooltip-arrow-width;
    height: $tooltip-arrow-height;

    &::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-tooltip-top {
  padding: $tooltip-arrow-height 0;

  .arrow, .tooltip-arrow {
    bottom: 0;

    left: 50%;
    margin-left: -$tooltip-arrow-width / 2;

    &::before {
      top: 0;
      border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-top-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-right {
  padding: 0 $tooltip-arrow-height;

  .arrow, .tooltip-arrow {
    left: 0;

    top: 25%;
    // top: 50%;
    // margin-top: -$tooltip-arrow-height;

    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      right: 0;
      border-width: ($tooltip-arrow-width / 2) $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
      border-right-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-bottom {
  padding: $tooltip-arrow-height 0;

  .arrow, .tooltip-arrow {
    top: 0;

    right: 50%;

    &::before {
      bottom: 0;
      border-width: 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-bottom-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-left {
  padding: 0 $tooltip-arrow-height;

  .arrow, .tooltip-arrow {
    right: 0;

    top: 25%;
    width: $tooltip-arrow-height;
    height: $tooltip-arrow-width;

    &::before {
      left: 0;
      border-width: ($tooltip-arrow-width / 2) 0 ($tooltip-arrow-width / 2) $tooltip-arrow-height;
      border-left-color: $tooltip-arrow-color;
    }
  }
}

.bs-tooltip-auto {
  &[x-placement^="top"] {
    @extend .bs-tooltip-top;
  }
  &[x-placement^="right"] {
    @extend .bs-tooltip-right;
  }
  &[x-placement^="bottom"] {
    @extend .bs-tooltip-bottom;
  }
  &[x-placement^="left"] {
    @extend .bs-tooltip-left;
  }
}

// Wrapper for the tooltip content
.tooltip-inner {
  max-width: $tooltip-max-width;
  padding: $tooltip-padding-y $tooltip-padding-x;
  color: $tooltip-color;
  text-align: center;
  background-color: $tooltip-bg;
  @include border-radius($tooltip-border-radius);
}

@include createTooltipClass ('jpu-styled-tooltip', $white, $tooltips-bg);
@include createTooltipClass ('new-jpu-styled-tooltip', $white, $jpu-new-styled-tooltips-bg, null, null, torem(12px), torem(8px)); //Tooltip styled by Dror's guidlines
@include createTooltipClass ('danger-styled-tooltip', $white, $bright-pink, 'aaa', 2px);

.danger-styled-tooltip > .tooltip-inner {
  background-color: $white;
  color: $bright-pink;
}


.bs-tooltip-max-width-400 > .tooltip-inner {
  $tooltip-max-width: 400px;
  max-width: $tooltip-max-width;
}

.apn-tooltip-arrow-position.bs-tooltip-right > .tooltip-arrow {
  top: 43%;
}

.user-profile-tooltip-arrow.bs-tooltip-right > .tooltip-arrow {
  top: calc(50% - 5px);
}

.apn-tooltip-arrow-position.bs-tooltip-left> .tooltip-arrow {
  top: 43%;
}

.user-profile-tooltip-arrow.bs-tooltip-left > .tooltip-arrow {
  top: calc(50% - 5px);
}

.roaming-tooltip-positioning {
  @include position(absolute, $left: 22% !important);
}


.tooltip-text-left {
  .tooltip-inner {
    text-align: left;
  }
}

.tooltip-user-profiles {
  .tooltip-inner {
    max-width: 450px;
  }
}

.tooltip-custom-padding {
  .tooltip-inner {
    padding: 20px;
  }
}