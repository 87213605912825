.adding-editing-card {
    padding: 22px;
    color: $white;
    background-color: $alert-green;
    box-shadow: 0 -10px 10px -2px $top-shadow;
}

.adaptive-form {
    .input-field {
        @media screen and (max-width: $break-large) {
            margin-bottom: 55px;
      }
    }

    .roles {
        @media screen and (max-width: $break-large) {
            width: 150px;
      }
    }
}

.security-form {
    @include responsive-font-size($font-size-md);

    .input-field {
        //height: 46px;
        margin-right: 1rem;
        line-height: 46px;

        &.flex {
            height: auto;
            margin-bottom: 0;
        }
    }

    [type=text] {
        line-height: 46px;
    }

    [type=text],
    [type=password],
    .file,
    select {
        padding: 0 20px;
        font-weight: 400;
        color: $input-color-grey;
        background-color: $input-background-grey;

        &:disabled {
            color: $radio-grey;
        }
    }

    &.dark-bg {
        [type=text],
        [type=password],
        .file,
        select {
            background-color: $input-background-dark-grey;
        }
    }

    select {
        padding: 0 40px 0 20px;
    }

    label {
        color: $security-grey;
        display: inline-block;
        // float: left;
        width: 190px;
        margin: 10px;
        font-weight: 400;
        line-height: 1;

        &.auto-label {
            width: auto;
            margin: 10px;
        }

        &.checkbox-text {
            width: 220px;
            line-height: 1;
            cursor: pointer;
        }
    }

    .relative-label {
        float: left;
        padding-top: 10px;
        line-height: 1;
    }

    .radiobutton {
        width: 22px;
        height: 22px;
        margin-right: 1rem;
        margin-bottom: -.25em;
    }

    .checkbox {
        position: relative;
        top: -2px;
        width: 15px;
        height: 15px;
        margin-right: 1rem;

        &.mobile-checkbox {
            top: 10px;
        }

        &.customer-checkbox {
            margin: 0;
            //top: 6px;
        }
    }

    .customer-auto-label {
        padding-left: 5px;
    }

    .rule-select {
        margin-left: 5px;
    }

    [type=text],
    [type=password],
    .file,
    .styled-select {
        width: 275px;

        &.port-input {
            width: 140px;
        }
    }

    #ipv6 {
        width: 500px;
    }

    form {
        padding: 25px 50px;
    }

    .footer-buttons {
        margin: 30px 0 20px;
    }

    .long-label {
        line-height: 25px;
        vertical-align: top;
    }

    [name="profile.ruleForm"] {
        //.validation {
        //    display: none;
        //}

        .ip-addr {
            &.input-has-error {
                .validation {
                    left: 15px;
                }
            }
        }

        // .action {
        //     &.input-has-error {
        //         .validation {
        //             left: 15px;
        //         }
        //     }
        // }

        //.input-has-error {
        //    margin-bottom: 45px;
        //
        //    .validation {
        //        position: absolute;
        //        top: 50px;
        //        left: 225px;
        //        display: block;
        //    }
        //}

        .validation-action {
            display: block;
        }

        .validation-source-address {
            left: 5px;
        }

        .validation-ip, .validation-ipv6 {
            left: 0;
            width: 500px;
        }
    }
}

.create-network-modal {
    .create-new-security-profile {
        .thing-name-modal {
            @include responsive-font-size($font-size-xl);
            font-weight: 300;
            color: $btn-browse-bg-color;
        }

        .input-field {
            &.network-name-field {
                &.input-has-error {
                    .validation {
                        top: -15px;
                    }
                }
            }
            &.input-has-error {
                .validation {
                    top: 0px;
                    left: -10px;
                }
            }
        }
    }
}
