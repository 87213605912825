.global-pools {
    .sort-global-pools {
//         @include display(flex);
//         @include justify-content(space-between);
//         margin: 1%;

        .ippools-counter {
            width: 200px;
            padding: 13px 0;
        }

//         select,
//         [type=text] {
//             width: 250px;
//             padding: 0 40px 0 20px;
//         }
    }
}
