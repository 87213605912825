//backups
.icon-dummy {
  &:before{
    content: $icon-settings;
  }
}

.icon-edit {
  &:before {
    content: $icon-edit;
  }
}

.icon-view {
  &:before {
    content: $icon-unblocked;
  }
}

.icon-mappointer {
  &:before {
    content: $icon-pin-on-map2;
  }
}

.icon-view {
  &:before {
    content: $icon-unblocked;
  }
}

.icon-logo {
  &:before {
    content: $icon-inventory;
  }
}

.icon-delete:before {
  &:before {
    content: $icon-trash;
  }
}

//new styles
.icon-color-white {
  &.path1 {
    &:before {
      color: $white;
    }
  }
}

.icon-color-gray {
  &.path1 {
    &:before {
      color: rgb(81, 82, 83);
    }
  }
}

.icon-login_go_down {
  &.icon-color-white {
    &:before {
      color: $white;
    }
  }
}

.icon-checkmark {
  &:before {
    content: $icon-checkmark1;
  }
}
