.body-toggled {
    .ng-toast {
        width: calc(100% - 87px);
        left: 87px;
    }
}

.ng-toast {
    //margin-top: 10px;
    z-index: 9999999999;
    width: calc(100% - 250px);
    left: 250px;

    .ng-toast__message__count {
        display: none;
    }

    .ng-toast__list {
        width: 600px;
        margin: 0 auto;
        border-radius: 7px;

        .ng-toast__message {
            .alert {
                border-radius: 7px;
                box-shadow: 0 2px 2px 2px $tab-shadow;

                border-top: transparent;
            }
        }

        .alert {
            width: 100%;
            font-size: 1.16rem;
            font-weight: 300;
            min-height: 45px;
            padding: 13px 10px;
            text-align: center;
            box-shadow: 12px 0 15px -4px $tab-shadow, -12px 0 8px -4px $tab-shadow;
            margin-bottom: 10px;

            .close {
                float: right;
                background: transparent;
                font-size: 2rem;
                font-weight: 400;
                line-height: 0.6;
            }

            &.alert-info {
                color: $notification-info-text;
                background-color: $notification-info-bg;

                .close {
                    color: $notification-info-icon;
                }
            }

            &.alert-warning {
                color: $notification-warning-text;
                background-color: $notification-warning-bg;

                .close {
                    color: $notification-warning-icon;
                }
            }

            &.alert-success {
                color: $notification-success-text;
                background-color: $notification-success-bg;

                .close {
                    color: $notification-success-icon;
                }
            }

            &.alert-error {
                color: $notification-error-text;
                background-color: $notification-error-bg;

                .close {
                    color: $notification-error-icon;
                }
            }

            span span {
                padding: 0 25px;
                display: block;
                word-break: break-word;
            }
        }
    }
}

.full-width-toasts {
    .ng-toast {
        width: 100%;
        left: 0;
    }
}
