@include media-breakpoint-down(xl) {
    .navbar, .navbar-nav {
        height: 100%;
        flex-wrap: nowrap;
    }
}

.mCSB_container_wrapper {
    margin-right: 0;
   & > .mCSB_container {
       padding: 0;
   }
}

// .sidebar-tooltip {
//     .tooltip-arrow {
//         &:before {
//             border-right-color: $tooltips-bg;
//         }
//     }
//     .tooltip-inner {
//         background: $tooltips-bg;
//         box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.75);
//     }
// }

.sidebar-submenu {
    margin-left: 250px;

    &.is-toggled {
        margin-left: $sidebar-collapsed-width;
    }

    a.dropdown-item {
        text-decoration: none;
    }
}

aside {
    position: fixed;
    float: left;
    width: #{$sidebar-width};
    height: 100%;
    //overflow-y: auto;
    background-color: $sidebar-background;

    .version {
        padding-bottom: 5px;
        font-size: .9rem;
        color: $sidebar-text-color;
    }
}

.brand-logo {
    text-align: center;

    a {
        font-weight: bold;
        color: $white;
        text-decoration: none;
    }

    .logo-img {
        margin-left: calc(1.75rem + 5px);

        i {
            font-size: 56px;
            color: $blue;
        }

        img {
            max-width: 150px;
            max-height: 90px;

            &[alt*='jpu'] {
                width: 110px !important;
            }

            &[alt*='kandy'] {
                width: 110px !important;
                margin-top: 1rem;
            }

            &[alt*='verint'] {
                width: auto !important;
                height: 30px !important;
                margin-left: -10px;
            }

            &[alt*='kore'] {
                width: auto !important;
                height: 30px !important;
                margin-left: -10px;
            }

            &[alt*='monogoto'] {
                width: 150px !important;
                //margin-top: 1rem;
            }

            &[alt*='traffilog'] {
                width: 150px !important;
                margin-top: 0;
            }

            &[alt*='aexonis'] {
                width: 75px !important;
                filter: contrast(1.9) brightness(0.9);
                // margin-top: 1rem;
            }

            &[alt*='coreone'] {
              width: 150px !important;
              // margin-top: 1rem;
            }

            &[alt*='global1sim'] {
                width: 150px !important;
                margin-top: 1rem;
            }
        }
    }

    .logo-text {
        height: 20px;
        margin-top: 15px;
    }
}

.sidebar-links {
    display: block;
    padding: 6px 8px;
    line-height: 1.438rem;
    color: $sidebar-text-color;
    text-decoration: none;
    cursor: pointer;
    border-left: 5px solid $sidebar-background;

    letter-spacing: 0.025em;
    font-weight: normal;

    > span {
        font-size: 14px;
        font-weight: normal;
        display: inline-block;
    }

    i {
        position: relative;
        top: 3px;
        font-size: 15px;
        color: $white;
        display: inline-block;
        padding-right: 4px;
        vertical-align: baseline;
        padding-left: 8px;
        &.fas {
            padding-right: .7rem;
            &.fa-hourglass-half {
                padding-right: 1rem;
            }
        }
    }

    &:hover {
        color: $white;
        background-color: $sidebar-active-link;
        border-left: 5px solid $sidebar-nav-border;
    }

    &.active {
        color: $white;
        background-color: $sidebar-active-link;
        border-left: 5px solid $sidebar-nav-border;
    }

    &.dropdown-toggle {
        &:after {
            display: none; // dropdown arrow

            position: absolute;
            top: 50%;
            right: 15px;
        }
        &.has-submenu {
            &:after {
                display: inline-block;
            }
        }
    }
}

.admin-menu {
    padding: 0;

    .tooltip {
        &:hover .tooltip-content {
            visibility: hidden;
            opacity: 0;
        }
    }
}

.toggled {
    width: $sidebar-collapsed-width;

    .sidebar-links-text {
        display: none;
    }

    .logo-img img {
        width: 60px;

        &[alt=JpU] {
            width: 60px !important;
            margin-top: 0 !important;
        }

        &[alt=monogoto] {
            width: 60px !important;
            margin-top: 0 !important;
        }

        &[alt=traffilog] {
            width: 60px !important;
            margin-top: 0 !important;
        }

        &[alt=coreone] {
          width: 60px !important;
          margin-top: 0 !important;
        }

        &[alt=global1sim] {
            width: 60px !important;
            margin-top: 0 !important;
        }
    }

    .sidebar-links {
        padding-left: 11px;

        i {
            font-size: 25px;
            padding-left: 0;
        }
    }

    .content-for-tooltip {
        display: block;
        width: auto;
    }

    .tooltip {
        &:hover .tooltip-content {
            @include transition(.3s opacity .3s);

            visibility: visible;
            opacity: 1;
        }
    }

    .tooltip-content {
        box-shadow: 2px 2px 6px 0px rgba(0,0,0,0.75);
        left: -15px;

        &.top-tooltip {
            top: -195%;
            white-space: nowrap;
            width: auto;
            min-width: 120px;
            text-align: center;

            &::after {
                @include calc(left, '20% - 10px');
                position: absolute;
                top: 100%;
                width: 0;
                height: 0;
                border: 10px solid transparent;
                border-bottom: 0;
                border-top-color: $tooltips-bg;
                content: '';
            }
        }
    }

    .bem-submenu-list {
        position: absolute;
        z-index: 5;
        background-color: $sidebar-background;
        width: 225px;
        right: -225px;
        top: 0;
        padding-left: 0;

        .sidebar-links__submenu-links {
            padding-left: 1.5rem !important;
        }
    }
}

.bem-submenu-list {
    padding-left: 0;

    .sidebar-links__submenu-links {
        padding-left: 50px !important;
    }

    .sidebar-links-text {
        display: inline-block;
    }
}


