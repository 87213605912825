.double-field {
    .input-field {
        // height: 50px;
    }

    label {
        line-height: 1;
    }

    label:last-of-type {
        font-size: .95rem;
    }

    .slash-divider {
        z-index: 10;
        display: inline-block;
        width: 5px;
        height: 60px;

        &::before {
            display: inline-block;
            font-size: 1.5rem;
            content: '/';
        }
    }

    .dot-divider {
        z-index: 10;
        display: inline-block;
        width: 5px;
        height: 60px;

        &::before {
            display: inline-block;
            font-size: 1.5rem;
            content: ':';
        }
    }

    [type=text]:first-of-type {
        margin-right: -15px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    [type=text]:last-of-type {
        width: 85px;
        border-left: 0;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

.radio-error {
    &.input-has-error {
        .validation {
            top: -18px;
        }
    }
}

.vpn-form,
.routes-form,
.nat-form {
    label {
        width: 250px;
    }

    .double-field {
        .input-field {
            margin-bottom: 5px;
        }

        .input-has-error {
            .validation {
                top: -12px;
            }
        }
    }

    .input-has-error {
        .validation {
            left: 285px;
        }
    }
}

.new-network-modal {

    .input-has-error {
        .validation {
            top: -16px;
            left: 0;
        }
    }

    .network-select-container {
        &.input-has-error {
            .validation {
                top: 3px;
                left: 55px;
            }
        }
    }
}
