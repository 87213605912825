.user-list {
    .block {
        margin-top: 0;
    }

    .inactive {
        vertical-align: middle;
    }
}

.users-tables {
  margin-top: 15px;

  .table-bordered {
    .operator-name {
      width: 500px;
    }

    th {
      &:last-of-type {
        text-align: right;
      }
    }

    td {
      &:last-of-type {
        padding-left: 0;
      }
    }
  }
}

.users-tags-container .tags-container {
    min-width: 293px !important;
    .host {
       ul.tag-list {
           float: left;
       }
   }
}
