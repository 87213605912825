%great-blue {
    display: inline-block;
    font-size: 40px;
    font-weight: 300;
    color: $h4;
}

.summary {
    @include clearfix;
}

.actual-consumed {
    //display: block;

    //float: left;

    min-width: 290px;
    //width: 100%;
    //height: 180px;

    //margin-right: 10px;
    //margin-bottom: 10px;


    h4 {
        margin: 7px 10px;
    }

    .right {
        margin-right: 15px;
        margin-top: 6px;        
    }

    .status {

        .data {
            @extend %great-blue;
        }

        .term {
            @extend %great-blue;

            @include responsive-font-size($font-size-xl);
            text-transform: uppercase;
        }
    }

    .donut-chart {
        position: relative;
        display: block;
        float: left;

        margin: 0 10px 10px;

        .number {
            position: absolute;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100%;
            height: 100%;
        }
    }

    .chart {
        display: inline-block;
        position: relative;
        font-size: 30px;

        &::after {
            position: relative;
            top: -5px;
            @include responsive-font-size($font-size-xl);
            content: '%';
        }

        &.green {
            color: $summary-green;
        }

        &.orange {
            color: $summary-orange;
        }

        &.blue {
            color: $summary-blue;
        }

        &.purple {
            color: $summary-purple;
        }
    }
}

.alerts {
    // h4 {
    //     margin-bottom: 16px;
    // }

    .row {
        padding: 0 10px;

        &:last-child {
            margin-bottom: 19px;
        }
    }

    .status {
        display: block;
        float: left;
        margin: 5px 10px;

        &:nth-of-type(3n) {
            clear: both;
        }
    }
}

.vpn {
    .vpn-list {
        padding-right: 50px;

        margin: 15px 0 19px 15px;

        .item {
            margin-bottom: 7px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .point {
            display: inline-block;
        
            width: 10px;
            height: 10px;
            margin-right: 7px;

            border-radius: 50%;

            background-color: $green;

            &.off {
                background-color: $toggle-pink;
            }
        }

        .vpn-name {
            color: $extra-semi-grey;
        }
    }
}

.charts {
    width: 100%;
    overflow-y: auto;

    .block {
        display: inline-block;
        //width: 49%;
        min-width: 800px;
        width: 100%;
        padding: 10px;
        //margin-right: 1.6%;
        margin-bottom: 10px;

        &:nth-of-type(2n) {
            margin-right: 0;
        } 
    }

    .chart {
        width: 100%;
    }

    .header {
        padding: 0 0 10px;

        .chart-title {
            float: left;
            margin: 0;
        }

        .close {
            float: right;
            margin-left: 10px;

            .icon-close {
                @include responsive-font-size($font-size-xs);
                color: $extra-semi-grey;
                cursor: pointer;

                &:hover {
                    color: $black;
                }
            }
        }

        .date {
            float: right;

            .point {
                display: inline-block;
                width: 10px;
                height: 10px;
                background-color: $h4;
                border-radius: 50%;

                &.sms-usage {
                    background-color: $summary-purple;
                }

                &.blocked-inbound {
                    background-color: #cc6633;
                }

                &.blocked-outbound {
                    background-color: #8F3F98;
                }

                &.blocked-sms {
                    background-color: #b0d757;
                }

                &.blocked-voice {
                    background-color: #90cbf6;
                }
            }
        }

        &::after {
            display: table;
            clear: both;
            content: '';
        }
    }
}

.small-charts {

    .small-chart {
        display: inline-block;
        height: 143px;
    }

    .block {
        position: relative;

        width: 220px;
        height: 100px;
        padding: 10px;
        margin-right: 30px;

        cursor: pointer;

        .point {
            position: absolute;
            top: 32px;
            right: -15px;

            &::before {
                font-size: 27px;
                color: white;

                border-radius: 50%;
                background-color: $green;

                content: '\e9a7';
            }
        }

        .chart {
            width: 200px;
            height: 100px;
        }
    }
}