@import "helpers";

body {
    background: $background-grey;
    font-size: $baseFontSize;
    font-weight: 350;
    color: $black;
    overflow-y: scroll;
}

* {
    box-sizing: border-box;
}

html {
    font-size: $baseFontSize;
    font-family: 'Roboto', Arial, sans-serif;
}

h1,
h2 {
    color: $blue;
    text-transform: uppercase;
}

//h1, .h1 { @include responsive-font-size($h1-font-size); }
//h2, .h2 { @include responsive-font-size($h2-font-size); }
//h3, .h3 { @include responsive-font-size($h3-font-size); }
//h4, .h4 { @include responsive-font-size($h4-font-size); }
//h5, .h5 { @include responsive-font-size($h5-font-size); }
//h6, .h6 { @include responsive-font-size($h6-font-size); }

h1 {
    font-weight: 100;
}

button {
    border: 0;
    outline: 0;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

//input:-webkit-autofill,
//input:-webkit-autofill:hover,
//input:-webkit-autofill:focus,
//input:-webkit-autofill:active {
//    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
//}


.large {
    @include responsive-font-size($h2-font-size);
}

.light {
    font-weight: 250;
}

.center {
    text-align: center !important;
}

.section-header {
    background: $white;
}

.jpu-blue {
    color: $blue;
    font-weight: 300;
}

.col.jpu-blue {
    @include responsive-font-size($font-size-md);
    @include responsive-font-size($font-size-lg);
    font-weight: 400;
}

.container {
    width: 96%;
    margin: 0 auto;
}

.container-fix {
    width: 100%;
}

.inline-block {
    display: inline-block;
}

.right {
    float: right;
}

.left {
    float: left;
}

.content-wrapper {
    clear: both;
}

.no-text-transform {
    text-transform: none;
}

.bold {
    font-weight: bold;
}

.align-right {
    text-align: right;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.no-float {
    float: none;
}

.capitalize {
    text-transform: capitalize;
}

.uppercase {
    text-transform: uppercase;
}

.table-head {
    margin-bottom: -1px;

    .table-row {
        height: auto;
        border-top: 0;
    }

    .scroll-table {
        td {
            padding: 10px 15px;
            font-weight: bold;
            border-top: 0;
        }
    }

    th {
        border-right: 0;
        border-left: 0;

        &:not(:first-of-type) {
            padding-left: 0;
        }
    }
}

.both {
    @include clearfix;
}

.percent-90 {
    width: 90%;
}

.highlighted {
    color: $black;
    background: $yellow;
}

pre {
    text-align: left;
}

mark {
    background-color: transparent;
}

.clear-fix {
    &::after {
        display: table;
        clear: both;
        content: '';
    }
}

.align-left {
    text-align: left;
}

.small {
    @include responsive-font-size($font-size-sm);
}

.display-flex {
    @include display(flex);
}

.blue {
    color: $blue;
}

.bgc-gray {
    background-color: $security-background-grey;
}

.vertical-divider {
    display: inline-block;
    width: 1px;
    height: 30px;
    margin: 0 13px;
    background-color: $reports-green-input;
}

.relative {
    position: relative;
}

.success {
    color: $alert-green !important;
}

.error {
    color: $modal-pink;
}

.overflow-hidden {
    overflow: hidden;
}

.invisible {
    visibility: hidden;
}

.z-index10 {
    z-index: 10;
}

.underline {
    text-decoration: underline;
}

.background-color-grey {
    background-color: $background-color-grey;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-help {
    cursor: help;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.max-width-195 {
    max-width: 195px;
}

.max-width-215 {
    max-width: 215px;
}

.display-none {
    display: none;
}

.fn-weight-300 {
    font-weight: 300;
}

.width-90 {
    width: 90px;
}

.width-160 {
    width: 160px;
}

.width-200 {
    width: 200px;
}

.height-100percent {
    height: 100%;
}

//$shadow-for-focused-input: 0 4px 24px 2px rgba(0, 0, 0, .14), 0 6px 5px 0px rgba(0, 0, 0, .12), 2px 1px 6px 0px rgba(0, 0, 0, .3);
$shadow-for-focused-input: 2px 2px 4px 0px rgba(0, 0, 0, .4);

.table-head-sort {
    background-color: $white;

    &.free-header {
        background-color: inherit;

        .current-sorted,
        .current-sorted-reverse {
            font-weight: bold;
            color: $blue;
            background-color: inherit;
        }
    }

    th,
    td {
        cursor: pointer;
        user-select: none;
    }

    .sort-arrow {
        display: inline-block;
        width: 7.3px;
        height: 4px;
        margin-left: 2px;
        background: url('#{$images}arrow-active-copy-3.png') no-repeat;
        transform: translateY(-2px);
    }

    .current-sorted,
    .current-sorted-reverse {
        font-weight: 400 !important;
    }

    .current-sorted-reverse {

        .sort-arrow {
            transform: translateY(-2px) rotate(180deg);
        }
    }
}

.bem-sort-arrow {
    // display: inline-block;
    // width: 7.3px;
    // height: 4px;
    // margin-left: 2px;
    // background: url('#{$images}arrow-active-copy-3.png') no-repeat;
    // transform: translateY(-2px);

    display: inline-block;
    vertical-align: inherit;
}

.current-sorted,
.current-sorted-reverse {
    font-weight: bold;
    color: $blue;
    background-color: inherit;
    font-weight: 400 !important;
}

.current-sorted {
    .bem-sort-arrow {
        border-top: 5px solid $blue;
        border-right: 5px solid transparent;
        border-left: 5px solid transparent;
    }
}

.current-sorted-reverse {
    .bem-sort-arrow {
        border-right: 5px solid transparent;
        border-bottom: 5px solid $blue;
        border-left: 5px solid transparent;
    }
}

.disabled-area {
    position: absolute;
    background-color: $input-background-grey;
    width: 100%;
    height: 100%;
    z-index: 10;
    opacity: 0.5;
}

.bem-sort-arrow__price-plans-details {
    position: absolute;
    top: 47%;
    right: 5px;
}

.bem-modifier_margin-0-15 {
    margin: 0 15px;
}

.bem-modifier_margin-0-20 {
    margin: 0 20px;
}

.bem-modifier_margin-0 {
    margin: 0;
}

.bem-modifier_margin-15-0 {
    margin: 15px 0;
}

.bem-modifier_neg-margin-bottom-25 {
    margin-bottom: -25px;
}

.bem-modifier_padding-15-0 {
    padding: 15px 0;
}

.bem-modifier_padding-20 {
    padding: 20px;
}

.bem-modifier_padding-0-20 {
    padding: 0 20px;
}

.bem-modifier_padding-0-10 {
    padding: 0 10px;
}

.bem-modifier_padding-left {
    padding-left: 27px;
}

.bem-modifier_margin-15 {
    margin: 1rem;
}

.bem-modifier_margin-top-20 {
    margin-top: 20px;
}

.bem-modifier_margin-top-40 {
    margin-top: 40px;
}

.bem-modifier_margin-top-50 {
    margin-top: 50px;
}

.bem-modifier_margin-top-12 {
    margin-top: 12px;
}

.bem-modifier_margin-top-10 {
    margin-top: 10px;
}

.bem-modifier_margin-top-15 {
    margin-top: 15px;
}

.bem-modifier_margin-top-6 {
    margin-top: 6px;
}

.bem-modifier_margin-top-35 {
    margin-top: 35px;
}

.bem-modifier_margin-left-6 {
    margin-left: 6px;
}

.bem-modifier_margin-left-15 {
    margin-left: 15px;
}

.bem-modifier_margin-top-minus-1rem {
    margin-top: -1rem;
}

.bem-modifier_margin-top-minus-2rem {
    margin-top: -2rem;
}

.bem-modifier_text-right {
    text-align: right;
}

.bem-modifier_text-center {
    text-align: center;
}

.bem-modifier_width-75 {
    width: 75px;
}

.bem-modifier_width-50 {
    width: 50px;
}

.bem-modifier_width-90 {
    width: 90px;
}

.bem-modifier_width-175 {
    width: 175px;
}

.bem-modifier_min-width-8rem {
    min-width: 8rem;
}

.bem-modifier_width-125 {
    width: 125px;
}

.bem-modifier_width-200 {
    width: 200px;
}

.bem-modifier_width-300 {
    width: 300px;
}

.bem-modifier_width-350 {
    width: 350px;
}

.bem-modifier_width-380 {
    width: 380px;
}

.bem-modifier_width-700 {
    width: 700px;
}

.bem-modifier_width-auto {
    width: auto;
}

.bem-modifier_max-width-900 {
    max-width: torem(900px);
}

.bem-modifier_max-width-500 {
    max-width: torem(500px);
}

.bem-modifier_max-width-100 {
    max-width: torem(100px);
}

.bem-modifier_min-width-175 {
    min-width: torem(175px);
}

.bem-modifier_min-width-300 {
    min-width: torem(300px);
}

.bem-modifier_min-width-435 {
    min-width: torem(435px);
}

.bem-modifier_min-width-350 {
    min-width: torem(400px);
}

.bem-modifier_min-width-120 {
    min-width: 120px;
}

.bem-modifier_min-width-180 {
    min-width: 180px;
}

.bem-modifier_min-height-45 {
    min-height: torem(45px);
}

.bem-modifier_min-width-800 {
    min-width: torem(800px);
}

.bem-modifier_align-middle {
    vertical-align: middle;
}

.ellipsis {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.qrcode {
    width: 200px;
    height: 200px;
}

.icon-checkmark {
    &.green-checkmark {
        font-size: 34px;
        font-weight: 100;
        color: #89c34c;
    }
}

.bem-modifier_width-500 {
    width: 500px;
}

.bem-modifier_width-70-percent {
    width: 70%;
}

.bem-modifier_width-50-percent {
    width: 50%;
}

.bem-modifier_width-90-percent {
    width: 90%;
}

.bem-modifier_width-100-percent {
    width: 100%;
}

.width-200 {
    max-width: 200px;
}

.width-250 {
    max-width: 250px;
}

.bem-modifier_full-width {
    width: 100%;
}

.bem-modifier_padding-0 {
    padding: 0;
}

.bem-modifier_padding-0-important {
    padding: 0 !important;
}

.bem-modifier_padding-0-35 {
    padding: 0 35px;
}

.bem-modifier_inline-block {
    display: inline-block;
}

.bem-modifier_display-block {
    display: block;
}

.bem-modifier_display-flex {
    display: flex;
}

.bem-modifier_display-inline-block {
    display: inline-block;
}

.bem-modifier_position-relative {
    position: relative;
}

.bem-modifier_position-absolute {
    position: absolute;
}

.bem-modifier_position-fixed {
    position: fixed;
}

.bem-modifier_text-size-14 {
    @include responsive-font-size($font-size-sm);
}

.bem-modifier_full-height {
    height: 100%;
}

.bem-modifier_height-46 {
    height: torem(46px);
}

.bem-modifier_height-90 {
    height: torem(90px);
}

.bem-modifier_text-left {
    text-align: left;
}

.bem-modifier_margin-right-15 {
    margin-right: 15px;
}

.bem-modifier_margin-right-30 {
    margin-right: 30px;
}

.bem-modifier_margin-60-5 {
    margin: 60px 5px;
}

.bem-modifier_margin-right-14 {
    margin-right: 14px;
}

.bem-modifier_margin-right-5 {
    margin-right: 5px;
}

.bem-modifier_margin-bottom10 {
    margin-bottom: 10px;
}

.bem-modifier_margin-bottom-100 {
    margin-bottom: 100px;
}

.bem-modifier_margin-left-minus-60 {
    margin-left: -60px;
}

.bem-modifier_text-color-grey {
    color: $text-grey;
}

.bem-modifier_text-bold {
    font-weight: bold;
}

.bem-modifier_font-weight300 {
    font-weight: 300;
}

.bem-modifier_font-weight400 {
    font-weight: 400;
}

.bem-add-divider {
    position: relative;

    :not(.remove-divider)::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 1px;
        height: 31px;
        margin-top: -15.5px;
        background: $extra-semi-grey;
        content: '';
    }
}

.bem-overflow-y-visible {
    overflow-y: auto;
}

.bem-overflow-y-auto {
    overflow-y: auto;
}

.bem-overflow-x-hidden {
    overflow-x: hidden;
}

.bem-overflow-x-visible {
    overflow-x: visible;
}

.bem-z-index-3 {
    z-index: 3;
}

.bem-overflow-hidden-important {
    overflow: hidden !important;
}

.bem-cursor-question-mark {
    cursor: help;
}

.bem-modifier_cursor-default {
    cursor: default;
}

.bem-modifier_cursor-pointer {
    cursor: pointer;
}

.bem-modifier_brighter-description {
    font-weight: 500;
    color: $events-modal-label-color;
}

.bem-input-hint {
    position: absolute;
    font-weight: 500;
    @include responsive-font-size($font-size-sm);
    color: $input-hint-color;
}

.bem-modifier_height100 {
    height: 100px;
}

.bem-success {
    font-weight: 400;
    color: $alert-green;
}

.bem-error {
    font-weight: 400;
    color: $modal-pink;
}

.bem-modifier_no-border-radius {
    border-radius: 0;
}

.bem-modifier_text-blue {
    color: $text-blue;
}

.bem-modifier_text-green {
    color: $text-green;
}

.bem-modifier_text-red {
    color: $text-red;
}

.bem-modifier_text-real-red {
    color: $text-real-red;
}

.bem-modifier_text-real-red-important {
    color: $text-real-red !important;
}

.bem-modifier_text-gray {
    color: $text-gray;
}

.bem-modifier_text-black {
    color: $black;
}

.bem-modifier_text-black-important {
    color: $black !important;
}

.bem-modifier_overflow-x-scroll {
    overflow-x: scroll;
}

.bem-modifier_overflow-x-auto {
    overflow-x: auto;
}

.bem-modifier_font-size22 {
    font-size: torem(22px);

}

.bem-slash-divider {
    z-index: 2;
    display: inline-block;
    width: 5px;
    height: 38px;
    position: absolute;
    right: 0;
    top: 0px;
    padding-top: 6px;

    &::before {
        display: inline-block;
        font-size: 1.5rem;
        content: '/';
    }
}

.bem-double-dot-divider {
    @extend .bem-slash-divider;

    &::before {
        content: ':';
    }
}

.bem-bg-color-white {
    background-color: $white;
}

.bem-ver-divider {
    //height: auto;
    position: absolute;
    border-right: 1px solid $border-color;
}

.bem-hr-divider {
    position: absolute;
    border-top: 1px solid $border-color;
}

.bem-hr-bottom-divider {
    border-bottom: 1px solid $code-border;
}

.bem-close-btn {
    top: torem(-10px);
    right: torem(-25px);
    position: absolute;

    @include responsive-font-size($font-size-lg);

    color: $input-background-dark-grey;
    cursor: pointer;
}

.bem-modifier_flex-shrink-0 {
    flex-shrink: 0;
}

// .bem-security-widts-separate-tables {
//     width: 1600px;
// }

.bem-global-pools-table-widths {
    width: 1593px;
}

.bem-roaming-partner-table-widths {
    width: 1585px;
}

.bem-things-table-widths {
    width: 1622px;
}

.bem-network-table-widths {
    width: 1628px;
}

.bem-color-warning-pink {
    &:before {
        color: $modal-pink !important;
    }
}

.bem-list-type-none {
    list-style-type: none;
}

.bem-align-text-left {
    text-align: left;
}

.bem-modifier_max-height-500 {
    max-height: 580px;
}

.black-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    color: $black;
}

.black-title-not-uppercase {
    font-size: 20px;
    font-weight: 500;
    color: $black;
}

.grey-title {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 500;
    color: $security-grey;
}

.bem-modifier_background-white {
    background: #fff;
}

.bem-modifier_background-light-grey {
    background: #f7f7f7;
}

.bem-modifier_material-shadow {
    box-shadow: 0px 1px 4px 0px rgba(0,0,0,.2);
}

.bem-regular-text {
    font-weight: 400;
    color: $black;
}

.bem-font-size1_2rem {
    font-size: 1.2rem;
}

.bem-font-size1_119rem {
    font-size: 1.119rem;
}

.bem-font-size0_8rem {
    font-size: 0.8rem;
}

.bem-right-triangle {
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-bottom-width: 8px;
    border-top-width: 8px;
    border-left: 12px solid $black;
    margin-left: 3px;
}

.bem-right-triangle_white {
    border: 0 solid transparent;
    border-bottom-width: 8px;
    border-top-width: 8px;
    border-left: 12px solid $white;
    margin-top: 8px;
}

.icon-settings_color-black {
    color: $icon-settings-color;
}

.bem-no-text-decoration {
    text-decoration: none;
}

.bem-vertical-align-middle {
    vertical-align: middle;
}

.bem-text-decoration-none {
    text-decoration: none;
}

.placeholder-font-family {
     font-family: 'Roboto', 'Aqua-Icons', Arial, sans-serif;
}

.bem-input-width {
    width: calc(100% - 30px);
    margin-left: 15px;
}

.file-name {
    display: inline-block;
    font-weight: normal;
    color: $text-label-dark-grey;
    font-size: 0.8rem;
    margin-top: 4px;
}

.responsive-fontsize-sm {
    @include responsive-font-size($font-size-sm);
}

.flex-no-shink {
    flex-shrink: 0;
}

.css-icon-up-down {
    position: absolute;
    right: 0;
    top: 0;

    &::before {
        content: "";
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 7px solid $black;
        display: block;
        margin-bottom: 1px;
    }

    &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 7px solid $black;
        display: block;
        margin-top: 1px;
    }
}

.bem-line-height-2 {
    line-height: 2;
}

.bem-add-border-bottom {
    border-bottom: 1px;
}

.bem-add-border-right {
    border-right: 1px;
}

.bem-add-border-bottom_style-solid-grey {
    border-bottom-color: rgba(0,0,0,.125);
    border-bottom-style: solid;
}

.bem-add-border-right_style-solid-grey {
    border-right-color: rgba(0,0,0,.125);
    border-right-style: solid;
}

.bem-permissions-height {
    height: torem(48px);
}

.info-icon-custom-color {
    &::before {
        color: #f69421 !important;
        font-size: 13px;
    }
}

.rotated-element {
    display: block;
    position: relative;
    left: -1px;
    height: 22px;
    width: 22px;
    transform: rotate(38deg);
    @include responsive-font-size($font-size-xl);
}

.minus_element {
    display: block;
    position: relative;
    bottom: 4px;
    height: 22px;
    width: 22px;
    font-size: 1.375rem;
}

.add_array {
    display: block;
    bottom: 4px;
    height: 22px;
    font-size: 1.375rem;
    font-weight: 400;
}


.ben-circle {
    display: block;
    background-color: $black;
    width: 40px;
    height: 40px;
    border-radius: 100px;
}

.ben-circle_large {
    width: 200px;
    height: 200px;
}

.ben-circle_green {
    background-color: greenyellow;
}

.height-auto {
    height: auto;
}

.height-fixed {
    height: 20px;
}

.pointer-events-none {
    pointer-events: none;
}

$chevronLineWidth: 0.16em;
$chevronWidth: 0.25em;

.chevron {
    vertical-align: middle;
}

.chevron::before {
    border-style: solid;
    border-width: $chevronLineWidth $chevronLineWidth 0 0;
    content: '';
    display: inline-block;
    height: $chevronWidth;
    left: 0.15em;
    position: relative;
    top: $chevronWidth;
    transform: rotate(-45deg);
    vertical-align: top;
    width: $chevronWidth;
}

.chevron.chevron-right:before {
    left: 0;
    transform: rotate(45deg);
}

.chevron.chevron-bottom:before {
    top: $chevronWidth;
    transform: rotate(135deg);
    left: 0.07em;
}

.chevron.chevron-left:before {
    left: 0.25em;
    transform: rotate(-135deg);
}

.flex-wrap {
  flex-wrap: wrap;
}