.branding-file-name {
  display: inline-block;
  font-weight: normal;
  color: $text-label-dark-grey;
  font-size: 0.8rem;
}

.file-info_width {
  max-width: 300px;
}

.info-circle__orange {
  color: #F69421;
}