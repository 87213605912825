.things-browser-wrapper {
    .ui-grid-top-panel, .ui-grid-header-viewport {
        //overflow: visible;
        //overflow-x: hidden !important;
        //overflow-y: visible !important;
    }
    .ui-grid-contents-wrapper {
        overflow-x: hidden !important;
    }
    .tags-container tags-input .host .tags input {
        margin: 0;
        padding: 0 0 0 6px;
    }
    input[type="text"].ui-grid-filter-input {
        border-radius: 20px;
        height: 33px;
        line-height: 50px;
        background: #edf1f2;
        border: none;
        padding: 0 10px;
        font-size: 14px;
        margin: 0;
    }

    .tags-container tags-input .host .tags {
        //padding: 4px 0px 0px 0;
        padding: 0;
        display: flex;
        align-items: center;
    }

    input[type="text"].ui-grid-filter-input::placeholder {
        font-size: 14px;
    }
    .tags-container {
        padding: 0 10px;
        margin-top: 5px;
        //width: 293px;
    }
    .tags-input .tags .tag-item {
        margin: 0 0 0 3px;
    }
    input[type="text"].ui-grid-filter-input::placeholder {
        @include responsive-font-size($font-size-sm);
    }
    .ui-grid-header-canvas {
        height: 86px !important;
    }
    .ui-grid-filter-container {
        padding: 0 10px !important;
    }
    .bem-table-bordered .ui-grid-header {
        border-bottom: none !important;
    }
    .ui-grid-header-cell:first-of-type {
        input[type="text"].ui-grid-filter-input {
            // margin-left: -40px;
        }
    }
    .ui-grid-menu-button {
        #menuitem-0 {
            display: none;
        }
    }

}

.things-browser-wrapper, .things-tags-wrapper {
    .new-rule-wrapper {
        transform: translateY(-67px);
    }

    .bem-btn_grey {
        background: $btn-browse-bg-color;
    }
}