.search-results {
    //padding: 0 15px 15px;
    background-color: $white;
    border-top: 1px solid $security-grey;
}

.empty-results {
    padding: 20px 0;
    @include responsive-font-size($font-size-md);
}

.search-result {
    position: relative;
    padding: 15px 25px;
    margin: 0 10px;
    @include responsive-font-size($font-size-md);
    cursor: pointer;
    border-bottom: 1px solid $search-grey;

    &:after {
        @include transform(rotate(-90deg));
        position: absolute;
        top: 30%;
        right: 0;
        font-family: 'Aqua-Icons';
        font-size: 28px;
        color: $search-arrow;
        content: "";
    }

    &:hover {
        background-color: $search-hover;

        &:after {
            content: '\e983';
        }
    }

    .result-type {
        display: inline-block;
        padding: 5px 10px;
        margin-right: 15px;
        color: $tag-button;
        border: 1px solid $tag-button;
        border-radius: 10px;
    }
}

.bem-result-type {
    display: inline-block;
    padding: 5px 10px;
//     margin-right: 15px;
    color: $tag-button;
    border: 1px solid $tag-button;
    border-radius: 10px;
}