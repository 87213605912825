.scheduler {

}

#job-form {
    .bs-row {
        &:not(:last-child) {
            // margin-bottom: .5rem;
        }
    }
}

.cron-gen-main {

    .nav-tabs {
        .nav-item {
            .nav-link {
                // font-weight: 400;
                color: $input-color-grey;
                text-decoration: none;
                font-size: 1em;
            }
        }
    }

    .card:not(:last-child) {
        margin-bottom: .5rem;
    }


    .cron-gen-container {

        margin-top: 10px;

        .cron-gen-radio {
            width: 20px;
            display: inline-block;
        }

        .cron-gen-select, .cron-gen-input, .cron-gen-checkbox {
            display: inline-block;
        }

        .well-time-wrapper {
            padding-left: 20px;
        }

        .inline-block {
            display: inline-block;
        }

        .minutes, .hours, .days, .seconds {
            width: 70px;
        }

        .months {
            width: 120px;
        }

        .month-days {
            width: 130px;
        }

        .months-small {
            width: 60px;
        }

        .day-order-in-month {
            width: 95px;
        }

        .week-days {
            width: 120px;
        }

        .advanced-cron-gen-input {
            width: 200px;
        }

        .hour-types {
            width: 70px;
        }
    }
}

