// TODO: lint this file
%top-blocks {
    float: right;
    height: #{$header-height};
    line-height: #{$header-height};
}

.main-header {
    @include calc (width, '100% - #{$sidebar-width}');
    position: relative;
    float: right;
    //height: 56px;
    background-color: $white;
}

.toggled-main {
    @include calc (width, '100% - #{$sidebar-collapsed-width}');
}

.toggle-button {
    //float: left;
    //padding: 0 15px;
    font-size: 28px;
    color: $light-grey;
    cursor: pointer;
}

.mirror {
    transform: scaleX(-1);
    padding-left: 0;
}

.client-name {
    display: inline-block;
    @include responsive-font-size($font-size-lg);
    line-height: #{$header-height};
}

.search {
    //width: 100%;
    position: relative;
    display: inline-block;
    margin: 0 30px;

    [type="text"] {
        //width: 100%;
        height: 33px;
        padding: 0 23px;
        margin: 12px 0 0 5px;
        font-weight: 400;
        line-height: 23px;
        color: $light-grey;
        background-color: $search-background;
    }

    [type="submit"] {
        position: absolute;
        top: 20px;
        right: 10px;
        width: 20px;
        background-color: transparent;
        background-image: url('#{$images}search.png');
        background-repeat: no-repeat;
        border: 0;
        outline: 0;
    }
}

.expanded-search {
    // @include calc(width, '100% - 375px');
    width: 100%;
    position: relative;
    display: inline-block;
    margin: 0 30px;

    [type="text"] {
        @extend .bem-form-input;
        @include calc(width, '100% - 310px');
        height: 33px;
        padding: 0 23px;
        margin: 12px 89px 0 0;
        font-weight: 400;
        line-height: 23px;
        color: $light-grey;
        //background-color: $search-background;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    [type="submit"] {
        position: absolute;
        top: 12px;
        right: 50px;
        width: 100px;
        height: 33px;
        @include responsive-font-size($font-size-md);
        font-weight: 300;
        color: $white;
        background-color: $submit-search-button;
        border: 0;
        border-radius: 46px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        outline: 0;
    }

    .dropdown-search {
        position: absolute;
        top: 12px;
        right: 150px;
        width: 160px;
        height: 33px;
        padding: 0 10px;
        @include responsive-font-size($baseFontSize);
        font-weight: 300;
        line-height: 33px;
        cursor: pointer;
        background-color: $cancel-grey;

        i {
            float: right;
            @include responsive-font-size($font-size-sm);
            line-height: 33px;
        }
    }

    .dropdown-search-box {
        position: absolute;
        top: 45px;
        right: -31px;
        z-index: 10;
        width: 241px;
        height: 300px;
        padding-top: 10px;
        overflow-y: auto;
        @include responsive-font-size($font-size-md);
        background-color: $background-grey-modal;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        box-shadow: 0 5px 5px 5px $tab-shadow;
    }

    .all-categories {
        position: relative;
        padding: 5px 0;
        margin: 0 15px 0 40px;
        cursor: pointer;

        &.checked-category:before {
            position: absolute;
            top: -2px;
            left: -35px;
            font-family: 'Aqua-Icons';
            font-size: 34px;
            color: $btn-green-color;
            content: '\e985';
        }

        .counter {
            color: $black;
        }
    }

    .cat-separator {
        margin: 10px 5px;
        border-bottom: 1px dashed $filters-gray;
    }

    .categories {
        position: relative;
        padding: 5px 0;
        margin: 0 15px 0 30px;
        cursor: pointer;

        &.checked-category:before {
            position: absolute;
            top: -2px;
            left: -35px;
            font-family: 'Aqua-Icons';
            font-size: 34px;
            color: $btn-green-color;
            content: '\e985';
        }
    }

    .counter {
        float: right;
        color: $header-search-category-counter-color;
        font-weight: 400;
    }

    .checked-category {
        .counter {
            color: $black;
        }
    }
}

.expanded-top-nav {
    @include calc(width, '100% - 80px');
}

.top-nav {
    float: right;
    width: 90%;

    .temp {
        @extend %top-blocks;
        margin-right: 30px;
    }

    .alerts {
        @extend %top-blocks;

        position: relative;
        width: 42px;
        margin-right: 30px;

        line-height: normal;

        background-image: url('#{$images}alerts.png');
        background-position: center;
        background-repeat: no-repeat;

        cursor: pointer;

        .label {
            top: 5px;
            left: 16px;
        }
    }

    .user-area {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        // width: 300px;

        float: right;
        height: #{$header-height};
        //margin-right: 15px;

        color: $light-grey;
        word-break: break-all;

        .avatar,
        .user,
        .icon-arrowdown {
            cursor: pointer;
        }

        .user {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 220px;
        }

        .avatar {
            width: 33px;
            height: 33px;
            margin-right: 10px;
            background: url('#{$images}avatar.png') no-repeat center center;
            background-size: cover;
            border: 1px solid $tag-border;
            border-radius: 50%;
        }

        .icon-arrowdown {
            margin-left: 10px;
        }

    }

    .user-dropdown-menu {
        position: absolute;
        right: 10px;
        bottom: -277px;
        z-index: 1000;
        opacity: 1;

        padding: 10px;

        color: $light-grey;

        background-color: $white;
        border: 1px solid $white;
        box-shadow: 0 2px 2px 2px $tab-shadow;

        &::before {
            @include triangle(16px, $white, up);

            position: absolute;
            top: -8px;
            right: 13px;

            content: '';
        }

        header {
            display: flex;
            align-items: center;

            margin-bottom: 20px;

            color: $input-color-grey;

            .avatar {
                width: 33px;
                height: 33px;
                margin-right: 10px;
                background: url('#{$images}avatar.png') no-repeat center center;
                background-size: cover;
                border: 1px solid $tag-border;
                border-radius: 50%;
            }

            .name {
                @include responsive-font-size($font-size-md);
            }

            .email {
                @include responsive-font-size($font-size-sm);
            }
        }

        menu {
            padding: 0 6px;
            margin: 0;

            @include responsive-font-size($font-size-md);

            .element {
                display: flex;
                align-items: center;

                margin: 15px 0;

                font-weight: 300;

                color: $input-color-grey;

                &:hover {
                    color: $light-blue;
                    cursor: pointer;
                }

                i {
                    margin-right: 10px;
                }
            }
        }

        footer {
            .logout {
                width: 100%;
                padding: 5px 0px;

                border: 1px solid $radio-grey;
                border-radius: 5px;

                background-color: $security-grey;
                color: $white;

                &:hover {
                    border: 1px solid $logout-pink;
                    background-color: $logout-pink;
                }

                .icon {
                    position: relative;
                    top: 2px;
                }
            }
        }
    }
}

.header-user-dropdown-menu {
    margin-top: 10px;
    padding: 10px;
    color: $light-grey;
    border: 1px solid $white;
    box-shadow: 0 2px 2px 2px $tab-shadow;

    left: initial;
    right: 0;

}

.header-user-menu-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    color: $input-color-grey;
}

.header-user-menu-header_avatar {
    width: 33px;
    height: 33px;
    margin-right: 10px;
    background: url('#{$images}avatar.png') no-repeat center center;
    background-size: cover;
    border: 1px solid $tag-border;
    border-radius: 50%;
}

.header-user-menu-header_name {
    @include responsive-font-size($font-size-md);
}

.header-user-menu-header_email {
    @include responsive-font-size($font-size-sm);
}

.header-user-menu-body {
    @include responsive-font-size($font-size-md);

    padding: 0 6px;
    margin: 0;
}

.header-user-menu-body_item {
    @include responsive-font-size($font-size-md);
    @extend .bem-no-text-decoration;

    display: flex;
    align-items: center;
    margin: 15px 0;
    font-weight: 300;
    color: $input-color-grey;

    &:hover {
        color: $light-blue;
        cursor: pointer;
    }

    &:focus {
        box-shadow: 0 0 2px 1px $text-focus-border-color;
    }

    i {
        margin-right: 10px;
    }
}

.header-user-menu-logout_button {
    @include responsive-font-size($font-size-md);
    @extend .bem-no-text-decoration;

    width: 100%;
    padding: 5px 0px;
    border: 1px solid $radio-grey;
    border-radius: 5px;
    background-color: $security-grey;
    color: $white;
    display: inline-block;
    text-align: center;

    &:hover {
        border: 1px solid $logout-pink;
        background-color: $logout-pink;
    }

    &:focus {
        background-color: $logout-pink;
    }

    .icon {
        position: relative;
        top: 2px;
    }
}

.top-nav-user-area-user {
    background-color: transparent;
    padding-right: 18px;

    &::after {
        position: absolute;
        right: 3px;
        top: 8px;
    }
}


.label {
    @include display(flex);
    @include align-items(center);
    @include justify-content(center);

    position: absolute;
    height: 18px;
    min-width: 21px;

    overflow: hidden;
    font-size: 13px;
    font-weight: 500;
    line-height: 19px;
    color: $white;

    background-color: $bright-pink;
    border-radius: 9px;
    padding: 0 5px;

    &.relative-label {
        position: relative;
        display: inline-block;
    }
}

.sub-header {
    position: relative;
    min-height: #{$subheader-height};
    padding: 0 15px;
    line-height: 75px;
    text-align: center;
    background-color: $white;
    box-shadow: inset 0 10px 10px -10px $top-shadow;

    &.align-right {
        text-align: right;
    }

    .choose-icon {
        position: relative;
        float: left;
        height: 60px;
        //padding: 5px 9px;
        //margin: 8px 15px 0 0;
        font-size: 42px;
        line-height: 60px;
        cursor: pointer;
        border: 1px solid transparent;
        border-radius: 5px;

        &.pushed,
        &:hover {
            border: 1px solid $tag-button;
        }

        &::after {
            position: absolute;
            right: -6px;
            bottom: -5px;
            width: 17px;
            height: 17px;
            font-family: 'Aqua-Icons';
            @include responsive-font-size($baseFontSize);
            line-height: 16px;
            background-color: $white;
            content: '\e947';
        }
    }

    .data {
        font-size: 26px;
    }

    h1 {
        float: left;
        margin: 0;
        line-height: 75px;
        @include responsive-font-size(2.4rem);

        span {
            color: $security-grey;
        }

        i {
            @include transform (rotate(90deg));
            display: inline-block;
            @include responsive-font-size($font-size-sm);
            color: $security-grey;
            vertical-align: middle;
        }

        i.apn-arrow {
            margin: 0 7px;
        }
    }

    // button {
    //     float: right;
    //     height: 40px;
    //     padding: 0 45px;
    //     margin: 18px 0 0;
    //     @include responsive-font-size($baseFontSize);
    //     font-weight: bold;
    //     line-height: 40px;
    //     text-transform: capitalize;
    // }
}

.update-status {
    float: right;
    cursor: pointer;

    i {
        position: relative;
        top: 3px;
        @include responsive-font-size($font-size-xl);
    }
}

.updated-status {
    color: $new-green;
}

.not-updated-status {
    color: $notification-pink;

    &.disabled {
        cursor: not-allowed;
    }
}
