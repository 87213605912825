.events {
    @include justify-content(flex-start);

    &.tabs {
        .tab {
            margin-right: 5px;
        }
    }

}

.tasks-form,
.events-form {
    padding: 20px 30px;
    margin: 20px;

    .webhook-block {
        margin: 10px 0 20px;
    }

    .auto-label {
        cursor: pointer;
    }

    .checkbox {
        margin-right: 5px;
    }

    [type=text] {
        width: 292.3px;
        background-color: $input-background-grey;
    }

    .row {
        @include display(flex);
        @include flex-flow(row wrap);
        @include align-items(top);

        .item {
            margin-bottom: 15px;

            &::before {
                top: 10px;
                left: -26px;
            }

            &:first-child {
                margin-right: 5%;
            }
        }

        h4 {
            margin: 0;
        }
    }

    label {
        display: inline-block;
        width: 150px;
        font-weight: 400;
        @include responsive-font-size($font-size-md);
        color: $security-grey;

        &.tumbler {
            width: 50px;
        }
    }

    .repeat-every {
        @include display(flex);
        @include align-items(center);
        flex-wrap: wrap;

        .repeat {
            margin-left: 4px;

            [type=text] {
                width: 90px;
                padding: 0 20px;
                margin-right: -5px;

                font-weight: 300;
                text-align: right;
                background-color: $input-background-dark-grey;

                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }

            select {
                width: 204px;
                padding: 0 46px 0 23px;

                border-bottom-left-radius: 0;
                border-top-left-radius: 0;
            }
        }

        &.input-has-error {
            .repeat {
                .days-to-repeat {
                    position: relative;
                    top: -1px;
                }
            }
        }

        .validation {
            flex-basis: 70%;
        }
    }

    .select-things-groups {
        .btn {
            height: 30px;
            padding: 0 30px;
            line-height: 30px;
        }

        .block {
            width: 400px;
            padding: 15px 20px;
            margin: 20px 0;
        }

        .group {
            margin: 10px 0;
        }
    }

    .actions {
        .item {
            @include display(flex);
            @include align-items(center);

            height: 46px;
        }

        .btn-set-webhook {
            height: 30px;
            padding: 0 16px;
            margin-left: 10px;
            line-height: initial;

            .tooltip-content {
                top: -205px;
                left: 0;
            }
        }

        .checkbox {
            position: relative;
            top: -1px;
            width: 20px;
        }
    }

    .input-has-error {
        .validation {
            left: 165px;
        }
    }
}

.events-modal {
    .group {
        @include display(flex);
        @include align-items(center);

        margin: 15px;
    }

    .auto-label {
        cursor: pointer;
        font-weight: 500;
        color: $events-modal-label-color;
    }

    .checkbox {
        margin-right: 5px;
    }
}

.alert-list {
    .tags-container {
        min-width: 293px !important;
        .host {
           ul.tag-list {
               float: left;
           }
       }
    }
}
