@import "colors";

$images: '/assets/images/';
$header-height: '56px';
$subheader-height: '75px';
$sidebar-width: '250px';
$sidebar-collapsed-width: 54px;
$stat-height: '400px';
$tabs-row-height: '62px';
$break-profile-form: 1450px;
$break-large: 1340px;
$break-medium: 968px;

/* Tooltip */
$tooltip-bg: $dark-grey;
$tooltip-arrow-color: $tooltip-bg;

/* Shadows */
$card-shadow: 0px 0px 6px 0px rgba(0, 0, 0, .1);

/* Common sizes */
$baseFontSize: 1rem !default; // Assumes the browser default, typically `16px`
$baseInPixels: 16px;
$font-size-xxxl: 1.875rem !default;
$font-size-xxl: 1.5rem !default;
$font-size-xl: 1.375rem !default;
$font-size-lg: 1.25rem !default;
$font-size-md: 1.125rem !default;
$font-size-sm: .875rem !default;
$font-size-xs: 0.75rem !default;
$font-size-xxs: 0.5rem !default;
$font-size-xxxs: 0.25rem !default;

/* Header font sizes */
$h1-font-size: 2.5rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

/* RFS settings */

// Minimum fontsize.
$rfs-minimum-font-size: 1rem !default;
$rfs-font-size-unit: rem !default;

// Breakpoint at where font-size starts decreasing if screen width is smaller.
$rfs-breakpoint: 1200px !default;
$rfs-breakpoint-unit: px !default;

// Resize font-size based on screen height and width.
$rfs-two-dimensional: false !default;

// Factor of decrease.
$rfs-factor: 5 !default;

// Generate disable classes
$rfs-generate-disable-classes: true !default;

// 1 rem = $rfs-rem-value px.
$rfs-rem-value: 16 !default;

// Disable RFS by setting $enable-responsive-font-sizes to false.
$enable-responsive-font-sizes: true !default;

$ui-grid-text-color: #686769;
$ui-grid-table-even-row-bg-color: #F4F6F9;
