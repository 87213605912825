.customer-list {

    .block {
        //margin-top: 2rem;
    }

    .active {
        background: $security-background-grey;
    }

    .manage {
        min-width: 80px;
    }
}

.customer-form {
    #roaming-plan {
        //width: 400px;
    }

    .select-apn, .checkbox-row {
        .col {
            display: inline-block;
        }
    }
}

.customers-fields {
    [type=text] {
        //width: 400px;
    }
}

.trash-active,
.trash {
    float: right;
    width: 50px;
    @include responsive-font-size($font-size-xl);
    cursor: pointer;
}

.trash-active {
    color: $input-color-grey;

    &:hover {
        color: $delete-entity-color;
    }
}

// .bem-table-bordered__btn-access {
//     position: relative;
//     top: -2px;
//     display: inline-block;
//     height: 40px;
//     padding: 0 39px;
//     margin-left: 15px;
//     @include responsive-font-size($baseFontSize);
//     font-weight: 400;
//     line-height: 40px;
//     color: $white;
//     cursor: pointer;
//     background-color: $new-green;
//     border: 0;
//     border-radius: 46px;
//     outline: 0;
// }
