.progress {
  height: 25px;
  font-weight: 400;
  background-color: transparent;

  .progress-bar {
    span {
      transition: 0.4s;
      height: 100%;
      padding: 6px;
    }
  }

}

.password-validation-tooltip {
  .tooltip-inner {
    min-width: max-content;
    pre {
      margin: 0;
    }
  }
}
